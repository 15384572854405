import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  Table,
  FormGroup,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import "../../../assets/scss/style.css"
import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import { MdGridView, MdTableRows } from "react-icons/md"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"
import { dateformat, getUserDetail } from "admin/commonFunction"
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//i18n
import { withTranslation } from "react-i18next"
import { point } from "leaflet"

import noDataImage from "../../../assets/images/nodata-found.png"
import { get, post, del, put } from "helpers/api_helper"

const ShowNFT = props => {
  //meta title
  document.title = "NFT's Show"
  const BASE_PATH = process.env.REACT_APP_BASE_PATH
  const [collectionListingData, setCollectionData] = useState([])
  const [listingData, setData] = useState([])
  const [searchName, setSearchName] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [view, setview] = useState(true)
  const [loaderStatus, setLoaderStatus] = useState(true)
  const [status, setStatus] = useState("")
  const [approval, setApproval] = useState("")
  const [userInfo, setUserInfo] = useState("")
  const perPage = 10

  const handlePageClick = page => {
    setCurrentPage(page)
    fetchData(page, perPage, searchName)
  }
  const fetchData = (pagenumber, pagelimit, search = "", status, approve) => {
    try {
      setLoaderStatus(true)
      axios.get(
          apiRoute.nftListing +
          "?page=" +
          pagenumber +
          "&limit=" +
          pagelimit +
          "&search=" +
          search +
          "&nftStatus=" +
          status +
          "&isApproved=" +
          approve
        )
        .then(res => {
          setLoaderStatus(false)
          console.log(res.data, ">>>>>>>>>>>>")
          setData(res.data.data.nft)

          let pageCount = Math.ceil(res.data.data.totalItems / perPage)
          setTotalCount(pageCount)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const fetchCollectionData = (pagenumber, pagelimit, search = "") => {
    try {
      axios
        .get(
          apiRoute.collectionListing +
          "?page=" +
          pagenumber +
          "&limit=" +
          pagelimit +
          "&search=" +
          search
        )
        .then(res => {
          setCollectionData(res.data.data.collection.items)
        })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData(currentPage, perPage, searchName, status, approval)
    setUserInfo(getUserDetail())
  }, [])

  const handleDateEvent = async event => {
    console.log(event.length)
    if (event.length == 2) {
    }
  }

  const handleStatusClick = event => {
    setStatus(event.target.value)
    setCurrentPage(1)
    console.log(event.target.value, "hdh")
    fetchData(1, perPage, searchName, event.target.value, approval)
  }

  const handleApproveClick = event => {
    setApproval(event.target.value)
    setCurrentPage(1)
    console.log(event.target.value, "hdh")
    fetchData(1, perPage, searchName, status, event.target.value)
  }

  const handleChangeCheck = async (event, type) => {
    try {
      console.log(event.target.value)
      console.log(event.target.checked)

      let id = event.target.value
      let status = true
      let isApproved = 1
      if (!event.target.checked) {
        status = false
        isApproved = 0
      }

      let url = apiRoute.nftStatusUpdate
      let sendData = { active: status, nftID: id }
      if (type == "isApproved") {
        url = apiRoute.nftApprovalStatus
        sendData = { isApproved: isApproved, nftID: id }
      }
      axios.post(url, sendData).then(res => {
        toast.success("Status Updated Successfully")
        // fetchData(currentPage, perPage, searchName,"",approval)
      })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }

  const deleteCollection = nft_id => {
    try {
      axios.delete(apiRoute.nftDelete + `?nftID=${nft_id}`).then(res => {
        console.log(res)
        toast.success("NFT Deleted Successfully")
        fetchData(currentPage, perPage, searchName, status, approval)
      })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }
  const ConfirmDelete = id => {
    // let URL = BASE_URL + 'user/delete/' + id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteCollection(id)
      }
    })
  }

  const handleSearchClick = event => {
    setSearchName(event.target.value)
    setCurrentPage(1)
    fetchData(1, perPage, event.target.value,status,approval)
  }


  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card className="border">
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 card-title flex-grow-1"> NFT's </h4>

                    <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                      <div
                        className="position-relative"
                        style={{ marginTop: "10px" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search...`}
                            onChange={handleSearchClick}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="search-bar-0"
                        className="search-label"
                        style={{ marginRight: 5, marginTop: 10 }}
                      >
                        <span id="search-bar-0-label" className="sr-only">
                          Pending or Approved
                        </span>
                        <select
                          className="form-control"
                          placeholder={`choose Status`}
                          onChange={handleApproveClick}
                        >
                          <option value="">Choose Approval</option>
                          <option value="1">Approved</option>
                          <option value="0">Pending</option>
                        </select>
                      </label>
                    </div>
                    <div>
                      <label
                        htmlFor="search-bar-0"
                        className="search-label"
                        style={{ marginRight: 5, marginTop: 10 }}
                      >
                        <span id="search-bar-0-label" className="sr-only">
                          select status
                        </span>
                        <select
                          className="form-control"
                          placeholder={`choose Status`}
                          onChange={handleStatusClick}
                        >
                          <option value="">Choose Status</option>
                          <option value="true">active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </label>
                    </div>

                    <div className="flex-shrink-0">
                      <Link to="#!" className="btn btn-light me-1">
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                    <div>
                      <span
                        className="btn btn-light me-1"
                        onClick={() => setview(true)}
                      >
                        <i
                          className="bx bx-grid-alt"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                      <span
                        className="btn btn-light me-1"
                        onClick={() => setview(false)}
                      >
                        <i
                          className="bx bx-list-ul"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row>
                    {totalCount == 0 ? (
                      <Col sm="12" className="text-center">
                        <img src={noDataImage} style={{ width: "505px" }} />
                      </Col>
                    ) : listingData && view ? (
                      listingData.map((element, index) => {
                        return (
                          <Col xl="2" xs="6" className="mt-3 px-2" key={index}>
                            <Card>
                              <div className="nft-category-image d-flex">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={BASE_PATH + element.itemThumb}
                                />

                                {/* Active/Inactive Status */}
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "10px",
                                  }}
                                >
                                  <Badge
                                    className={
                                      element.active == false
                                        ? "bg-danger border"
                                        : "bg-success border"
                                    }
                                  >
                                    {element.active == false
                                      ? "Inactive"
                                      : "Active"}
                                  </Badge>
                                </div>

                                {/* Approved Status */}
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "30px",
                                  }}
                                >
                                  <Badge
                                    className={
                                      element.isApproved == false ||
                                        element.isApproved == 2
                                        ? "bg-danger border"
                                        : "bg-success border"
                                    }
                                  >
                                    {element.isApproved == false
                                      ? "Pending"
                                      : element.isApproved == 2
                                        ? "Reject"
                                        : "Approved"}
                                  </Badge>
                                </div>

                                <UncontrolledDropdown
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                  }}
                                >
                                  <DropdownToggle
                                    href="#"
                                    tag="a"
                                    className="dropdown-toggle"
                                  >
                                    <i
                                      className="bx bx-dots-vertical-rounded"
                                      style={{
                                        fontSize: "21px",
                                        color: "#2a3042",
                                        textShadow: "2px 1px 1px white",
                                      }}
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <Link
                                      className="dropdown-item"
                                      to={`/admin/nft/edit/${element._id}`}
                                    >
                                      {" "}
                                      Edit{" "}
                                    </Link>
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        ConfirmDelete(element._id)
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <Link to={`/admin/nft/detail/${element._id}`}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: '6px'
                                  }}
                                >
                                  <div className="p-1">
                                    <b className="d-block">{element.name}</b>
                                    <span>
                                      {" "}
                                      {dateformat(element.createdAt)}
                                    </span>
                                  </div>
                                  <div style={{ marginTop: 24 }}>
                                    Price {element.price} Eth
                                  </div>
                                </div>
                              </Link>
                            </Card>
                          </Col>
                        )
                      })
                    ) : (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead className="table-light">
                            <tr>
                              <th> NFT </th>
                              <th> Current Price </th>
                              <th> Properties </th>
                              <th> Last Sale </th>
                              <th> Owner </th>
                              <th> Time Listed </th>
                              <th> Approval </th>
                              <th> Status </th>
                              <th> Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loaderStatus ? (
                              <tr>
                                <td>No Record Found</td>
                              </tr>
                            ) : (
                              listingData.map((element, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={`/admin/nft/detail/${element._id}`}
                                      >
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={BASE_PATH + element.itemThumb}
                                            className="avatar-sm me-3 rounded-circle"
                                            alt="img"
                                          />
                                          <div className="">
                                            <h5 className="font-size-14 mb-1">
                                              {" "}
                                              {element.name}{" "}
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Created At :{" "}
                                              {dateformat(element.createdAt)}
                                            </p>
                                            <p className="text-muted mb-0">
                                              Created By :{" "}
                                              {element.userdetails.name}
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="">
                                        <h5 className="font-size-14 text-muted mb-0">
                                          {Number(element.price).toFixed(2)} Eth
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <ul className="mb-0 p-0">
                                        {
                                          (element.nft_properties.length > 0) ? element.nft_properties.map((value, index) => {
                                            return <div key={index}>

                                              <li className="font-size-12 fw-bold text-muted mb-2">{value.propertyname}</li>
                                              <div className="mb-2">
                                                  {
                                                    value.property_sections.map((section, ind) => {
                                                      return <h5 className="font-size-12 text-muted mb-1" key={ind}> <i className="mdi mdi-checkbox-marked-outline me-1"></i> {section.name} </h5>
                                                    })
                                                  }
                                              </div>
                                            </div>
                                          }) : <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                        }
                                      </ul>
                                    </td>
                                    <td>
                                      <div className="">
                                        <h5 className="font-size-14 text-muted mb-0">
                                          -
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        <h5 className="font-size-14 mb-0">
                                          {" "}
                                          -{" "}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        <h5 className="font-size-14 text-muted mb-0">
                                          -
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      {userInfo.role == "admin" ? (
                                        <div className="square-switch mytoggle">
                                          <input
                                            type="checkbox"
                                            id={`square-switch1${index}`}
                                            switch="success"
                                            defaultChecked={element.isApproved}
                                            value={element._id}
                                            onClick={event =>
                                              handleChangeCheck(
                                                event,
                                                "isApproved"
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`square-switch1${index}`}
                                            data-on-label="Accept"
                                            data-off-label="Pending"
                                            className="mb-0"
                                          />
                                        </div>
                                      ) : element.isApproved == 0 ? (
                                        <Badge className="bg-danger" style={{padding: '7px 7px'}}>
                                          {" "}
                                          Pending{" "}
                                        </Badge>
                                      ) : (
                                        <Badge className="bg-success" style={{padding: '7px 7px'}}>
                                          {" "}
                                          Accepted{" "}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      <div className="square-switch mytoggle" style={{height: '24px'}}>
                                        <input
                                          type="checkbox"
                                          id={`square-switch${index}`}
                                          switch="success"
                                          defaultChecked={Boolean(
                                            element.active
                                          )}
                                          value={element._id}
                                          onClick={event =>
                                            handleChangeCheck(event, "isActive")
                                          }
                                        />
                                        <label
                                          htmlFor={`square-switch${index}`}
                                          data-on-label="Active"
                                          data-off-label="Inactive"
                                          className="mb-0"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <ul className="list-unstyled hstack gap-1 mb-0">
                                        <li>
                                          <Link
                                            to={`/admin/nft/edit/${element._id}`}
                                            className="btn btn-sm btn-soft-primary"
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-danger"
                                            onClick={() => {
                                              ConfirmDelete(element._id)
                                            }}
                                          >
                                            <i
                                              className="mdi mdi-delete-outline"
                                              id="deletetooltip"
                                            />
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                )
                              })
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </Row>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={page => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ShowNFT.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ShowNFT)
