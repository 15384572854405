import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, Table, FormGroup, Input, InputGroup } from "reactstrap";
// import Flatpickr from "react-flatpickr";

import '../../assets/scss/style.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//i18n
import { withTranslation } from "react-i18next";

const NFTImage1 = 'https://i0.wp.com/opvapeshop.com/wp-content/uploads/2019/11/IMG_2511.jpeg?fit=1568%2C1176&ssl=1';
const NFTImage2 = 'https://www1.lovethatdesign.com/wp-content/uploads/2021/09/Love-That-Design-L_eto-Cafe-Dubai-10-1024x767.jpg';
const NFTImage3 = 'https://dynamicassets.azureedge.net/uploads/offer/mainPicture/5c7275d8b1a1f.jpg';
const NFTImage4 = 'https://0.rc.xiniu.com/g1/M00/4A/65/CgAGS2Cs0KCAc-k6AB-zgwpRdxE408.png';
const NFTImage5 = 'https://0.rc.xiniu.com/g1/M00/4A/65/CgAGS2Cs0SyAJHmNAAIcipWQs1Y19.jpeg';
const NFTImage6 = 'https://www.aquariumsource.com/wp-content/uploads/2020/07/betta-fish-laying-bottom-of-tank-380x220.jpg';
const NFTImage7 = 'https://www.visitsealife.com/sydney/media/xopndkc2/blacktip-reef-shark-3.jpg?anchor=center&mode=crop&format=webp&quality=80&width=600&height=460';
const NFTImage8 = 'https://indiansinuae.org/wp-content/uploads/2021/07/large-aquarium-dubai-united-arab-emirates.jpg';
const NFTImage9 = 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/39/95/b8.jpg';
const NFTImage10 = 'https://bluewatersdubai.ae/-/media/Project/MeraasEcosystem/Bluewater/Shop/The-Little-Things/1.jpg';
const NFTImage11 = 'https://bluewatersdubai.ae/-/media/Project/MeraasEcosystem/Bluewater/Shop/The-Little-Things/4.jpg';
const NFTImage12 = 'https://www.figstop.com/wp-content/uploads/2022/01/Marvel-Action-Figures-Little-Things-Bluewaters-Dubai.jpg';
const NFTImage13 = 'https://kadabook-development.s3.amazonaws.com/media/Place_Images/blu-kiosk-dubai-mall/Gallery/98aa75d2-ec1c-47cc-be3d-5ca5b8215edc-h.jpeg';
const NFTImage14 = 'https://lh5.googleusercontent.com/p/AF1QipNt_fpS0NwzfyUp8nmPYTo4qioo9yhPMQRHepGo=w500-h500-k-no';

const Revenue = props => {

    //meta title
    document.title = "Revenue";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [dateFilter, setDateFilter] = useState([]);

    const handleDateEvent = async (event) => {
        console.log(event.length)
        if (event.length == 2) {
            
        }
    }
    return (
        <React.Fragment>

            <div className="page-content margin-custom">
                <Container fluid>

                    <Row>
                        <Col xl="12">
                            <Row>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium  font-size-14 fw-bold"> Today's Revenue </p>
                                                    <h5 className="mb-0">
                                                        <span className="font-size-13 text-muted">
                                                            $ 1263.00 - Amount
                                                        </span> <br />
                                                        <span className="font-size-13 text-muted">
                                                            $ 986.00 - Commission
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-primary">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium  font-size-14 fw-bold"> Last 7 Days Revenue </p>
                                                    <h5 className="mb-0">
                                                        <span className="font-size-13 text-muted">
                                                            $ 3210.00 - Amount
                                                        </span> <br />
                                                        <span className="font-size-13 text-muted">
                                                            $ 2986.00 - Commission
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-success align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-success">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium  font-size-14 fw-bold"> Last 30 Days Revenue </p>
                                                    <h5 className="mb-0">
                                                        <span className="font-size-13 text-muted">
                                                            $ 6530.00 - Amount
                                                        </span> <br />
                                                        <span className="font-size-13 text-muted">
                                                            $ 5423.00 - Commission
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-info align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-info">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium  font-size-14 fw-bold">Total Revenue </p>
                                                    <h5 className="mb-0">
                                                        <span className="font-size-13 text-muted">
                                                            $ 10363.00 - Amount
                                                        </span> <br />
                                                        <span className="font-size-13 text-muted">
                                                            $ 9936.00 - Commission
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-warning align-self-center mini-stat-icon bg_gredient">
                                                    <span className="avatar-title rounded-circle bg_gredient">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card className="border">
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1"></h5>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                            <div className="position-relative" style={{ marginTop: '10px' }}>
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search...`}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>

                                        <Flatpickr className="form-control d-block" style={{ width: '200px', marginRight: '8px' }} onChange={(date) => handleDateEvent(date)} placeholder="Select Dates" options={{ mode: "range", dateFormat: "Y-m-d" }} />
                                        <div className="flex-shrink-0">
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th># ID</th>
                                                    <th> NFT </th>
                                                    <th> Type </th>
                                                    <th> User </th>
                                                    <th> Owner </th>
                                                    <th> Amount </th>
                                                    <th> Commission </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                TRANS96589523
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage1}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1 text-color-gredient">The Caesars Palace </h5>
                                                                <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Mint</h5>
                                                            <p> 15 Nov, 2022 </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Maurice Read</h5>
                                                            <p> maurice.read@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Billy Bates</h5>
                                                            <p> billy.bates@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $41.00
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $16.10
                                                            </h5>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                TRANS96589523
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage3}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1 text-color-gredient">The Minting Space </h5>
                                                                <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Mint</h5>
                                                            <p> 15 Nov, 2022 </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Johnny Briggs</h5>
                                                            <p> johnny.briggs@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Javed</h5>
                                                            <p> javed.332@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $25.20
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $10.20
                                                            </h5>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                TRANS96589523
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage4}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1 text-color-gredient">Beast Ape </h5>
                                                                <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Buy</h5>
                                                            <p> 15 Nov, 2022 </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Arnold Fothergill</h5>
                                                            <p> arnold.fothergill@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Frank Hearne</h5>
                                                            <p> frank.hearne@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $43.00
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $12.00
                                                            </h5>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                TRANS96589523
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage6}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1 text-color-gredient">The Bull </h5>
                                                                <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Sell</h5>
                                                            <p> 15 Nov, 2022 </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Billy Newham</h5>
                                                            <p> billy.newham@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">William Gunn</h5>
                                                            <p> william.gunn@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $36.00
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $11.20
                                                            </h5>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                TRANS96589523
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={NFTImage8}
                                                                className="avatar-sm me-3 rounded-circle"
                                                                alt="img"
                                                            />
                                                            <div className="">
                                                                <h5 className="font-size-14 mb-1 text-color-gredient">The Caesars Palace </h5>
                                                                <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Buy</h5>
                                                            <p> 15 Nov, 2022 </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Bobby Peel</h5>
                                                            <p> bobby.peel@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-0 text-color-gredient">George Lohmann</h5>
                                                            <p> george.lohmann@gmail.com </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $56.30
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                $19.10
                                                            </h5>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};


Revenue.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Revenue);
