import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, CardTitle, Button, FormFeedback } from "reactstrap";
import '../../../assets/scss/style.css';
import { toast } from "react-toastify";
import { apiRoute } from "routes/apiRoutes";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router'

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const NFTImage7 = 'https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg';

const ShowNFT = props => {

    //meta title
    document.title = "Collection Create";
    const BASE_PATH = process.env.REACT_APP_BASE_PATH;
    const { loader, loading, setloading } = useContext(LoaderContext);
    const history = useHistory();
    const [resultData, setResultData] = useState({ name: '', description: '', royalties: '', bannerImg: '', logoImg: '', nftlimit: '',ticketPrice:'', collection_type: '', no_uses: '', start_date: '', end_date: '', time_period: '', property_img: '', property_banner_img: '', property_id: '', category_id : '',nftTicket :'' });
    const [logoImg, setLogoImg] = useState('');
    const [bannerImg, setBannerImg] = useState('');
    const [nftTicket, setnftTicket] = useState('');

    const { collectionID } = useParams();

    console.log(collectionID)

    const changeValues = (event) => {
        setResultData({ ...resultData, [event.target.name]: event.target.value })
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: resultData.name,
            description: resultData.description,
            royalties: resultData.royalties,
            nftlimit: resultData.nftlimit,
            // ticketPrice:resultData.ticketPrice,
            collection_type: resultData.collection_type,
            no_uses: resultData.no_uses,
            time_period: resultData.time_period,
            logoImg: logoImg,
            bannerImg: bannerImg,
            preLogoImg: resultData.logoImg,
            preBannerImg: resultData.bannerImg,
            property_img: resultData.property_img,
            property_banner_img: resultData.property_banner_img,
            property_id: resultData.property_id,
            category_id: resultData.category_id,
            // nftTicket:nftTicket
        },
        validationSchema: Yup.object({
            name: Yup.string().required("This field is required."),
            royalties: Yup.number().max(100).required("This field is required."),
            nftlimit: Yup.number().required("This field is required."),
            // ticketPrice: Yup.string().required("This field is required."),
            description: Yup.string().required("This field is required."),
            collection_type: Yup.string().required("This field is required."),
            no_uses: Yup.string().required("This field is required."),
            time_period: Yup.string().required("This field is required."),
            category_id: Yup.string().required("This field is required."),
            // logoImg: Yup.string(),
            // bannerImg: Yup.string(),
            // preLogoImg: Yup.string(),
            // preBannerImg: Yup.string(),
        }),
        onSubmit: (values) => {
            console.log(values)
            updateCollectionFun(values)
        }
    });

    const inpLevel = [{ question: "", answer: "" }]
    const [inputLevel, setinputLevel] = useState(inpLevel)

    function handleAddFieldsFAQ() {
        const item1 = { question: "", answer: "" }
        setinputLevel([...inputLevel, item1])
    }

    function handleRemoveLevel(idx) {
        document.getElementById("nestedLevel" + idx).remove()
    }


    const fetchData = () => {
        try {
            axios.get(apiRoute.collectionSingleDetail + '?collectionID=' + collectionID).then(res => {


                var resultObject = res.data.data.collection;
                setResultData({ name: resultObject.name, description: resultObject.description, royalties: resultObject.royalties, bannerImg: resultObject.bannerImg, logoImg: resultObject.logoImg, nftlimit: resultObject.nftlimit,ticketPrice:resultObject.ticketPrice, collection_type: resultObject.type, no_uses: resultObject.useTime, start_date: resultObject.startDateTime, end_date: resultObject.endDateTime, time_period: resultObject.startDateTime + ' / ' + resultObject.endDateTime, property_img: resultObject.logoImg, property_banner_img: resultObject.bannerImg, property_id: resultObject.propertyId, category_id : resultObject.categoryId,nftTicket:resultObject.nftTicket })
                if (res.data.data.collection.faq.length > 0) {
                    setinputLevel(res.data.data.collection.faq)
                }


                let eventCollection = {};
                eventCollection.label = res.data.data.property?.name;
                eventCollection.value = res.data.data.property?._id;
                setselectedCollectionMulti(eventCollection)

                let eventCategory = {};
                eventCategory.label = res.data.data.category?.name;
                eventCategory.value = res.data.data.category?._id;
                setSelectedCategories(eventCategory)
            })
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchData();
    }, [])

    const updateCollectionFun = async (data) => {

        console.log(data, 'form values >>>>>>>>>>>>>>>>>>>>>>')

        var StartEndDateTime = data.time_period.split(" / ");
        var questionElements = document.getElementsByClassName("question-cls");
        var answerElements = document.getElementsByClassName("answer-cls");

        var FAQrecord = [];
        for (var i = 0; i < questionElements.length; i++) {
            if (questionElements[i].value != '' && answerElements[i].value != '') {
                var record = {};
                record.question = questionElements[i].value;
                record.answer = answerElements[i].value;
                FAQrecord[i] = record;
            }
        }

        var formData = new FormData();
        formData.append('bannerImg', data.bannerImg)
        formData.append('description', data.description)
        formData.append('logoImg', data.logoImg)
        formData.append('property_image', data.property_img)
        formData.append('mapView_image', data.property_banner_img)
        formData.append('propertyId', (data.property_id && data.property_id != undefined) ? data.property_id : '')
        formData.append('categoryId', (data.category_id && data.category_id != undefined) ? data.category_id : '')
        formData.append('royalties', 0)
        formData.append('name', data.name)
        formData.append('preLogoImg', data.preLogoImg)
        formData.append('preBannerImg', data.preBannerImg)
        formData.append('collectionID', collectionID)
        formData.append('nftlimit', data.nftlimit)
        formData.append('type', data.collection_type)
        formData.append('startDateTime', StartEndDateTime[0])
        formData.append('endDateTime', StartEndDateTime[1])
        formData.append('useTime', data.no_uses)
        // formData.append('nftTicket',data.nftTicket)
        // formData.append('ticketPrice',data.ticketPrice)
        formData.append('faq', JSON.stringify(FAQrecord))
        setloading(true);
        await axios.put(apiRoute.collectionUpdate, formData)
            .then((response) => {
                setloading(false);
                toast.success(response.data.message);
                history.push('/admin/nft/collections');
            }).catch((error) => {
                setloading(false);
                toast.error(error.response.data.error);
            })
    }

    const handleDateEvent = async (event) => {
        console.log(event.length)
        if (event.length == 2) {
            let filterDate = [];
            filterDate[0] = moment(event[0]).format('YYYY-MM-DD');
            filterDate[1] = moment(event[1]).format('YYYY-MM-DD');

            setResultData({ ...resultData, ['time_period']: filterDate[0] + ' / ' + filterDate[1] })

        } else {
            setResultData({ ...resultData, ['time_period']: "" })
        }
    }

    const [listCollection, setListCollection] = useState([])
    const [optionCollectionGroup, setOptionCollectionGroup] = useState([])
    const fetchCollections = () => {
        try {
            axios
                .get(apiRoute.getProperty)
                .then(res => {

                    setListCollection(res.data.data.property)

                    var propertyOptionGroup = []
                    res.data.data.property.map((value, index) => {
                        var optionData = {}
                        optionData.label = value.name
                        optionData.value = value._id
                        propertyOptionGroup.push(optionData)
                    })
                    setOptionCollectionGroup(propertyOptionGroup)
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Something went wrong!")
                })
        } catch (err) {
            console.log(err)
        }
    }

    const [listCategories, setListCategories] = useState([])
    const [optionCategoriesGroup, setOptionCategoriesGroup] = useState([])
    const fetchCategories = () => {
        try {
            axios
                .get(apiRoute.categoriesListing)
                .then(res => {

                    setListCategories(res.data.data.category.items)

                    var propertyOptionGroup = []
                    res.data.data.category.items.map((value, index) => {
                        var optionData = {}
                        optionData.label = value.name
                        optionData.value = value._id
                        propertyOptionGroup.push(optionData)
                    })
                    setOptionCategoriesGroup(propertyOptionGroup)
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Something went wrong!")
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchCollections()
        fetchCategories()
    }, [])

    const [selectedCollectionMulti, setselectedCollectionMulti] = useState(null)

    function handleCollectionMultiRemove(selectedCollectionMulti) {
        setselectedCollectionMulti(null)
    }
    function handleCollectionMulti(selectedCollectionMulti) {
        setselectedCollectionMulti(selectedCollectionMulti)

        if (listCollection) {
            listCollection.map((value, index) => {
                if (value._id == selectedCollectionMulti.value) {
                    setResultData({ name: value.name, description: value.description, royalties: 0, bannerImg: resultData.bannerImg, logoImg: resultData.logoImg, nftlimit: resultData.nftlimit, collection_type: resultData.collection_type, no_uses: resultData.no_uses, start_date: resultData.start_date, end_date: resultData.end_date, time_period: resultData.time_period, property_img: value.propertyImg, property_banner_img: value.mapView, property_id: selectedCollectionMulti.value })
                }
            })
        }
    }


    // Categories
    const [selectedCategories, setSelectedCategories] = useState(null)
    function handleCategoriesRemove(selectedCollectionMulti) {
        setSelectedCategories(null)
        setResultData({ ...resultData, ['category_id']: '' })
    }
    function handleCategories(event) {
        setSelectedCategories(event)
        setResultData({ ...resultData, ['category_id']: event.value })
    }


    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }} className="validation-cls">
                                        <CardTitle className="h5 mb-4 text-color-gredient">Edit Collection</CardTitle>

                                        <Row className="">
                                            <Col md={6}>
                                                <Row>

                                                    <Col md={12}>
                                                        <label htmlFor="floatingnameInput">Select Property <span className="font-size-11 text-secondary"> [ Do you want to link this collection to a property? ] </span> </label>
                                                        <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                            <Select value={selectedCollectionMulti} isMulti={false} options={optionCollectionGroup} className="select2-selection"
                                                                onChange={e => {
                                                                    handleCollectionMulti(e)
                                                                }}
                                                                onKeyDown={e => {
                                                                    handleCollectionMultiRemove(e)
                                                                }}
                                                            />
                                                            {validation.touched.collection_id && validation.errors.collection_id ? (
                                                                <FormFeedback type="invalid"> {validation.errors.collection_id}  </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col md={12}>
                                                        <label htmlFor="floatingnameInput">Select Category  </label>
                                                        <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                            <Select value={selectedCategories} isMulti={false} options={optionCategoriesGroup} className="select2-selection"
                                                                onChange={e => {
                                                                    handleCategories(e)
                                                                }}
                                                                onKeyDown={e => {
                                                                    handleCategoriesRemove(e)
                                                                }}
                                                            />
                                                            {validation.touched.category_id && validation.errors.category_id ? (
                                                                <FormFeedback type="invalid"> {validation.errors.category_id}  </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label htmlFor="floatingnameInput">Name</label>
                                                            <input type="text" name="name" className="form-control" id="floatingnameInput" placeholder="Enter Collection Name..." onBlur={validation.handleBlur} onChange={changeValues} value={resultData.name} />
                                                            {validation.touched.name && validation.errors.name ? (
                                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="d-none">
                                                        <div className="mb-3 mt-2">
                                                            <label htmlFor="floatingnameInput">Royalties</label>
                                                            <input type="number" name="royalties" className="form-control" id="floatingnameInput" placeholder="Enter Royalties..." onBlur={validation.handleBlur} onChange={changeValues} value={resultData.royalties} />
                                                            {validation.touched.royalties && validation.errors.royalties ? (
                                                                <FormFeedback type="invalid">{validation.errors.royalties}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="floatingnameInput1">Description</label>
                                                    <textarea name="description" className="form-control" id="floatingnameInput1" rows={3} placeholder="Enter Description..." style={{ height: '206px' }} onBlur={validation.handleBlur} onChange={changeValues} value={resultData.description}></textarea>
                                                    {validation.touched.description && validation.errors.description ? (
                                                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="mb-3 mt-0">
                                                    <label htmlFor="floatingnameInput">Number of NFT</label>
                                                    <input type="number" name="nftlimit" className="form-control" id="floatingnameInput" placeholder="Enter Limit..." onBlur={validation.handleBlur} onChange={changeValues} value={resultData.nftlimit} />
                                                    {validation.touched.nftlimit && validation.errors.nftlimit ? (
                                                        <FormFeedback type="invalid">{validation.errors.nftlimit}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="mb-3 mt-0">
                                                    <label htmlFor="floatingnameInput">Collection Type</label>
                                                    <select
                                                        defaultValue="0"
                                                        name="collection_type"
                                                        className="form-select"
                                                        onBlur={validation.handleBlur}
                                                        onChange={changeValues}
                                                    >
                                                        <option value=""> Select... </option>
                                                        {
                                                            (resultData.collection_type == 'event') ? <option value="event" selected> Event Type </option> : <option value="event"> Event Type </option>
                                                        }
                                                        {
                                                            (resultData.collection_type == 'membership') ? <option value="membership" selected> Membership Type </option> : <option value="membership"> Membership Type </option>
                                                        }

                                                    </select>
                                                    {validation.touched.collection_type && validation.errors.collection_type ? (
                                                        <FormFeedback type="invalid">{validation.errors.collection_type}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            
                                            <Col md={5} className="mb-4">
                                                <label htmlFor="floatingnameInput">Collection Logo</label>
                                                <input type="file" name="logoImg" className="form-control" id="floatingnameInput" placeholder="Upload Collection Logo" onChange={(event) => {
                                                    setLogoImg(event.currentTarget.files[0]);
                                                }} />
                                                {validation.touched.logoImg && validation.errors.logoImg ? (
                                                    <FormFeedback type="invalid">{validation.errors.logoImg}</FormFeedback>
                                                ) : null}
                                            </Col>

                                            <Col md={1} className="mb-4 overflow-hidden">
                                                <img src={BASE_PATH + resultData.property_img} style={{ height: '80px' }} />
                                            </Col>


                                            <Col md={3}>
                                                <div className="mb-3 mt-0">
                                                    <label htmlFor="floatingnameInput">Number of Uses  <span className="font-size-11 text-secondary"> [ Put 0 to enter unlimited times ] </span></label>
                                                    <input type="number" name="no_uses" className="form-control" id="floatingnameInput" placeholder="Enter Number..." onBlur={validation.handleBlur} onChange={changeValues} value={resultData.no_uses} />
                                                    {validation.touched.no_uses && validation.errors.no_uses ? (
                                                        <FormFeedback type="invalid">{validation.errors.no_uses}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mb-3 mt-0">
                                                    <label htmlFor="floatingnameInput">Validity Period</label>
                                                    <Flatpickr className="form-control d-block" style={{ marginRight: '8px' }} onChange={(date) => handleDateEvent(date)} placeholder="Select Dates" options={{ mode: "range", dateFormat: "Y-m-d" }} value={`${resultData.start_date} to ${resultData.end_date}`} />
                                                    {validation.touched.time_period && validation.errors.time_period ? (
                                                        <FormFeedback type="invalid">{validation.errors.time_period}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={5} className="mb-4">
                                                <label htmlFor="floatingnameInput">Collection Banner</label>
                                                <input type="file" name="bannerImg" className="form-control" id="floatingnameInput" placeholder="Upload Collection Banner" onChange={(event) => {
                                                    setBannerImg(event.currentTarget.files[0]);
                                                }} />
                                                {validation.touched.bannerImg && validation.errors.bannerImg ? (
                                                    <FormFeedback type="invalid">{validation.errors.bannerImg}</FormFeedback>
                                                ) : null}
                                            </Col>

                                            <Col md={1} className="mb-4 overflow-hidden">
                                                <img src={BASE_PATH + resultData.property_banner_img} style={{ height: '80px' }} />
                                            </Col>


                                            <Col md={12} className="mb-2 mt-3">
                                                <label className="font-size-18">FAQ -  </label>
                                                <Button
                                                    color="success"
                                                    className="inner btn-sm ms-2 mb-1"
                                                    onClick={() => {
                                                        handleAddFieldsFAQ()
                                                    }}
                                                >
                                                    Add FAQ
                                                </Button>
                                                {inputLevel.map((field, key) => (
                                                    <Row
                                                        key={key}
                                                        id={"nestedLevel" + key}
                                                    >

                                                        <Col md={10}>
                                                            <div className="mb-3">
                                                                <label>- Question</label>
                                                                <input type="text" name="question[]" className="form-control question-cls" placeholder="Writer here..." defaultValue={field.question} />
                                                            </div>
                                                        </Col>

                                                        <Col md={2}>
                                                            <div className="mt-2 mt-md-0 d-grid">
                                                                <label className="invisible">delete</label>
                                                                <Button color="primary" className="inner"
                                                                    onClick={() => {
                                                                        handleRemoveLevel(key)
                                                                    }}
                                                                    block
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col md={12} className="pe-0">
                                                            <div className="mb-3">
                                                                <label>- Answer</label>
                                                                <textarea name="answer[]" className="form-control answer-cls" id="floatingnameInput1" rows={2} placeholder="Enter Description..." defaultValue={field.answer} style={{ height: '90px' }}></textarea>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>


                                        </Row>

                                        <div>
                                            <Link to="/admin/nft/collections" className="btn btn-secondary w-md mt-2 me-2">
                                                Cancel
                                            </Link>
                                            <button type="submit" className="btn btn-primary w-md mt-2">Update</button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
