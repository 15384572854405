import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Table } from "reactstrap";
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import '../../assets/scss/style.css'
import classnames from "classnames"

import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import { dateformat, getUserDetail, dateTimeformat } from "admin/commonFunction"
import { LoaderContext } from "context/ContextProvider"
import { useHistory } from 'react-router'
import { withTranslation } from "react-i18next";

const NFTImage7 = 'https://bluewatersdubai.ae/-/media/Project/MeraasEcosystem/Bluewater/Whats-On/droneshow/droneshow.jpg';

import img1 from "../../assets/images/users/avatar-1.jpg";
import img2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/users/avatar-3.jpg";
import img4 from "../../assets/images/users/avatar-4.jpg";
import img5 from "../../assets/images/users/avatar-5.jpg";
import img6 from "../../assets/images/users/avatar-6.jpg";
import img7 from "../../assets/images/users/avatar-7.jpg";
import img8 from "../../assets/images/users/avatar-8.jpg";

const NFTImage1 = 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Ferris_Wheel_Ain_Dubai_in_Dubai_02.jpg';
const NFTImage2 = 'https://dubaieyewheel.com/wp-content/uploads/2022/01/WhatsApp-Image-2022-01-13-at-12.02.43-AM-300x300.jpeg';
const NFTImage3 = 'https://www.traveller.com.au/content/dam/images/h/2/1/0/x/2/image.related.socialLead.620x349.h210wq.png/1642123242787.jpg';
const NFTImage4 = 'https://images.prismic.io/mystique/54b948bc-e603-47cc-a2b7-48085cb1bf0e_ain+dubai+1.jpg?auto=compress%2Cformat&w=1200&h=450&q=75&crop=faces&fm=webp&rect=0,0,797,498';
const NFTImage5 = 'https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg';
const NFTImage6 = 'https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg';
const NFTImage8 = 'https://cdn.pixabay.com/photo/2020/12/13/23/18/ferris-wheel-5829622_1280.jpg';


const ShowNFT = props => {
    const { id } = useParams()
    const settings = {
        centerMode: false,
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    //meta title
    document.title = "NFT's Details";
    const BASE_PATH = process.env.REACT_APP_BASE_PATH
    const [eventDetail, setEventDetail] = useState("")
    const [propertyDetail, setPropertyDetail] = useState("")
    const [nftDetail, setNFTDetail] = useState("")
    const [collectionDetail, setCollectionDetail] = useState("")

    const fetchEvent = () => {
        try {
            axios
                .get(apiRoute.eventDetail + "?eventID=" + id)
                .then(res => {

                    console.log(res, 'event response >>>>>>>>>>>>>>>')
                    setEventDetail(res.data.data.event)
                    setPropertyDetail(res.data.data.property)
                    setNFTDetail(res.data.data.nftDetails)
                    setCollectionDetail(res.data.data.collectionDetails)

                })
                .catch(error => {
                    console.log(error)
                    toast.error("Something went wrong!")
                })
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchEvent()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>
                    <Row>
                        <Col xl="3" className="p-2 border">
                            <img src={BASE_PATH + eventDetail?.logo} style={{ width: '100%', height: '100%' }} />
                        </Col>

                        <Col xl="9">
                            <Card className="border mb-0">
                                <div className="p-4 mt-2">
                                    <h5 className="text-primary"> {eventDetail.name}  </h5>
                                    <p className="mb-2 mt-2 text-muted">
                                        <i className="mdi mdi-google-maps text-info me-1 d-none"></i> 
                                        <i className="mdi mdi-calendar text-info me-1"></i> {dateTimeformat(eventDetail.startDateTime)} &nbsp;to&nbsp; {dateTimeformat(eventDetail.endDateTime)}
                                    </p>

                                    <div className="mb-2">
                                    </div>

                                    <p className="mt-4 mb-0 p-0 fw-bold"> Details : </p>
                                    <p className="mt-0 mb-3">  {eventDetail.description}  </p>

                                    <div className="mb-2">
                                        <Row>
                                            <Col md={12}>
                                                <p className="mt-4 mb-0 p-0 fw-bold"> Collection Details : </p>
                                                <h5 className="font-size-12 text-muted my-1" >
                                                    <i className="mdi mdi-checkbox-marked-outline me-1"></i> {collectionDetail.name}
                                                    <i className="mdi mdi-collage me-1 ms-4"></i> NFT : 0 / {collectionDetail.nftlimit}
                                                    <i className="mdi mdi-calendar me-1 ms-4"></i> Created At : {dateformat(collectionDetail.createdAt)}
                                                </h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card>

                        </Col>

                        <h4 className="card-title mb-3 mt-3"> Visited Users <span className="font-theme-color"> [0] </span> </h4>
                        <Col xl="12" className="border">
                            <Card className="my-2">

                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <Thead>
                                            <Tr>
                                                <Th>Sr. No.</Th>
                                                <Th>User</Th>
                                                <Th>NFT</Th>
                                                <Th>Date Time</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <tr>
                                                <td colSpan={4}> <span className="co-name"> No Record Found </span> </td>
                                            </tr>
                                            <tr className="d-none">
                                                <td> <span className="co-name"> 7 </span> </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img7}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14">Andrew Greenwood </h5>
                                                            <p className="text-muted mb-0">andrew.greenwood@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={NFTImage8}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-1">Nyolings </h5>
                                                            <p className="text-muted mb-0"># 1555</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>09 Nov., 2022 01:00 AM</td>
                                            </tr>
                                        </Tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
