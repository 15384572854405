import { ethers } from "ethers"
export const ethereum = window.ethereum

// check if metamask extension is installed on the browser
export const isMetaMaskInstalled = () => {
  if (ethereum) {
    return true
  }

  return false
}

// connect to metakmask wallet
export const connectWallet = async () => {
  const accounts = await ethereum.request({ method: "eth_requestAccounts" })
  return accounts
}
export const connectAccount = async () =>{
  const accounts = await ethereum.request({
      method: 'eth_accounts',
  });
  return accounts;
}
export const getBalance = async address => {
  const Balance = await ethereum.request({
    method: "eth_getBalance",
    params: [address, "latest"],
  })
  return ethers.utils.formatEther(Balance)
}

export const forcenetwork = async () => {
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x5" }], // chainId must be in hexadecimal numbers
  })
}

export const isWalletConnected = () => {
  if (localStorage.getItem("isWalletConnected") === "true") {
    return true
  }

  return false
}

export const connectWalletLocaly = () => {
  localStorage.setItem("isWalletConnected", true)
}

export const disconnectWallet = () => {
  localStorage.removeItem("isWalletConnected")
}

// module.exports = {
//   google: {
//     API_KEY: "",
//     CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
//     SECRET: "",
//   },
//   facebook: {
//     APP_ID: "",
//   },
// }
