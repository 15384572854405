import PropTypes, { element } from "prop-types"
import React, { useEffect, useState ,useContext} from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  Col,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
} from "reactstrap"

import noDataImage from '../../../assets/images/nodata-found.png';
import "../../../assets/scss/style.css"
import { dateformat } from "admin/commonFunction"
import { apiRoute } from "routes/apiRoutes"
import { LoaderContext } from "context/ContextProvider"
import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import userImage1 from "../../../assets/images/small/img-1.jpg"
import userImage2 from "../../../assets/images/small/img-2.jpg"
import userImage3 from "../../../assets/images/small/img-3.jpg"
import userImage4 from "../../../assets/images/small/img-4.jpg"
import userImage5 from "../../../assets/images/small/img-5.jpg"
import userImage6 from "../../../assets/images/small/img-6.jpg"
import userImage7 from "../../../assets/images/small/img-7.jpg"

const Transaction = props => {
  //meta title
  document.title = "Categories"
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const {loader,loading,setloading} = useContext(LoaderContext);
  const [varyingModal, setVaryingModal] = useState(false)
  const [collection, setCollection] = useState({
    name: "",
    icon: ""
  })

  const [err, seterr] = useState({
    nameErr: "",
    iconErr: ""
  })

  const [image, setImage] = useState({
    logoImg: "",
    bannerImg: "",
  })
  const [data, setData] = useState([])
  const [edit, setEdit] = useState({
    id: "",
    name: "",
    icon: "",
  })
  const [updateModal, setUpdateModal] = useState(false)

  function tog_updateModal() {
    setUpdateModal(!updateModal)
  }

  const handleFileChange = e => {
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);
    setEdit({ ...edit, newIcon: url });
  }

  const handlefields = () => {
    if (collection.name == "") {
      seterr({ ...err });
    }
    else {
      setnameErr("");
      setname(true)
    }
  }

  const handleCategory = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (formData.get('name') == "") {
      console.log(123);
      seterr({ ...err, nameErr: "Please Enter category name" })
      return
    }
    if (formData.get('categoryIcon').name == "") {
      seterr({ ...err, iconErr: "Please Enter category image" })
      return
    }
    setloading(true)
    try {
      axios
        .post(apiRoute.categoriesCreate, formData)
        .then(res => {
          console.log(res);
          setloading(false);
          toast.success("Category Created Successfully");
          fetchData()
        }).catch((error) => {
          
            setloading(false);
            toast.error(error.response.data.error);
        })
    } catch (err) {
      console.log(err)
      setloading(false);
      toast.error("An error occurred !");

    }
    console.log(formData.get('categoryIcon'), "data")
    tog_varyingModal()
  }

  const deleteCategory = categoryId => {
    try {
      axios
        .delete(apiRoute.categoriesDelete+`?id=${categoryId}`)
        .then(res => {
          console.log(res)
          toast.success("Category Deleted Successfully");
          fetchData()
        })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !");

    }
  }

  const updateCategory = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target);
    setloading(true);
    try {
      axios
        .put(apiRoute.categoriesUpdate+`?id=${edit.id}`, formData)
        .then(res => {
          console.log(res)
          setloading(false);
          tog_updateModal();
          toast.success("Category Updated Successfully");
          fetchData()
        }).catch((error) => {
          
          setloading(false);
          toast.error(error.response.data.error);
      })
    } catch (err) {
      console.log(err)
      setloading(false);
      toast.error("An error occurred !");
    }
  }

  const editCategory = (id, name, icon) => {
    tog_updateModal()
    console.log(icon, "icon")
    setEdit({ id, name, icon })
    console.log(edit)
  }

  function tog_varyingModal() {
    setVaryingModal(!varyingModal)
  }

  const fetchData = () => {
    try {
      axios.get(apiRoute.categoriesListing).then(res => {
        console.log(res)
        setData(res.data.data.category.items)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  
  const ConfirmDelete = id => {
  //  let URL = BASE_URL + 'user/delete/' + id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteCategory(id)
      }
    })
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">
                      {" "}
                      Categories{" "}
                    </h5>

                    <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                      <div
                        className="position-relative"
                        style={{ marginTop: "10px" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search...`}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        to="#!"
                        className="btn btn-secondary me-1"
                        onClick={() => {
                          tog_varyingModal()
                        }}
                      >
                        <i className="bx bx-plus"></i> Create Category
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row>
                    {
                    (data.length == 0) ?
                        <Col sm="12" className="text-center">
                          <img src={noDataImage} style={{width: '505px'}} />
                        </Col>
                     : data.map((element, index) => {
                      
                      return (
                        <Col sm="3" key={index}>
                          <Card className="mini-stats-wid border">
                            <CardBody>
                              <div className="d-flex">
                                <div className="avatar-sm me-3">
                                  <img
                                    src={`${process.env.REACT_APP_BASE_PATH +
                                      element.categoryIcon
                                      }`}
                                    className="avatar-title rounded-circle"
                                  />
                                </div>
                                <div className="flex-grow-1">
                                  <h5 className="mb-2 text-color-gredient">{element.name}</h5>
                                  <p className="text-muted mb-0">
                                    {dateformat(element.createdAt)}
                                  </p>
                                </div>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    tag="a"
                                    className="dropdown-toggle"
                                  >
                                    <i className="bx bx-dots-vertical-rounded text-color-gredient" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        editCategory(
                                          element._id,
                                          element.name,
                                          element.categoryIcon
                                        )
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        ConfirmDelete(element._id)
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        isOpen={varyingModal}
        toggle={() => {
          tog_varyingModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title text-color-gredient" id="exampleModalLabel">
            {" "}
            New Category{" "}
          </h5>
          <button
            type="button"
            onClick={() => {
              setVaryingModal(false)
            }}
            className="btn-close"
          ></button>
        </div>
        <form onSubmit={handleCategory}>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                id="category-name"
                placeholder="Enter Category Name"
                onChange={e => {
                  setCollection({ ...collection, name: e.target.value });
                }}
              />
              <span style={{ color: 'red' }}>{collection.name ? "" : err.nameErr}</span>
            </div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Category Icon
              </label>
              <input
                name="categoryIcon"
                className="form-control"
                type="file"
                id="formFile"
                onChange={e => { setCollection({ ...collection, icon: e.target.files[0].name }); }}
              />
              <span style={{ color: 'red' }}>{collection.icon ? "" : err.iconErr}</span>
            </div>
            {/* <div className="mb-3">
              <label htmlFor="royalities" className="col-form-label">
              Royalties
              </label>
              <input type="text" className="form-control" name="royalties" id="royalities" placeholder="Enter Royalties Percentage" onChange={(e) => {setCategory({...category,"royalties":e.target.value})}}/>
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="col-form-label">
              Description
              </label>
              <textarea className="form-control" name="description" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Description" onChange={(e) => {setCategory({...category,"description":e.target.value})}}/>
            </div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
              Collection Logo
              </label>
              <input onChange={(e)=>setImage({...image,logoImg:e.target.files})} name="logoImg" className="form-control" type="file" id="formFile" />
            </div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
              Collection Banner
              </label>
              <input onChange={(e)=>setImage({...image,bannerImg:e.target.files})} name="bannerImg"  className="form-control" type="file" id="formFile" />
            </div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setVaryingModal(false)
              }}
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Create
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={updateModal}
        toggle={() => {
          tog_updateModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title text-color-gredient" id="exampleModalLabel">
            {" "}
            Edit Category{" "}
          </h5>
          <button
            type="button"
            onClick={() => {
              setUpdateModal(false)
            }}
            className="btn-close"
          ></button>
        </div>
        <form onSubmit={updateCategory}>
          <div className="modal-body">
            <div>
              {/* <label htmlFor="formFile" className="form-label">
              Category Icon:
              </label> */}

              <label htmlFor="formFile" className="form-label">
                <div className="position-relative">
                  <img
                    src={`${edit?.newIcon || process.env.REACT_APP_BASE_PATH + edit.icon}`}
                    className=""
                    style={{ height: '87px', width: '90px' }}
                  />
                  <i className="bx bx-edit" style={{ fontSize: '19px', position: 'absolute', bottom: '4px', backgroundColor: 'white', padding: '1px', right: '4px', borderRadius: '2px' }}></i>
                </div>
              </label>
              <input
                name="categoryIcon"
                className="form-control"
                type="file"
                id="formFile"
                hidden
                onChange={handleFileChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Name:
              </label>
              <input
                type="text"
                value={edit ? edit.name : ""}
                className="form-control"
                name="name"
                id="category-name"
                placeholder="Enter Category Name"
                onChange={e => {
                  setEdit({ ...edit, name: e.target.value })
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setUpdateModal(false)
              }}
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  )
}

Transaction.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Transaction)
