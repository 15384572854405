import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, Table, FormGroup, Input, InputGroup } from "reactstrap";
// import Flatpickr from "react-flatpickr";

import '../../assets/scss/style.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import noDataImage from "../../assets/images/nodata-found.png"
import { dateformat, getUserDetail } from "admin/commonFunction"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import { toast } from "react-toastify"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"

//i18n
import { withTranslation } from "react-i18next";

const NFTImage1 = 'https://i0.wp.com/opvapeshop.com/wp-content/uploads/2019/11/IMG_2511.jpeg?fit=1568%2C1176&ssl=1';
const NFTImage2 = 'https://www1.lovethatdesign.com/wp-content/uploads/2021/09/Love-That-Design-L_eto-Cafe-Dubai-10-1024x767.jpg';
const NFTImage3 = 'https://dynamicassets.azureedge.net/uploads/offer/mainPicture/5c7275d8b1a1f.jpg';
const NFTImage4 = 'https://0.rc.xiniu.com/g1/M00/4A/65/CgAGS2Cs0KCAc-k6AB-zgwpRdxE408.png';
const NFTImage5 = 'https://0.rc.xiniu.com/g1/M00/4A/65/CgAGS2Cs0SyAJHmNAAIcipWQs1Y19.jpeg';
const NFTImage6 = 'https://www.aquariumsource.com/wp-content/uploads/2020/07/betta-fish-laying-bottom-of-tank-380x220.jpg';
const NFTImage7 = 'https://www.visitsealife.com/sydney/media/xopndkc2/blacktip-reef-shark-3.jpg?anchor=center&mode=crop&format=webp&quality=80&width=600&height=460';
const NFTImage8 = 'https://indiansinuae.org/wp-content/uploads/2021/07/large-aquarium-dubai-united-arab-emirates.jpg';
const NFTImage9 = 'https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/39/95/b8.jpg';
const NFTImage10 = 'https://bluewatersdubai.ae/-/media/Project/MeraasEcosystem/Bluewater/Shop/The-Little-Things/1.jpg';
const NFTImage11 = 'https://bluewatersdubai.ae/-/media/Project/MeraasEcosystem/Bluewater/Shop/The-Little-Things/4.jpg';
const NFTImage12 = 'https://www.figstop.com/wp-content/uploads/2022/01/Marvel-Action-Figures-Little-Things-Bluewaters-Dubai.jpg';
const NFTImage13 = 'https://kadabook-development.s3.amazonaws.com/media/Place_Images/blu-kiosk-dubai-mall/Gallery/98aa75d2-ec1c-47cc-be3d-5ca5b8215edc-h.jpeg';
const NFTImage14 = 'https://lh5.googleusercontent.com/p/AF1QipNt_fpS0NwzfyUp8nmPYTo4qioo9yhPMQRHepGo=w500-h500-k-no';

const Transaction = props => {

    //meta title
    document.title = "Transactions";
    const BASE_PATH = process.env.REACT_APP_BASE_PATH
    const [listingData, setData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [view, setview] = useState(true)
    const [loaderStatus, setLoaderStatus] = useState(true)
    const perPage = 10

    const handlePageClick = page => {
        setCurrentPage(page)
        fetchData(page, perPage, searchName)
    }

    const fetchData = (pagenumber, pagelimit, search = "") => {
        try {
            setLoaderStatus(true)
            axios
                .get(apiRoute.transactionsListing + "?page=" + pagenumber + "&limit=" + pagelimit + "&search=" + search)
                .then(res => {
                    console.log(res.data, "transactions listing >>>>>>>>>>>>>>>")
                    setLoaderStatus(false)
                    setData(res.data.data.transaction)

                    let pageCount = Math.ceil(res.data.data?.totalItems / perPage)
                    setTotalCount(pageCount)
                })
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchData(currentPage, perPage, searchName)
    }, [])


    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Copy to clipboard!")
    }

    const [dateFilter, setDateFilter] = useState([]);
    const handleDateEvent = async (event) => {
        console.log(event.length)
        if (event.length == 2) {
            // let filterDate = [];
            // filterDate[0] = moment(event[0]).format('YYYY-MM-DD');
            // filterDate[1] = moment(event[1]).format('YYYY-MM-DD');
            // setDateFilter(filterDate);
            // await fetchData(1, perPage, '', statusFilter, filterDate);
        }
    }
    return (
        <React.Fragment>

            <div className="page-content margin-custom">
                <Container fluid>

                    <Row>
                        <Col xl="12">
                            <Row className="d-none">
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium"> Total NFT's </p>
                                                    <h4 className="mb-0"> 0 </h4>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-warning align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-warning">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium"> Minted NFT's </p>
                                                    <h4 className="mb-0"> 0 </h4>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-success align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-success">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium"> Buying NFT's </p>
                                                    <h4 className="mb-0"> 0 </h4>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-info align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-info">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col sm="3">
                                    <Card className="mini-stats-wid border mb-3">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium"> Sell NFT's </p>
                                                    <h4 className="mb-0"> 0 </h4>
                                                </div>
                                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-primary">
                                                        <i className="mdi mdi-chart-box-outline h2 text-white mb-0" />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card className="border">
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-0 card-title flex-grow-1 text-color-gredient"> Transactions </h4>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                            <div className="position-relative" style={{ marginTop: '10px' }}>
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search...`}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>

                                        <Flatpickr className="form-control d-block" style={{ width: '200px', marginRight: '8px' }} onChange={(date) => handleDateEvent(date)} placeholder="Select Dates" options={{ mode: "range", dateFormat: "Y-m-d" }} />
                                        <div className="flex-shrink-0">
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#Token</th>
                                                    <th> Collection </th>
                                                    <th> Type </th>
                                                    <th> User </th>
                                                    <th> NFT's </th>
                                                    <th> Amount </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listingData.length == 0 ? (
                                                        <tr>
                                                            <td>No Record Found</td>
                                                        </tr>
                                                    ) : (
                                                        listingData.map((element, index) => {
                                                            return (

                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="">
                                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                                {element.txhash && element.txhash.slice(0, 18)}.....{element.txhash && element.txhash.slice(
                                                                                    element.txhash.length - 4,
                                                                                    element.txhash.length
                                                                                )} &nbsp; <i className="mdi mdi-content-copy" onClick={() => handleCopy(element.txhash)} style={{ cursor: 'pointer' }}></i>
                                                                                &nbsp; <a href={`https://goerli.etherscan.io/tx/${element.txhash}`} target="_blank" rel="noreferrer"> <i className="mdi mdi-open-in-new ms-1 text-color-gredient"></i> </a>
                                                                            </h5>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <img
                                                                                src={BASE_PATH + element?.collection?.logoImg}
                                                                                className="avatar-sm me-3 rounded-circle"
                                                                                alt="img"
                                                                            />
                                                                            <div className="">
                                                                                <h5 className="font-size-14 mb-1 text-color-gredient"> {element?.collection?.name} </h5>
                                                                                <p className="text-muted mb-0">Created At : {dateformat(element?.collection?.createdAt)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="">
                                                                            <h5 className="font-size-14 mb-0 text-color-gredient">Mint</h5>
                                                                            <p> {dateformat(element?.createdAt)} </p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="">
                                                                            <h5 className="font-size-14 mb-0 text-color-gredient">{element?.userdetails?.name}</h5>
                                                                            <p> {element?.userdetails?.email} </p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="">
                                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                                {element.nfts && Number(element?.nfts)}
                                                                            </h5>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="">
                                                                            <h5 className="font-size-14 text-muted mb-0">
                                                                                {element?.value} ETH
                                                                            </h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                }


                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>



                                <Row className="mt-2 me-2">
                                    <Col md={9}></Col>
                                    <Col md={3}>
                                        <Pagination
                                            {...bootstrap5PaginationPreset}
                                            current={currentPage}
                                            total={totalCount}
                                            onPageChange={page => handlePageClick(page)}
                                            className="pagination justify-content-end"
                                        />
                                    </Col>
                                </Row>


                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};


Transaction.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Transaction);
