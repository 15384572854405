import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, CardTitle, Button, FormFeedback } from "reactstrap";
import '../../assets/scss/style.css'
import Select from "react-select";

import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"
import { dateformat, getUserDetail } from "admin/commonFunction"
import { LoaderContext } from "context/ContextProvider"
import { useHistory } from 'react-router'

import { withTranslation } from "react-i18next";
import moment from "moment";

const NFTImage7 = 'https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg';

const ShowNFT = props => {

    const { id } = useParams()

    //meta title
    document.title = "Event Update";
    const history = useHistory();
    const BASE_PATH = process.env.REACT_APP_BASE_PATH
    const [userInfo, setUserInfo] = useState("")
    const { loader, loading, setloading } = useContext(LoaderContext)
    const [categories, setCategories] = useState([])
    const [formValues, setFormValues] = useState({
        name: "",
        description: "",
        property_id: "",
        nft_id: "",
        collection_id: "",
        start_datetime: "",
        closing_datetime: "",
        preLogo: "",
        preBanner: "",
    })
    const [itemLogo, setItemLogo] = useState("")
    const [itemBanner, setItemBanner] = useState("")

    const changeValues = event => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value })
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: formValues.name,
            description: formValues.description,
            property_id: formValues.property_id,
            // nft_id: formValues.nft_id,
            collection_id: formValues.collection_id,
            start_datetime: formValues.start_datetime,
            closing_datetime: formValues.closing_datetime,
            itemLogo: itemLogo,
            itemBanner: itemBanner,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("This field is required."),
            description: Yup.string().required("This field is required."),
            // property_id: Yup.string().required("This field is required."),
            collection_id: Yup.string().required("This field is required."),
            // nft_id: Yup.string().required("This field is required."),
            start_datetime: Yup.date().required("This field is required."),
            closing_datetime: Yup.date().required("This field is required.").min(
                Yup.ref('start_datetime'),
                "end date can't be before start date"
            ),
        }),
        onSubmit: values => {
            updateEvent(values)
        },
    })

    const updateEvent = async data => {

        var formData = new FormData()
        formData.append("name", data.name)
        formData.append("description", data.description)
        formData.append("property", 0)
        // formData.append("nft", data.nft_id)
        formData.append("collectionId", data.collection_id)
        formData.append("startDateTime", data.start_datetime)
        formData.append("endDateTime", data.closing_datetime)
        formData.append("logo", data.itemLogo)
        formData.append("banner", data.itemBanner)
        formData.append("eventID", id)
        setloading(true)

        await axios
            .put(apiRoute.eventUpdate, formData)
            .then(response => {
                console.log(response, 'response >>>>>>>>>>>>>>>>>>')
                toast.success(response.data.message)
                setloading(false)
                history.push('/admin/event/shows');
            })
            .catch(error => {
                console.log(error, 'error >>>>>>>>>>>>>>>>>>')
                setloading(false)
                toast.error(error.response.data.error)
            })
    }

    const [optionGroup, setOptionGroup] = useState([])
    const fetchProperties = () => {
        try {
            axios
                .get(apiRoute.getProperty)
                .then(res => {
                    //   setData(res.data.data.property)

                    var propertyOptionGroup = []
                    res.data.data.property.map((value, index) => {
                        var optionData = {}
                        optionData.label = value.name
                        optionData.value = value._id
                        propertyOptionGroup.push(optionData)
                    })
                    setOptionGroup(propertyOptionGroup)
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Something went wrong!")
                })
        } catch (err) {
            console.log(err)
        }
    }
    const fetchEvent = () => {
        try {
            axios
                .get(apiRoute.eventDetail + "?eventID=" + id)
                .then(res => {


                    console.log(res, 'event response >>>>>>>>>>>>>>>')
                    let eventResp = res.data.data.event;
                    setFormValues({
                        name: eventResp.name,
                        description: eventResp.description,
                        property_id: eventResp.property,
                        nft_id: eventResp.nft,
                        collection_id: eventResp.collectionId,
                        start_datetime: moment(eventResp.startDateTime).format('YYYY-MM-DDTh:mm:ss'),//moment(eventResp.startDateTime),
                        closing_datetime: moment(eventResp.endDateTime).format('YYYY-MM-DDTh:mm:ss'), //eventResp.endDateTime,
                        preLogo: eventResp.logo,
                        preBanner: eventResp.banner,
                    })

                    // let eventProperty = {};
                    // eventProperty.label = res.data.data.property.name;
                    // eventProperty.value = res.data.data.property._id;
                    // setselectedMulti(eventProperty)

                    // let eventNFT = {};
                    // eventNFT.label = res.data.data.nftDetails.name;
                    // eventNFT.value = res.data.data.nftDetails._id;
                    // setselectedNFTMulti(eventNFT)

                    let eventCollection = {};
                    eventCollection.label = res.data.data.collectionDetails.name;
                    eventCollection.value = res.data.data.collectionDetails._id;
                    setselectedCollectionMulti(eventCollection)

                })
                .catch(error => {
                    console.log(error)
                    toast.error("Something went wrong!")
                })
        } catch (err) {
            console.log(err)
        }
    }

    const [optionNFTGroup, setOptionNFTGroup] = useState([])
    const fetchNFTs = () => {
        try {
            axios
                .get(apiRoute.nftListing)
                .then(res => {

                    console.log(res.data.data, 'nftListing >>>>>>>>>>>>>')

                    //   setData(res.data.data.property)

                    var propertyOptionGroup = []
                    res.data.data.nft.map((value, index) => {
                        var optionData = {}
                        optionData.label = value.name
                        optionData.value = value._id
                        propertyOptionGroup.push(optionData)
                    })
                    setOptionNFTGroup(propertyOptionGroup)
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Something went wrong!")
                })
        } catch (err) {
            console.log(err)
        }
    }

    const [optionCollectionGroup, setOptionCollectionGroup] = useState([])
    const fetchCollections = () => {
        try {
            axios
                .get(apiRoute.eventCollectionListing)
                .then(res => {

                    console.log(res.data.data, 'eventCollectionListing >>>>>>>>>>>>>')

                    //   setData(res.data.data.property)
                    var propertyOptionGroup = []
                    res.data.data.collection.map((value, index) => {
                        var optionData = {}
                        optionData.label = value.name
                        optionData.value = value._id
                        propertyOptionGroup.push(optionData)
                    })
                    setOptionCollectionGroup(propertyOptionGroup)
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Something went wrong!")
                })
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchEvent()
        fetchProperties()
        fetchNFTs()
        fetchCollections()
    }, [])

    const [selectedMulti, setselectedMulti] = useState(null)
    const [selectedNFTMulti, setselectedNFTMulti] = useState(null)
    function handleMulti(selectedMulti) {
        setFormValues({ ...formValues, ['property_id']: selectedMulti.value })
        setselectedMulti(selectedMulti)
    }
    function handleNFTMulti(selectedNFTMulti) {
        setFormValues({ ...formValues, ['nft_id']: selectedNFTMulti.value })
        setselectedNFTMulti(selectedNFTMulti)
    }
    const [selectedCollectionMulti, setselectedCollectionMulti] = useState(null)
    function handleCollectionMulti(selectedCollectionMulti) {
        setFormValues({ ...formValues, ['collection_id']: selectedCollectionMulti.value })
        setselectedCollectionMulti(selectedCollectionMulti)
    }


    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                        className="validation-cls"
                                    >
                                        <CardTitle className="h5 mb-4 text-color-gredient">Edit Event</CardTitle>

                                        <Row className="">
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label htmlFor="floatingnameInput">Name</label>
                                                            <input type="text" className="form-control" id="floatingnameInput" name="name" placeholder="Writer here..." onBlur={validation.handleBlur} onChange={changeValues} value={formValues.name} />
                                                            {validation.touched.name && validation.errors.name ? (
                                                                <FormFeedback type="invalid"> {validation.errors.name}  </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} className="d-none">
                                                        <label htmlFor="floatingnameInput">Select Property</label>
                                                        <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                            <Select value={selectedMulti} isMulti={false} options={optionGroup} className="select2-selection"
                                                                onChange={e => {
                                                                    handleMulti(e)
                                                                }}
                                                            />
                                                            {validation.touched.property_id && validation.errors.property_id ? (
                                                                <FormFeedback type="invalid"> {validation.errors.property_id}  </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <label htmlFor="floatingnameInput">Select Collection</label>
                                                        <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                            <Select value={selectedCollectionMulti} isMulti={false} options={optionCollectionGroup} className="select2-selection"
                                                                onChange={e => {
                                                                    handleCollectionMulti(e)
                                                                }}
                                                            />
                                                            {validation.touched.collection_id && validation.errors.collection_id ? (
                                                                <FormFeedback type="invalid"> {validation.errors.collection_id}  </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="floatingnameInput1">Description</label>
                                                    <textarea className="form-control" id="floatingnameInput1" rows={3} placeholder="Writer here..." name="description" style={{ height: '120px' }} onBlur={validation.handleBlur} onChange={changeValues} defaultValue={formValues.description}></textarea>
                                                    {validation.touched.description && validation.errors.description ? (
                                                        <FormFeedback type="invalid"> {validation.errors.description}  </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={6} className="d-none">
                                                <label htmlFor="floatingnameInput">Select NFT</label>
                                                <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                    <Select value={selectedNFTMulti} isMulti={false} options={optionNFTGroup} className="select2-selection"
                                                        onChange={e => {
                                                            handleNFTMulti(e)
                                                        }}
                                                    />
                                                    {validation.touched.property_id && validation.errors.property_id ? (
                                                        <FormFeedback type="invalid"> {validation.errors.property_id}  </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="floatingnameInput">Start - Date and time</label>
                                                    <input className="form-control" type="datetime-local" id="example-datetime-local-input" name="start_datetime" onBlur={validation.handleBlur} onChange={changeValues} defaultValue={formValues.start_datetime} />
                                                    {validation.touched.start_datetime && validation.errors.start_datetime ? (
                                                        <FormFeedback type="invalid"> {validation.errors.start_datetime}  </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="floatingnameInput">Closing - Date and time</label>
                                                    <input className="form-control" type="datetime-local" id="example-datetime-local-input" name="closing_datetime" onBlur={validation.handleBlur} onChange={changeValues} defaultValue={formValues.closing_datetime} />
                                                    {validation.touched.closing_datetime && validation.errors.closing_datetime ? (
                                                        <FormFeedback type="invalid"> {validation.errors.closing_datetime}  </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={5} className="mb-4">
                                                <label htmlFor="">Event Logo</label>
                                                <input type="file" className="form-control" id="" placeholder="Writer here..." name="logo"
                                                    onChange={event => {
                                                        setItemLogo(event.currentTarget.files[0])
                                                    }}
                                                />
                                                {validation.touched.itemLogo && validation.errors.itemLogo ? (
                                                    <FormFeedback type="invalid"> {validation.errors.itemLogo} </FormFeedback>
                                                ) : null}
                                            </Col>
                                            <Col md={1} className="mb-4 overflow-hidden">
                                                <img
                                                    src={BASE_PATH + formValues.preLogo}
                                                    style={{ height: "80px" }}
                                                />
                                            </Col>
                                            <Col md={5} className="mb-4">
                                                <label htmlFor="">Event Banner</label>
                                                <input type="file" className="form-control" id="" placeholder="Writer here..." name="banner"
                                                    onChange={event => {
                                                        setItemBanner(event.currentTarget.files[0])
                                                    }}
                                                />
                                                {validation.touched.itemBanner && validation.errors.itemBanner ? (
                                                    <FormFeedback type="invalid"> {validation.errors.itemBanner} </FormFeedback>
                                                ) : null}
                                            </Col>
                                            <Col md={1} className="mb-4 overflow-hidden">
                                                <img
                                                    src={BASE_PATH + formValues.preBanner}
                                                    style={{ height: "80px" }}
                                                />
                                            </Col>
                                        </Row>

                                        <div>
                                            <Link to="/admin/event/shows" className="btn btn-secondary w-md mt-2 me-2">
                                                Cancel
                                            </Link>
                                            <button type="submit" className="btn btn-primary w-md mt-2">Submit</button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div >
        </React.Fragment >
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
