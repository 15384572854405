import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import { apiRoute } from "routes/apiRoutes";
import { LoaderContext } from "context/ContextProvider.js";

function AddFaq() {
  const { Add,showEdit,handleClose,setloading } = useContext(LoaderContext);

  const handleFaq = e => {
    e.preventDefault();
    let bodyData = {
        question:e.target.question.value,
        answer:e.target.answer.value
    }
    setloading(true)
    try {
      axios
        .post(apiRoute.addFaq, bodyData)
        .then(res => {
          console.log(res);
          setloading(false);
          toast.success("FAQ Added Successfully");
          handleClose();
        })
    } catch (err) {
      console.log(err)
      setloading(false);
      toast.error("An error occurred !");
     
    }
    // console.log(formData.get('categoryIcon'), "data")
    // tog_varyingModal()
  }

 

  return (
    <>
      <Modal
        show={Add}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Faq</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFaq}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Question
              </Form.Label>
              <Form.Control type="text" name="question"  placeholder="" autoFocus />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control as="textarea" name="answer" aria-label="With textarea" autoFocus/>
            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary" style={{marginLeft:10}}>
            ADD
          </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddFaq;
