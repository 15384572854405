import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, Table } from "reactstrap";
import axios from "axios";
import TransactionsGraph from "./transactions";
import NFTCollections from "./NFTCollections";
import NFTTrending from "./NFTTrending";
import { apiRoute } from "routes/apiRoutes"


//i18n
import { withTranslation } from "react-i18next";
import '../../../assets/scss/style.css'

import img1 from "../../../assets/images/small/img-1.jpg";
import img2 from "../../../assets/images/small/img-2.jpg";
import img3 from "../../../assets/images/small/img-3.jpg";
import img7 from "../../../assets/images/small/img-7.jpg";

import user1 from "../../../assets/images/users/avatar-2.jpg"
import user2 from "../../../assets/images/users/avatar-1.jpg"
import user3 from "../../../assets/images/users/avatar-3.jpg"
import user4 from "../../../assets/images/users/avatar-4.jpg"
import user5 from "../../../assets/images/users/avatar-5.jpg"

const Dashboard = props => {

    //meta title
    document.title = "NFT Dashboard";
    const [listingData, setData] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const perPage = 4;

    

    const fetchData = (pagenumber, pagelimit, search = '') => {
        try {
            axios.get(apiRoute.collectionListing + '?page=' + pagenumber + '&limit=' + pagelimit + '&search=' + search).then(res => {
                console.log(res)
                setData(res.data.data.collection.items)

                let pageCount = Math.ceil(res.data.data.collection.totalItems / perPage);
                setTotalCount(pageCount);
            })
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [])


    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

                    <Row>
                        <Col xl="9">

                            {/* Categories */}
                            <NFTCollections />

                            {/* Trending */}
                            <NFTTrending />


                            <Row className="mt-1 me-1">
                                <Col xl="6" xs="6">
                                    <h4 className="card-title">Recent NFT's Buying</h4>
                                </Col>
                                <Col xl="6" xs="6" className="text-end">
                                    <Link to="/admin/dashboard" className="btn btn-light me-1 btn-sm"> View All </Link>
                                </Col>

                                <div className="table-responsive mt-3 border rounded">
                                    <Table className="table align-middle table-nowrap">
                                        <tbody>
                                            <tr>
                                                <th> NFT </th>
                                                <th> Type </th>
                                                <th> User </th>
                                                <th> Owner </th>
                                                <th> Price </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img3}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-1">The Caesars Palace </h5>
                                                            <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Mint</h5>
                                                        <p> 15 Nov, 2022 </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Maurice Read</h5>
                                                        <p> maurice.read@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Billy Bates</h5>
                                                        <p> billy.bates@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 text-muted mb-0">
                                                            $25.20
                                                        </h5>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img2}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-1">The Minting Space </h5>
                                                            <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Mint</h5>
                                                        <p> 15 Nov, 2022 </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Johnny Briggs</h5>
                                                        <p> johnny.briggs@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Javed</h5>
                                                        <p> javed.332@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 text-muted mb-0">
                                                            $25.20
                                                        </h5>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img1}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-1">Beast Ape </h5>
                                                            <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Sell</h5>
                                                        <p> 15 Nov, 2022 </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Arnold Fothergill</h5>
                                                        <p> arnold.fothergill@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Frank Hearne</h5>
                                                        <p> frank.hearne@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 text-muted mb-0">
                                                            $25.20
                                                        </h5>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={img7}
                                                            className="avatar-sm me-3 rounded-circle"
                                                            alt="img"
                                                        />
                                                        <div className="">
                                                            <h5 className="font-size-14 mb-1">The Bull </h5>
                                                            <p className="text-muted mb-0">Created At : 14 Mar, 2020</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Buy</h5>
                                                        <p> 15 Nov, 2022 </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Billy Newham</h5>
                                                        <p> billy.newham@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">William Gunn</h5>
                                                        <p> william.gunn@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 text-muted mb-0">
                                                            $25.20
                                                        </h5>
                                                    </div>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                        </Col>

                        <Col xl={3}>

                            <Row className="mt-3">
                                <Col xl="12" xs="12">
                                    <h4 className="card-title">Recent Activities</h4>
                                </Col>

                                <div className="mt-2 border rounded py-3">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item ">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <img
                                                        src={user1}
                                                        alt=""
                                                        className="img-fluid d-block rounded-circle"
                                                    />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-14 mb-1">
                                                        Clarence Smith{" "}
                                                        <small className="text-muted float-end">
                                                            2 hrs Ago
                                                        </small>
                                                    </h5>
                                                    <p className="text-muted">
                                                        Sub-Admin Created New NFT.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <img
                                                        src={user4}
                                                        alt=""
                                                        className="img-fluid d-block rounded-circle"
                                                    />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-14 mb-1">
                                                        James Smith{" "}
                                                        <small className="text-muted float-end">
                                                            2 hrs Ago
                                                        </small>
                                                    </h5>
                                                    <p className="text-muted">
                                                        Sub-Admin Created New NFT.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <img
                                                        src={user2}
                                                        alt=""
                                                        className="img-fluid d-block rounded-circle"
                                                    />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-14 mb-1">
                                                        Patriksha{" "}
                                                        <small className="text-muted float-end">
                                                            2 hrs Ago
                                                        </small>
                                                    </h5>
                                                    <p className="text-muted">
                                                        Buying a NFT (Cool Cat #591)
                                                    </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <img
                                                        src={user3}
                                                        alt=""
                                                        className="img-fluid d-block rounded-circle"
                                                    />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-14 mb-1">
                                                        Javed{" "}
                                                        <small className="text-muted float-end">
                                                            2 hrs Ago
                                                        </small>
                                                    </h5>
                                                    <p className="text-muted">
                                                        Buying a NFT (Cool Cat #591)
                                                    </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <img
                                                        src={user5}
                                                        alt=""
                                                        className="img-fluid d-block rounded-circle"
                                                    />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-14 mb-1">
                                                        Jared {" "}
                                                        <small className="text-muted float-end">
                                                            2 hrs Ago
                                                        </small>
                                                    </h5>
                                                    <p className="text-muted">
                                                        Sub-Admin Created New NFT.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>


                                    </ul>
                                </div>
                            </Row>

                            <Row className=" mt-4">
                                <Col xl="12" xs="12">
                                    <h4 className="card-title">Transaction Graph</h4>
                                </Col>

                                <TransactionsGraph dataColors='["--bs-warning"]' />
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};


Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
