import { toast } from "react-toastify"
import myContractABI from "../contracts/myContractABI.json"
import myContractByteCode from "../contracts/myContractByteCode.json"
import { ContractFactory, ethers } from "ethers"
let ethereum = window.ethereum

export const deploy = async (name, symbol, base_url, maxsupply, price, use_time,valid_period,membership,setloading) => {
  try {
    if (!ethereum) {
      throw toast.error("No crypto wallet found. Please install it.")
    }
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner()
    const factory = new ContractFactory(
      myContractABI,
      myContractByteCode.object,
      signer
    )
    let _price = ethers.utils.parseUnits(price,"ether")
    const contract = await factory.deploy(name, symbol, base_url, maxsupply,_price,use_time,valid_period,membership)
    await contract.deployed()
    return contract
  } catch (error) {
    setloading(false)
    console.log("errorrr deply msg fn", error)
  }
}
