import React, { useState, useContext } from "react"
import { LoaderContext } from "context/ContextProvider"
import { Rings } from "react-loader-spinner"
import { useSelector } from "react-redux"
import { Modal } from "react-bootstrap"
import "./Loader.css"
const Loader = () => {
  const { loading } = useContext(LoaderContext)

  return (
    <>
      <Modal show={loading} className="loaderModal" centered size="sm">
        <Rings
          height="150"
          width="150"
          color="#3480eb"
          radius="6"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />
      </Modal>
    </>
  )
}

export default Loader
