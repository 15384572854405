import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
// import user1 from "../../../assets/images/Fav_icon.png";
const user1 = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd8mph-cdRhA4IWM5YJo8a03yvMRCRmhx1Jg&usqp=CAU";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const [role, setRole] = useState("admin");
  const [webStatus, setWebStatus] = useState("no");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        var obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        var obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
        setRole(obj.role);
      }

      let webStatus = localStorage.getItem('webStatus');
      setWebStatus(webStatus)
      // if (webStatus && webStatus == "yes" && obj.role == "admin") {
      //   window.location.replace('https://universe-eye-admin.block-brew.com/login')
      // }

    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
          style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ background: '#74788d', padding: '3px 10px 4px 10px', color: 'white', fontSize: '16px', borderRadius: '50%', border: '2px solid #cfcfcf', width: '37px', height: '35px' }}> <span>{props.nameLetter}</span> </span>
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        {
          (role == "admin" || webStatus == "no") && <>
            <DropdownMenu className="dropdown-menu-end">
              <Link to="/admin/change-password" className="dropdown-item">
                {" "}
                <i className="bx bxs-key font-size-16 align-middle me-1" />
                {props.t("Change Password")}{" "}
              </Link>
              <div className="dropdown-divider" />
              <Link to="/logout" className="dropdown-item">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>{props.t("Logout")}</span>
              </Link>
            </DropdownMenu>
          </>
        }

      </Dropdown>
    </React.Fragment >
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
