import React, { useState ,useEffect,useContext} from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import coinbase from "../../assets/coinbase.png";
import metamask from "../../assets/metamask.webp";
import "./ConnectionModal.css";
import { } from "../../config";
import { LoaderContext } from "../../context/ContextProvider";

function ConnectionModal() {

  const { show, setShow,connectMetawallet } = useContext(LoaderContext);
  const handleClose = () => {
    setShow(false);
  };


  return (
    <div className="modal">
      <Modal
        className="connModal"
        backdrop="static"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Use Account Form</Modal.Title>
          <hr />
        </Modal.Header>
        <Modal.Body>
          <div className="allWallets">
            <Button
              style={{ backgroundColor: "white", borderColor: "white" }}
              onClick={connectMetawallet}
            >
              <div className="wallet">
                <div className="metamaskWallet">
                  <div>
                    <img
                      className="walletImage"
                      src={metamask}
                      style={{ width: 40, height: 40 }}
                      alt="not found"
                    />
                  </div>
                  <div className="walletName">
                    <p style={{ textDecoration: "none", color: "black" }}>
                      Metamask
                    </p>
                  </div>
                </div>
              </div>
            </Button>
            <Button style={{ backgroundColor: "white", borderColor: "white" }}>
              <div className="coinbaseWallet">
                <div className="wallet">
                  <div>
                    <img
                      className="walletImage"
                      src={coinbase}
                      style={{ width: 40, height: 40 }}
                      alt="not found"
                    />
                  </div>
                  <div className="walletName">
                    <p style={{ textDecoration: "none", color: "black" }}>
                      Coinbase
                    </p>
                  </div>
                </div>
              </div>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="connectWalletFooter">
          <a href="/">Don't have an account?</a>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConnectionModal;
