import React, { useContext } from 'react'
import { Modal, Button } from "react-bootstrap";
import { StepContext } from '../../App';
import { BiCopy } from "react-icons/bi"
import metamask from "../../assets/metamask.webp";
import copy from "copy-to-clipboard";
import { disconnectWallet } from "../../config";
import { LoaderContext } from "../../context/ContextProvider";
import "./DisconnectModal.css";

const DisconnectModal = () => {
  const { showDiss, setShowDiss, account, disconnectWalletFromApp } = useContext(LoaderContext);
  const handleClose = () => {
    setShowDiss(false);
  };

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." + str.substr(38, 43) : str;
  }

  return (
    <Modal className="dissModal" show={showDiss} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ACTIVE ACCOUNT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='bodyContent'>
          <div style={{ float: "left", display: 'flex' }}>
            <img src={metamask} style={{ width: 30, height: 30, marginRight: 8 }} />
            <h5 style={{ fontSize: 18, fontWeight: 100, paddingTop: 4 }}>Metamask</h5>
          </div>
          <div style={{ float: 'right', display: 'flex' }}>
            <p className='addtxt'>{substr(account.toString(), 6)}</p>
            <Button className='copybtn' onClick={() => copy(account)}>
               <BiCopy style={{ color: "gray", width: 24, height: 24 }} />
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="discconnectbtn" onClick={disconnectWalletFromApp}>
          Disconnect Wallet
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DisconnectModal;
