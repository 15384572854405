import PropTypes from "prop-types"
import React, { useEffect, useState, useContext } from "react"
import { Link, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Button,
  FormFeedback,
} from "reactstrap"
import "../../assets/scss/style.css"
import Select from "react-select";
import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"

import { withTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { exists } from "i18next"
import GoogleMaps from './map';

const places = props => {
  //meta title
  document.title = "Edit Property"
  const BASE_PATH = process.env.REACT_APP_BASE_PATH;

  const history = useHistory()
  const { loader, loading, setloading } = useContext(LoaderContext)
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    propertyId: "",
    propertyImg: "",
    mapView: "",
    buildingType: "",
    collection_id: "",
    collection_image: "",
    collection_banner_image: "",
    pre_gallery: [],
  })

  const [propertyImg, setPropertyImg] = useState("")
  const [mapview, setMapView] = useState("")
  const [galleryImg, setGalleryImg] = useState([])
  const { propertyID } = useParams()

  const [LatLong, setLatLong] = useState({ latitude: 25.079944630125194, longitude: 55.12245170750407 })

  const changeValues = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: formValues.name,
      description: formValues.description,
      propertyId: formValues.propertyId,
      collection_id: formValues.collection_id,
      propertyType: formValues.buildingType,
      collection_image: formValues.collection_image,
      collection_banner_image: formValues.collection_banner_image,
      propertyImg: propertyImg,
      mapView: mapview,
      galleryImg: galleryImg,
      latitude: LatLong.latitude,
      longitude: LatLong.longitude,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required."),
      description: Yup.string().required("This field is required."),
      propertyId: Yup.number().required("This field is required."),
      // collection_id: Yup.string().required("This field is required."),
      propertyType: Yup.string().required("This field is required."),
      latitude: Yup.string().required("This field is required."),
      longitude: Yup.string().required("This field is required."),
    }),
    onSubmit: values => {
      editPlace(values)
      console.log(values, "hgh")
    },
  })

  const editPlace = async data => {
    var nameElements = document.getElementsByClassName("name-cls")
    var sectionIdElements = document.getElementsByClassName("section-cls")

    var SECrecord = []
    var sectionUniqueID = []
    var uniqueErr = ""
    for (var i = 0; i < nameElements.length; i++) {
      console.log(sectionIdElements[i].value)
      if (nameElements[i].value != "" && sectionIdElements[i].value != "") {
        for (let j = 0; j < sectionUniqueID.length; j++) {
          if (sectionIdElements[i].value == sectionUniqueID[j]) {
            uniqueErr = "The section id must be unique."
            break
          }
        }
        if (uniqueErr != "") {
          toast.error(uniqueErr)
          break
        }
        sectionUniqueID[i] = sectionIdElements[i].value

        var record = {}
        record.name = nameElements[i].value
        record.sectionId = sectionIdElements[i].value
        SECrecord[i] = record
      }
    }
    if (uniqueErr != "") {
      return
    }
    console.log(JSON.stringify(SECrecord), "hgjkljll;")

    // Form Collection
    var formData = new FormData()
    formData.append("propertyID", propertyID)
    formData.append("name", data.name),
    formData.append("description", data.description),
    formData.append("propertyId", data.propertyId)
    formData.append("collectionId", (data.collection_id && data.collection_id != undefined) ? data.collection_id : '')
    formData.append("buildingType", data.propertyType)
    formData.append("propertyImg", data.propertyImg)
    formData.append("mapView", data.mapView)
    formData.append("collection_banner_image", data.collection_banner_image)
    formData.append("collection_image", data.collection_image)
    
    formData.append('latitude', data.latitude)
    formData.append('longitude', data.longitude)

    formData.append("section", JSON.stringify(SECrecord))
    console.log(formData, "data")

    for (let index = 0; index < Object.keys(data.galleryImg).length; index++) {
      formData.append("multiMedia", data.galleryImg[index])
    }

    // Api Call
    setloading(true)
    await axios
      .put(apiRoute.editProperty, formData)
      .then(response => {
        setloading(false)
        toast.success(response.data.message)
        history.push("/admin/properties")
      })
      .catch(error => {
        setloading(false)
        toast.error(error.response.data.error)
      })
  }

  const inpLevel = [{ name: "", sectionId: "" }]
  const [inputLevel, setinputLevel] = useState(inpLevel)

  function handleAddFieldsFAQ() {
    const item1 = { name: "", sectionId: "" }
    setinputLevel([...inputLevel, item1])
  }

  function handleRemoveLevel(idx) {
    document.getElementById("nestedLevel" + idx).remove()
  }

  const fetchData = () => {
    try {
      axios
        .get(apiRoute.propertyDetail + "?propertyID=" + propertyID)
        .then(res => {

          console.log(res.data.data, 'res.data.data >>>>>>>>>>>>>>>>')

          var field_values = res.data.data.place;
          setFormValues({ name: field_values.name, description: field_values.description, propertyId: field_values.propertyId, propertyImg: field_values.propertyImg, mapView: field_values.mapView, buildingType: field_values.buildingType, collection_id: field_values.collectionId, collection_banner_image: field_values.mapView, collection_image: field_values.propertyImg, pre_gallery: field_values.multiMedia })

          if (res.data.data.sections.length > 0)
            setinputLevel(res.data.data.sections)

          let eventCollection = {};
          eventCollection.label = res.data.data.collection?.name;
          eventCollection.value = res.data.data.collection?._id;
          setselectedCollectionMulti(eventCollection)



          if(field_values.latitude && field_values.longitude && field_values.latitude != '' && field_values.longitude != ''){

            setLatLong({ latitude: field_values.latitude, longitude: field_values.longitude })
          }
        })
        .catch(error => {
          console.log(error)
          toast.error("Collection not found !")
        })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData()
    fetchCollections()
  }, [])

  const [listCollection, setListCollection] = useState([])
  const [optionCollectionGroup, setOptionCollectionGroup] = useState([])
  const fetchCollections = () => {
    try {
      axios
        .get(apiRoute.collectionListing)
        .then(res => {

          console.log(res.data.data, 'eventCollectionListing >>>>>>>>>>>>>')

          setListCollection(res.data.data.collection)
          var propertyOptionGroup = []
          res.data.data.collection.map((value, index) => {
            var optionData = {}
            optionData.label = value.name
            optionData.value = value._id
            propertyOptionGroup.push(optionData)
          })
          setOptionCollectionGroup(propertyOptionGroup)
        })
        .catch(error => {
          console.log(error)
          toast.error("Something went wrong!")
        })
    } catch (err) {
      console.log(err)
    }
  }

  const [selectedCollectionMulti, setselectedCollectionMulti] = useState(null)

  function handleCollectionMultiRemove() {
    setselectedCollectionMulti(null)
  }
  function handleCollectionMulti(selectedCollectionMulti) {
    setselectedCollectionMulti(selectedCollectionMulti)

    if (listCollection) {
      listCollection.map((value, index) => {
        if (value._id == selectedCollectionMulti.value) {
          setFormValues({
            name: value.name,
            description: value.description,
            collection_image: value.logoImg,
            collection_banner_image: value.bannerImg,
            propertyId: formValues.propertyId,
            buildingType: formValues.buildingType,
            propertyType: formValues.propertyType,
            pre_gallery: formValues.pre_gallery,
            collection_id: selectedCollectionMulti.value,
          })
        }
      })
    }
  }

  
  const handleChangeLocation = (event) => {
    console.log(event, 'map event >>>>>>>>>>>>>>>>>>>>>')
    setLatLong({ latitude: event.lat, longitude: event.lng })
  };

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="validation-cls"
                  >
                    <CardTitle className="h5 mb-4 text-color-gredient" >Edit Property</CardTitle>

                    <Row className="">
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <label htmlFor="floatingnameInput">Select Collection <span className="font-size-11 text-secondary"> [ Do you want to link this property to a collection? ] </span></label>
                            <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select value={selectedCollectionMulti} isMulti={false} options={optionCollectionGroup} className="select2-selection"
                                onChange={e => {
                                  handleCollectionMulti(e)
                                }}
                                onKeyDown={e => {
                                  handleCollectionMultiRemove()
                                }}
                              />
                              {validation.touched.collection_id && validation.errors.collection_id ? (
                                <FormFeedback type="invalid"> {validation.errors.collection_id}  </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-4">
                              <label htmlFor="floatingnameInput">Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="floatingnameInput"
                                placeholder="Enter Property Name"
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                                value={formValues.name}
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-4">
                              <label htmlFor="floatingnameInput1">
                                Property ID
                              </label>
                              <input
                                type="text"
                                name="propertyId"
                                className="form-control"
                                id="floatingnameInput"
                                placeholder="Enter PropertyId"
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                                value={formValues.propertyId}
                              />
                              {validation.touched.propertyId &&
                                validation.errors.propertyId ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.propertyId}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col md={10} className="mb-4">
                            <label htmlFor="floatingnameInput">
                              Property Icon
                            </label>
                            <input
                              type="file"
                              name="propertyImg"
                              className="form-control"
                              id="floatingnameInput"
                              placeholder="Upload Property Image"
                              onChange={event => {
                                setPropertyImg(event.currentTarget.files[0])
                              }}
                            />
                            {validation.touched.propertyImg &&
                              validation.errors.propertyImg ? (
                              <FormFeedback type="invalid">
                                {validation.errors.propertyImg}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md={2} className="mb-2 overflow-hidden">
                            <img
                              src={BASE_PATH + formValues.collection_image}
                              style={{ height: "80px", maxWidth: "100%" }}
                            />
                          </Col>

                          <Col md={10} className="mb-4">
                            <label htmlFor="floatingnameInput">Map View</label>
                            <input
                              type="file"
                              name="mapView"
                              className="form-control"
                              id="floatingnameInput"
                              placeholder="Upload map Image"
                              onChange={event => {
                                setMapView(event.currentTarget.files[0])
                              }}
                            />
                            {validation.touched.mapView &&
                              validation.errors.mapView ? (
                              <FormFeedback type="invalid">
                                {validation.errors.mapView}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md={2} className="mb-2 overflow-hidden">
                            <img
                              src={BASE_PATH + formValues.collection_banner_image}
                              style={{ height: "80px", maxWidth: "100%" }}
                            />
                          </Col>

                          <Col md={6} className="mb-4">
                            <label htmlFor="floatingnameInput">Gallery Image</label>
                            <input
                              type="file"
                              name="mapView"
                              className="form-control"
                              id="floatingnameInput"
                              placeholder="Upload map Image"
                              multiple="muliple"
                              onChange={event => {
                                setGalleryImg(event.currentTarget.files)
                              }}
                            />
                            {validation.touched.propertyImg &&
                              validation.errors.propertyImg ? (
                              <FormFeedback type="invalid">
                                {validation.errors.propertyImg}
                              </FormFeedback>
                            ) : null}

                            <div className="mt-1 d-flex">
                              {
                                formValues.pre_gallery.map((img1, ind1) => {
                                  return <div key={ind1} className="me-1 border"><img src={BASE_PATH + img1} className="border" style={{ height: "65px", width: "65px" }} /></div>
                                })
                              }
                            </div>

                          </Col>

                          <Col md={6} className="mb-4">
                            <label htmlFor="floatingnameInput">Property Type</label>
                            <select
                              name="propertyType"
                              className="form-select"
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              value={validation.values.propertyType}
                            >
                              <option value="">Select Property Type</option>
                              <option value="Residential">Residential </option>
                              <option value="Business">Business</option>
                              <option value="Commercial">Commercial</option>
                              <option value="Party Hall">Party Hall</option>
                            </select>
                            {validation.touched.propertyType &&
                              validation.errors.propertyType ? (
                              <FormFeedback type="invalid">
                                {validation.errors.propertyType}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>

                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <label htmlFor="floatingnameInput1">Description</label>
                              <textarea name="description" className="form-control" id="floatingnameInput1" rows={3} placeholder="Enter Description..." style={{ height: '167px' }} onBlur={validation.handleBlur} onChange={changeValues} value={formValues.description}></textarea>
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <GoogleMaps latitude={Number(LatLong.latitude)} longitude={Number(LatLong.longitude)} changeFunction={handleChangeLocation} />
                        </Row>
                      </Col>




                      <Col md={12} className="mb-2 mt-3">
                        <label className="font-size-18">Sections in Property - </label>
                        <Button
                          color="success"
                          className="inner btn-sm ms-2 mb-1"
                          onClick={() => {
                            handleAddFieldsFAQ()
                          }}
                        >
                          + Add Section
                        </Button>
                        {inputLevel.map((field, key) => (
                          <Row key={key} id={"nestedLevel" + key}>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Name</label>
                                <input
                                  type="text"
                                  name="name[]"
                                  className="form-control name-cls"
                                  placeholder="Writer here..."
                                  defaultValue={field.name}
                                />
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Section ID</label>
                                <input
                                  type="text"
                                  name="sectionId[]"
                                  className="form-control section-cls"
                                  placeholder="Writer here..."
                                  defaultValue={field.sectionId}
                                />
                              </div>
                            </Col>

                            <Col md={2}>
                              <div className="mt-2 mt-md-0 d-grid">
                                <label className="invisible">delete</label>
                                <Button
                                  color="primary"
                                  className="inner"
                                  onClick={() => {
                                    handleRemoveLevel(key)
                                  }}
                                  block
                                >
                                  Delete
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>

                    <div>
                      <Link
                        to="/admin/properties"
                        className="btn btn-secondary w-md mt-2 me-2"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary w-md mt-2"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

places.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(places)
