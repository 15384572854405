import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card, CardBody, Col, Row, Container, Badge, CardTitle, FormGroup, Input, InputGroup, UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import '../../assets/scss/style.css'

import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import { dateformat, dateTimeformat, getUserDetail } from "admin/commonFunction"
import { LoaderContext } from "context/ContextProvider"
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination"

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";
import noDataImage from "../../assets/images/nodata-found.png"

import event1 from "../../assets/images/events/event-1.jpg";
import event2 from "../../assets/images/events/event-2.jpg";
import event3 from "../../assets/images/events/event-3.jpg";
import event4 from "../../assets/images/events/event-4.jpg";
import event5 from "../../assets/images/events/event-5.jpg";
import event6 from "../../assets/images/events/event-6.jpg";

const EventsShow = props => {

  //meta title
  document.title = "Events";
  const BASE_PATH = process.env.REACT_APP_BASE_PATH
  const { loader, loading, setloading } = useContext(LoaderContext)
  const [listingData, setEventData] = useState([])
  const [searchName, setSearchName] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [view, setview] = useState(true)
  const [loaderStatus, setLoaderStatus] = useState(true)
  const [approval, setApproval] = useState("")
  const [userInfo, setUserInfo] = useState("")
  const [sort,setSort] = useState("")
  const perPage = 10

  const handlePageClick = page => {
    setCurrentPage(page)
    fetchData(page, perPage, searchName,approval,sort)
  }

  const getEvents = (pagenumber, pagelimit, search = "", approval,events) => {
    try {

      setLoaderStatus(true)
      axios.get(apiRoute.eventListing + "?page=" + pagenumber + "&limit=" + pagelimit + "&search=" + search + "&isApproved=" + approval + "&events=" + events)
        .then(res => {
          setLoaderStatus(false)
          console.log(res.data, ">>>>>>>>>>>>")

          setEventData(res.data.data.event)
          let pageCount = Math.ceil(res.data.data.totalItems / perPage)
          setTotalCount(pageCount)
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getEvents(currentPage, perPage, searchName, approval,sort)
    setUserInfo(getUserDetail())
  }, [])


  // Delete Event
  const deleteEvent = eventid => {
    try {
      axios
        .delete(apiRoute.eventDelete + `?eventID=${eventid}`)
        .then(res => {
          toast.success("Event Deleted Successfully")
          getEvents(currentPage, perPage, searchName, approval,sort)
        })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }
  const ConfirmDelete = eventid => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteEvent(eventid)
      }
    })
  }

  const handleSortClick = event => {   
    setSort(event.target.value)
    setCurrentPage(1)
    getEvents(1, perPage, searchName,approval, event.target.value)
  }

  const handleApproveClick = event => {  
    setApproval(event.target.value) 
    setCurrentPage(1)
    getEvents(1, perPage, searchName, event.target.value,sort)
  }


  const handleSearchClick = event => {
    setSearchName(event.target.value)
    setCurrentPage(1)
    getEvents(1, perPage, event.target.value, approval,sort)
  }

  
  const handleChangeCheck = async (event, type) => {
    try {
      console.log(event.target.value)
      console.log(event.target.checked)

      let id = event.target.value
      let status = true
      let isApproved = 1
      if (!event.target.checked) {
        status = false
        isApproved = 0
      }

      if (type == "isApproved") {
        let sendData = { isApproved:isApproved , eventID: id }
        
        axios.post(apiRoute.eventApprovalStatus, sendData).then(res => {
          toast.success("Status Updated Successfully")
          fetchData(currentPage, perPage, searchName,"",approval)
        })
      }
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient"> Events </h5>
                    <div>
                    <label htmlFor="search-bar-0" className="search-label" style={{marginRight:5,marginTop:10}}>
                        <span id="search-bar-0-label" className="sr-only">
                            Pending or Approved
                          </span>
                        <select className="form-control" placeholder={`choose Status`} onChange={handleApproveClick} >
                        <option value="">choose Status</option>  
                          <option value="1">Approved</option>
                          <option value="0">Pending</option>
                        </select>
                        </label>
                    </div>
                    <div>
                    <label htmlFor="search-bar-0" className="search-label" style={{marginRight:5,marginTop:10}}>
                        <span id="search-bar-0-label" className="sr-only">
                            sort by
                          </span>
                        <select className="form-control" placeholder={`Sort-By`} onChange={handleSortClick} >
                        <option value="">Sort-By</option>  
                          <option value="past">Past Events</option>
                          <option value="ongoing">Ongoing Events</option>
                          <option value="upcoming">Upcoming Events</option>
                        </select>
                        </label>
                    </div>

                    <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                      <div className="position-relative" style={{ marginTop: '10px' }}>
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search...`}
                            onChange={handleSearchClick}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <Link to="/admin/event/create" className="btn btn-secondary me-1" >
                        <i className="bx bx-plus"></i> Create Event
                      </Link>
                    </div>
                    <div>
                      <span
                        className="btn btn-light me-1"
                        onClick={() => setview(true)}
                      >
                        <i
                          className="bx bx-grid-alt"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                      <span
                        className="btn btn-light me-1"
                        onClick={() => setview(false)}
                      >
                        <i
                          className="bx bx-list-ul"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row>
                    {totalCount == 0 ? (
                      <Col sm="12" className="text-center">
                        <img src={noDataImage} style={{ width: "505px" }} />
                      </Col>
                    ) : listingData && view ? (
                      listingData.map((element, index) => {
                        return (
                          <Col xl={2} key={index}>
                            <Card className="border">
                              <img
                                className="card-img-top img-fluid rounded"
                                src={BASE_PATH + element.logo}
                                style={{ height: '170px', objectFit: 'cover' }}
                              />

                              {/* Event Dates */}
                              <div
                                style={{
                                  position: "absolute",
                                  left: "10px",
                                  top: "10px",
                                }}
                              >
                                <Badge className="bg-success border" > {dateformat(element.startDateTime)} </Badge>
                              </div>

                              {/* Approved Status */}
                              <div
                                style={{
                                  position: "absolute",
                                  left: "10px",
                                  top: "33px",
                                }}
                              >
                                <Badge
                                  className={
                                    element.isApproved == false ||
                                      element.isApproved == 2
                                      ? "bg-danger border"
                                      : "bg-success border"
                                  }
                                >
                                  {element.isApproved == false
                                    ? "Pending"
                                    : element.isApproved == 2
                                      ? "Reject"
                                      : "Approved"}
                                </Badge>
                              </div>

                              <UncontrolledDropdown
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "10px",
                                }}
                              >
                                <DropdownToggle
                                  href="#"
                                  tag="a"
                                  className="dropdown-toggle"
                                >
                                  <i
                                    className="bx bx-dots-vertical-rounded"
                                    style={{
                                      fontSize: "21px",
                                      color: "#2a3042",
                                      textShadow: "2px 1px 1px white",
                                    }}
                                  />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <Link
                                    className="dropdown-item"
                                    to={`/admin/event/edit/${element._id}`}
                                  >
                                    {" "}
                                    Edit{" "}
                                  </Link>
                                  <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      ConfirmDelete(element._id)
                                    }}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>

                              <div className="p-3 ">
                                <Link to={`/admin/event/detail/${element._id}`}>
                                  <b> {element.name} </b> <br />
                                 <span className="text-color-gredient"> {element.collection.name}</span>
                                </Link>
                              </div>
                            </Card>
                          </Col>
                        )
                      })
                    ) : (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead className="table-light">
                            <tr>
                              <th> Event </th>
                              <th> Collection </th>
                              <th> DateTime </th>
                              <th> Approval </th>
                              <th> Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loaderStatus ? (
                              <tr>
                                <td>No Record Found</td>
                              </tr>
                            ) : (
                              listingData.map((element, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link to={`/admin/event/detail/${element._id}`}>
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={BASE_PATH + element.logo}
                                            className="avatar-sm me-3 rounded-circle"
                                            alt="img"
                                          />
                                          <div className="">
                                            <h5 className="font-size-14 mb-1 text-color-gredient">
                                              {" "}
                                              {element.name}{" "}
                                            </h5>
                                            <p className="text-muted mb-0">
                                              Created At :{" "}
                                              {dateformat(element.createdAt)}
                                            </p>
                                            <p className="text-muted mb-0">
                                              Created By :{" "}
                                              {element.userdetails.name}
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={BASE_PATH + element.collection.logoImg}
                                          className="avatar-sm me-3 rounded-circle"
                                          alt="img"
                                        />
                                        <div className="">
                                          <h4 className="font-size-14 mb-1">
                                            {" "}
                                            {element.collection.name}{" "}
                                          </h4>
                                          <p className="text-muted mb-0">
                                            Created At :{" "}
                                            {dateformat(element.collection.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        <h5 className="font-size-14 text-muted mb-2">
                                          Start : {dateTimeformat(element.startDateTime)}
                                        </h5>
                                        <h5 className="font-size-14 text-muted mb-0">
                                          Closing : {dateTimeformat(element.endDateTime)}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      {userInfo.role == "admin" ? (
                                        <div className="square-switch mytoggle">
                                          <input
                                            type="checkbox"
                                            id={`square-switch1${index}`}
                                            switch="success"
                                            defaultChecked={element.isApproved}
                                            value={element._id}
                                            onClick={event =>
                                              handleChangeCheck(
                                                event,
                                                "isApproved"
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`square-switch1${index}`}
                                            data-on-label="Accept"
                                            data-off-label="Pending"
                                            className="mb-0"
                                          />
                                        </div>
                                      ) : element.isApproved == 0 ? (
                                        <Badge className="bg-danger" style={{ padding: '7px 7px' }}>
                                          {" "}
                                          Pending{" "}
                                        </Badge>
                                      ) : (
                                        <Badge className="bg-success" style={{ padding: '7px 7px' }}>
                                          {" "}
                                          Accepted{" "}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      <ul className="list-unstyled hstack gap-1 mb-0">
                                        <li>
                                          <Link
                                            to={`/admin/event/edit/${element._id}`}
                                            className="btn btn-sm btn-soft-primary"
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-danger"
                                            onClick={() => {
                                              ConfirmDelete(element._id)
                                            }}
                                          >
                                            <i
                                              className="mdi mdi-delete-outline"
                                              id="deletetooltip"
                                            />
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                )
                              })
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )
                    }
                  </Row>


                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={page => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};


EventsShow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(EventsShow);
