import React, { useState } from "react"
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../assets/images/project/favicon.png"
import backgroundImage from '../../assets/images/background-user.png';

function CardUser(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  //Setting Menu

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="mb-2">
            <CardBody className="border">
              <Row className="background-user-image">
                <Col lg="3" className="p-0 pt-3 ps-3 m-0">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={avatar1}
                        alt=""
                        className="avatar-md rounded img-thumbnail p-3"
                        // style={{ backgroundColor: 'white',height: '4.5rem', width: '6.0rem' }}
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted text-color-gredient">
                        <h5 className="mb-1 ">Welcome <strong> Admin </strong></h5>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="9" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="2">
                        <div className="py-4">
                          <Link to="/admin/users">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Total Users</p>
                                <h5 className="mb-0 text-color-gredient"> {props.resultStats.totalUser} </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="2">
                        <div className="py-4">
                          <Link to="/admin/vendors">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2"> Total Vendors </p>
                                <h5 className="mb-0 text-color-gredient">{props.resultStats.totalVendor}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="2">
                        <div className="py-4">
                          <Link to="/admin/nft/collections">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2 ">Total Collections</p>
                                <h5 className="mb-0 text-color-gredient">{props.resultStats.collectionCount}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col xs="2">
                        <div className="py-4">
                          <Link to="/admin/event/requests">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Event Pending Request</p>
                                <h5 className="mb-0 text-color-gredient">{props.resultStats.eventReqCount}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>

                      <Col xs="2">
                        <div className="py-4">
                          <Link to="/admin/event/shows">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2">Upcoming Events</p>
                                <h5 className="mb-0 text-color-gredient">{props.resultStats.upcomingEvents}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>

                      <Col xs="2">
                        <div className="py-4">
                          <Link to="/admin/event/shows">
                            <div className="d-flex flex-wrap">
                              <div className="me-3">
                                <p className="text-muted mb-2 ">Today's Events</p>
                                <h5 className="mb-0 text-color-gredient">{props.resultStats.todayEvents}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
