import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Badge,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "../../assets/scss/style.css"

import "flatpickr/dist/themes/material_blue.css"
import noDataImage from "../../assets/images/nodata-found.png"
import { dateformat, getUserDetail } from "admin/commonFunction"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import { toast } from "react-toastify"
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination"

import { withTranslation } from "react-i18next"

const Collections = props => {
  //meta title
  document.title = " Properties "
  const BASE_PATH = process.env.REACT_APP_BASE_PATH
  const [listingData, setData] = useState([])
  const [searchName, setSearchName] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [view, setview] = useState(true)
  const [userInfo, setUserInfo] = useState("")
  const [loaderStatus, setLoaderStatus] = useState(true)
  const [status, setStatus] = useState("")
  const [approval, setApproval] = useState("")
  const perPage = 10;

  const handlePageClick = page => {
    setCurrentPage(page)
    fetchData(page, perPage, searchName, status)
  }

  const fetchData = (pagenumber, pagelimit, search = "", status) => {
    try {
      setLoaderStatus(true)
      axios
        .get(
          apiRoute.getProperty +
            "?page=" +
            pagenumber +
            "&limit=" +
            pagelimit +
            "&search=" +
            search +
            "&placeStatus=" +
            status
        )
        .then(res => {
          console.log(res.data, "Place listing >>>>>>>>>>>>>>>")
          setLoaderStatus(false)
          setData(res.data.data.property)

          let pageCount = Math.ceil(res.data.data.totalItems / perPage)
          setTotalCount(pageCount)
        })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData(currentPage, perPage, searchName, status)
    setUserInfo(getUserDetail())
  }, [])

  const handleSearchClick = event => {
    setSearchName(event.target.value)
    setCurrentPage(1)
    fetchData(1, perPage, event.target.value, status, approval)
  }
  const handleStatusClick = event => {
    setStatus(event.target.value)
    setCurrentPage(1)
    console.log(event.target.value, "hdh")
    fetchData(1, perPage, searchName, event.target.value, approval)
  }

  const handleApproveClick = event => {
    setApproval(event.target.value)
    setCurrentPage(1)
    console.log(event.target.value, "hdh")
    fetchData(1, perPage, searchName, status, event.target.value)
  }

  const deleteProperty = property_id => {
    try {
      axios
        .delete(apiRoute.deleteProperty + `?propertyID=${property_id}`)
        .then(res => {
          toast.success("Property Deleted Successfully")
          fetchData(currentPage, perPage, searchName, status)
        })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }
  const ConfirmDelete = id => {
    // let URL = BASE_URL + 'user/delete/' + id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteProperty(id)
      }
    })
  }

  const HandleChangeStatus = (type, id, status) => {
    try {
      let url = apiRoute.collectionChangeStatus
      let sendData = { active: status, CollectionID: id }
      if (type == "isApproved") {
        url = apiRoute.collectionApprovedStatus
        sendData = { isApproved: status, CollectionID: id }
      }
      axios.post(url, sendData).then(res => {
        toast.success("Status Updated Successfully")
        fetchData(currentPage, perPage, searchName, status, approval)
      })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }

  const handleChangeCheck = async (event, type) => {
    try {
      console.log(event.target.value)
      console.log(event.target.checked)

      let id = event.target.value
      let status = true
      let isApproved = 1
      if (!event.target.checked) {
        status = false
        isApproved = 0
      }

      let url = apiRoute.collectionChangeStatus
      let sendData = { active: status, CollectionID: id }
      if (type == "isApproved") {
        url = apiRoute.propertyApprovalStatus
        sendData = { isApproved: isApproved, propertyID: id }
      }
      axios.post(url, sendData).then(res => {
        toast.success("Status Updated Successfully")
        fetchData(currentPage, perPage, searchName, "", approval)
      })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }

  // console.log(userInfo, 'userinfo >>>>>>>>>>>>>>>>>>>')

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1 text-color-gredient">Properties</h5>
                    <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                      <div
                        className="position-relative"
                        style={{ marginTop: "10px" }}
                      >
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`Search ...`}
                            onChange={handleSearchClick}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        to="/admin/properties/create"
                        className="btn btn-secondary me-1"
                      >
                        <i className="bx bx-plus"></i> Create Property
                      </Link>
                    </div>
                    <div>
                      <span
                        className="btn btn-light me-1"
                        onClick={() => setview(true)}
                      >
                        <i
                          className="bx bx-grid-alt"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                      <span
                        className="btn btn-light me-1"
                        onClick={() => setview(false)}
                      >
                        <i
                          className="bx bx-list-ul"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row>
                    {listingData.length == 0 ? (
                      <Col sm="12" className="text-center">
                        <img src={noDataImage} style={{ width: "505px" }} />
                      </Col>
                    ) : view ? (
                      listingData.map((element, index) => {
                        return (
                          <Col xl="2" xs="6" className="mt-3 px-2" key={index}>
                            <Card>
                              <div className="nft-category-image d-flex">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={BASE_PATH + element.propertyImg}
                                />

                                {/* Approval Status */}
                                <div  className=""
                                  style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "10px",
                                  }}
                                >
                                  <Badge
                                    className={
                                      element.isApproved == false
                                        ? "bg-danger border"
                                        : "bg-success border"
                                    }
                                  >
                                    {element.isApproved == false
                                      ? "Pending"
                                      : "Approved"}
                                  </Badge>
                                </div>

                                {/* Building Type */}
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "30px",
                                  }}
                                >
                                  <Badge 
                                  className={
                                    element.buildingType == 'public'
                                      ? "bg-info border"
                                      : "bg-success border"
                                  }>
                                    {element.buildingType.toUpperCase()}
                                  </Badge>
                                </div>

                                <UncontrolledDropdown
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                  }}
                                >
                                  <DropdownToggle
                                    href="#"
                                    tag="a"
                                    className="dropdown-toggle"
                                  >
                                    <i
                                      className="bx bx-dots-vertical-rounded"
                                      style={{
                                        fontSize: "21px",
                                        color: "#2a3042",
                                        textShadow: "2px 1px 1px white"
                                      }}
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <Link
                                      className="dropdown-item"
                                      to={`/admin/properties/edit/${element._id}`}
                                    >
                                      {" "}
                                      Edit{" "}
                                    </Link>
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        ConfirmDelete(element._id)
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <div className="p-2">
                              <h5 className="font-size-14 mb-1 text-color-gredient">{element.name}</h5>
                                <span> #{element.propertyId} </span>
                              </div>
                            </Card>
                          </Col>
                        )
                      })
                    ) : (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead className="table-light">
                            <tr>
                              <th> Property </th>
                              <th> Created By </th>
                              <th> Property Type </th>
                              <th> Sections </th>
                              <th> Created At </th>
                              <th> Approval </th>
                              <th className="d-none"> Status </th>
                              <th> Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loaderStatus ? (
                              <tr>
                                <td>No Record Found</td>
                              </tr>
                            ) : (
                              listingData.map((element, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={BASE_PATH + element.propertyImg}
                                          className="avatar-sm me-3 rounded-circle"
                                          alt="img"
                                        />
                                        <div className="">
                                          <h5 className="font-size-14 mb-1 text-color-gredient">
                                            {" "}
                                            {element.name}{" "}
                                          </h5>
                                          <p className="text-muted mb-0">
                                            #{element.propertyId}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="">
                                          <h5 className="font-size-14 mb-1 text-color-gredient">
                                            {element.userdetails.name}
                                          </h5>
                                          <p className="text-muted mb-0">
                                          {element.userdetails.email}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        <h5 className="font-size-12 text-muted mb-0">
                                        
                                          <Badge 
                                          className={
                                            element.buildingType == 'public'
                                              ? "bg-info border"
                                              : "bg-success border"
                                          }>
                                            {element.buildingType.toUpperCase()}
                                          </Badge>
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        {(element.property_sections.length > 0) ? element.property_sections.map((value, index) => {
                                          return <h5 className="font-size-12 text-muted mb-1" key={index}> <i className="mdi mdi-checkbox-marked-outline me-1"></i> {value.name} </h5>
                                        }) : '-'}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        <h5 className="font-size-14 mb-0 text-color-gredient">
                                          {dateformat(element.createdAt)}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                    { (userInfo.role == 'admin') ? 
                                    <div className="square-switch mytoggle">
                                        <input
                                          type="checkbox"
                                          id={`square-switch1${index}`}
                                          switch="success"
                                          defaultChecked={Boolean(
                                            element.isApproved
                                          )}
                                          value={element._id}
                                          onClick={event =>
                                            handleChangeCheck(
                                              event,
                                              "isApproved"
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={`square-switch1${index}`}
                                          data-on-label="Accept"
                                          data-off-label="Pending"
                                          className="m-0"
                                        />
                                      </div>
                                    : (element.isApproved == 0) ?  <Badge className="bg-danger" style={{padding: '7px 7px'}}> Pending </Badge> : <Badge className="bg-success" style={{padding: '7px 7px'}}> Accepted </Badge> }
                                      
                                    </td>
                                    <td className="d-none">
                                      <div className="square-switch mytoggle">
                                        <input
                                          type="checkbox"
                                          id={`square-switch${index}`}
                                          switch="success"
                                          defaultChecked={Boolean(
                                            element.active
                                          )}
                                          value={element._id}
                                          onClick={event =>
                                            handleChangeCheck(event, "isActive")
                                          }
                                        />
                                        <label
                                          htmlFor={`square-switch${index}`}
                                          data-on-label="Active"
                                          data-off-label="Inactive"
                                          className="m-0"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <ul className="list-unstyled hstack gap-1 mb-0">
                                        <li>
                                          <Link
                                            to={`/admin/properties/edit/${element._id}`}
                                            className="btn btn-sm btn-soft-primary"
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-danger"
                                            onClick={() => {
                                              ConfirmDelete(element._id)
                                            }}
                                          >
                                            <i
                                              className="mdi mdi-delete-outline"
                                              id="deletetooltip"
                                            />
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                )
                              })
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </Row>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={page => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

Collections.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Collections)
