import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Card, CardBody, Col, Row, Container, Badge
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import '../../assets/scss/style.css'

import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import { dateformat, dateTimeformat, getUserDetail } from "admin/commonFunction"
import { LoaderContext } from "context/ContextProvider"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";
import noDataImage from "../../assets/images/nodata-found.png"

import event1 from "../../assets/images/events/event-1.jpg";
import event2 from "../../assets/images/events/event-2.jpg";
import event3 from "../../assets/images/events/event-3.jpg";
import event4 from "../../assets/images/events/event-4.jpg";
import event5 from "../../assets/images/events/event-5.jpg";
import event6 from "../../assets/images/events/event-6.jpg";

const EventsShow = props => {

    //meta title
    document.title = "Support";
    const BASE_PATH = process.env.REACT_APP_BASE_PATH
    const { loader, loading, setloading } = useContext(LoaderContext)
    const [listingData, setEventData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [view, setview] = useState(true)
    const [loaderStatus, setLoaderStatus] = useState(true)
    const [approval, setApproval] = useState("")
    const [userInfo, setUserInfo] = useState("")
    const perPage = 10

    const handlePageClick = page => {
        setCurrentPage(page)
        getEvents(page, perPage, searchName)
    }

    const getEvents = (pagenumber, pagelimit, search = "") => {
        try {

            setLoaderStatus(true)
            axios.get(apiRoute.supportListing + "?page=" + pagenumber + "&limit=" + pagelimit + "&search=" + search)
                .then(res => {

                    console.log(res, 'res >>>>>>>>>>')

                    setLoaderStatus(false)

                    setEventData(res.data.data.data.items)
                    let pageCount = Math.ceil(res.data.data.totalItems / perPage)
                    setTotalCount(pageCount)
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getEvents(currentPage, perPage, searchName)
        setUserInfo(getUserDetail())
    }, [])


    const handleSearchClick = event => {
        setSearchName(event.target.value)
        setCurrentPage(1)
        getEvents(1, perPage, event.target.value)
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1 text-color-gredient"> Support Requests </h5>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                            <div className="position-relative" style={{ marginTop: '10px' }}>
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search...`}
                                                        onChange={handleSearchClick}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="btn btn-light me-1" >
                                                <i className="mdi mdi-refresh" style={{ cursor: "pointer" }} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Row>

                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th> User </th>
                                                        <th> Title </th>
                                                        <th> Description </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loaderStatus ? (
                                                        <tr>
                                                            <td>No Record Found</td>
                                                        </tr>
                                                    ) : (
                                                        (listingData) && listingData.map((element, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ width: '350px' }}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="">
                                                                                <h5 className="font-size-14 mb-1 text-color-gredient">
                                                                                    {" "}
                                                                                    {element?.name}{" "}
                                                                                </h5>
                                                                                <p className="text-muted mb-0">
                                                                                    Email :{" "}
                                                                                    {element?.email}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {element?.title}
                                                                    </td>
                                                                    <td>
                                                                        {element?.description}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Row>


                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};


EventsShow.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(EventsShow);
