
   const BASE_URL = process.env.REACT_APP_BASE_URL
  //  const BASE_URL = "http://localhost:8003/api/";
  
  const apiRoute = {
    // Categories
    categoriesListing: BASE_URL+'category/getcategories',
    categoriesCreate: BASE_URL+'category/createcategory',
    categoriesUpdate: BASE_URL+'category/updatecategory',
    categoriesDelete: BASE_URL+'category/deletecategory',

    // Collections
    collectionListing: BASE_URL+'collection/getcollections',
    collectionCreate: BASE_URL+'collection/createcollectionwithticket',
    collectionUpdate: BASE_URL+'collection/updatecollection',
    collectionDelete: BASE_URL+'collection/deletecollection',
    collectionSingleDetail: BASE_URL+'collection/getCollectionDetail',
    collectionChangeStatus: BASE_URL+'collection/updatestatus',
    collectionApprovedStatus: BASE_URL+'collection/status/approve',
    
    // NFT 
    nftCreate: BASE_URL+'nft/createnft',
    nftListing: BASE_URL+'nft/getnfts',
    nftDetail: BASE_URL+'nft/getnftdetails',
    nftUpdate: BASE_URL+'nft/updatenft',
    nftStatusUpdate: BASE_URL+'nft/updatestatus',
    nftDelete: BASE_URL+'nft/deletenft',
    nftApprovalStatus: BASE_URL+'nft/status/approve',

    // FAQ / CSM
    addFaq: BASE_URL+'dashboard/addFaq',
    updateFaq: BASE_URL+'dashboard/editFaq',
    deleteFaq: BASE_URL+'dashboard/deleteFaq',
    getFaq: BASE_URL+'dashboard/getFaq',
    csmUpdate:BASE_URL+'dashboard/csm/update',
    getCsm:BASE_URL+'dashboard/csm/detail',

    //User
    registerUser:BASE_URL+'user/register',
    editUser:BASE_URL+'user/updateUser',
    userDetail:BASE_URL+'user/getUserDetail',
    getUsers:BASE_URL+'user/getUsers',  // Vendors
    getUserListing:BASE_URL+'user/getUserListing', // Users
    updateStatus:BASE_URL+'user/updatestatus',
    deleteUser:BASE_URL+'user/deleteUser',
    
    // Social Links
    SocialLikes: BASE_URL+'dashBoard/socialLinks',
    GetSocialLikes: BASE_URL+'dashBoard/getsocialLinks',

    //Property
    createProperty:BASE_URL+'property/create',
    getProperty:BASE_URL+'property/getProperties',
    propertyDetail:BASE_URL+'property/getPropertyDetail',
    deleteProperty:BASE_URL+'property/deleteProperty',
    editProperty:BASE_URL+'property/update',
    propertyApprovalStatus:BASE_URL+'property/status/approve',
    propertyWithCollection:BASE_URL+'property/create/propertyandcollection', // Property With Collection
    
    // Event
    eventCreate:BASE_URL+'event/create',
    eventListing:BASE_URL+'event/getEvents',
    eventDetail:BASE_URL+'event/detail',
    eventUpdate:BASE_URL+'event/update',
    eventDelete:BASE_URL+'event/delete',
    eventNFTListing:BASE_URL+'event/getNftListing',
    eventCollectionListing:BASE_URL+'event/collection/listing',
    eventRequests:BASE_URL+'nft/getEventRequests',
    eventRequestStatusUpdate:BASE_URL+'nft/event_status_update',
    eventApprovalStatus: BASE_URL+'event/status/approve',

    // Dashboard
    dashboardStats: BASE_URL+'dashboard/dashBoardData',
    
    // Transactions
    transactionsListing: BASE_URL+'transaction/getTransactions',

    // Support
    supportListing: BASE_URL+'app/getSupport',
}

export { apiRoute }
