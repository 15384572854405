import PropTypes, { element } from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Table } from "reactstrap";
import '../../../assets/scss/style.css'
import classnames from "classnames"

import noDataImage from '../../../assets/images/nodata-found.png';
import { dateformat } from "admin/commonFunction"
import { toast } from "react-toastify";
import { apiRoute } from "routes/apiRoutes";

import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

import { withTranslation } from "react-i18next";

const NFTImage7 = 'https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg';

const ShowNFT = props => {
    const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    //meta title
    document.title = "NFT's Details";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [resultData, setResultData] = useState([]);
    const [userdata,setUserData] = useState([]);
    const { id } = useParams();
console.log(resultData,"data");
    const fetchData = () => {

        try {
            console.log(id,"gjgjgjhg");
            axios.get(apiRoute.nftDetail + '?nftID=' + id).then(res => {

                console.log(res.data.data, 'res.data.data >>>>>>>>>>>>')
                setResultData(res.data.data.nft);
                setUserData(res.data.data.userDetails);
                // setFormValues(res.data.data)
                // setinputStats(res.data.data.stats)
                // setinputLevel(res.data.data.level)
                // setinputFields(res.data.data.properties)
            }).catch((error) => {
                console.log(error)
                toast.error("Collection not found !");
            })
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content margin-custom">
                <Container fluid>
                    <Row>
                        <Col xl="3" className="p-2 border">
                            <img src={`${process.env.REACT_APP_BASE_PATH + resultData?.itemMedia}`} style={{ height: '100%', width: '100%' }} />
                        </Col>
                        <Col xl="9">
                            <Card className="mb-2 border">
                                <div>
                                    <Row>
                                        <Col lg="12">
                                            <div className="p-4">
                                                <h5 className="text-primary"> {resultData?.name}  </h5>
                                                <p className="mb-0 text-muted"> <i className="mdi mdi-check-decagram text-info"></i> {userdata?userdata.name: 'Admin'} </p>

                                                <p className="mt-2 mb-3"> {resultData?.description}</p>

                                                <p className="mt-2 mb-1 fw-bold font-size-14"> <i className="mdi mdi-view-dashboard"></i> Properties </p>
                                                <div className="d-flex">
                                               
                                                    {/* <p className="mb-0 font-size-14 text-muted me-4"> <span className="font-theme-color"> Eyes </span> <br /> Red Rim </p>
                                                    <p className="mb-0 font-size-14 text-muted ms-3 me-4"> <span className="font-theme-color"> Hair </span> <br /> Dark Blonde Waves </p>
                                                    <p className="mb-0 font-size-14 text-muted ms-3 me-4"> <span className="font-theme-color"> Lips </span> <br /> Orange </p>
                                                    <p className="mb-0 font-size-14 text-muted ms-3 me-4"> <span className="font-theme-color"> Shirt </span> <br /> Red Stripe Shirt </p>
                                                    <p className="mb-0 font-size-14 text-muted ms-3 me-4"> <span className="font-theme-color"> Skin </span> <br /> Peach </p> */}
                                                    
                                                  {resultData.properties?.map((element,index) =>{
                                                    return (
                                                        index === 0 ? 
                                                    <p key={index} className="mb-0 font-size-14 text-muted me-4"> <span className="font-theme-color">{element.properiestype}</span> <br />{element.propertiesname}</p>
                                                     :
                                                    <p key={index} className="mb-0 font-size-14 text-muted ms-4 me-4"> <span className="font-theme-color">{element.properiestype}</span> <br />{element.propertiesname}</p>

                                                    )
                                                  })}

                                                </div>
                                                <p className="mt-2 mb-1 fw-bold font-size-14"> <i className="mdi mdi-view-dashboard"></i> Price </p>
                                                <div className="d-flex">
                                                    <p className="mb-0 font-size-14 text-muted me-4"> <span className="font-theme-color"> Current Price </span> <br /> {resultData.price} ETH </p>
                                                    {/* <p className="mb-0 font-size-14 text-muted ms-3 me-4"> <span className="font-theme-color"> Views Count </span> <br /> 90 </p>
                                                    <p className="mb-0 font-size-14 text-muted ms-3 me-4"> <span className="font-theme-color"> Favorites Count </span> <br /> 06 </p> */}
                                                </div>
                                                <p className="mt-2 mb-1 fw-bold font-size-14"> <i className="mdi mdi-view-dashboard"></i> Stats </p>
                                                <div className="d-flex">
                                                {
                                                   (resultData.stats?.length == 0)?

                                                     <p className="mb-0 font-size-14 text-muted me-4">No Stats</p>
                                                    :
                                                    resultData.stats?.map((element,index) =>{
                                                    return (
                                                        index === 0 ? 
                                                    <p key={index} className="mb-0 font-size-14 text-muted me-4"> <span className="font-theme-color">{element.statsname}</span> <br />{element.statsvalue} of {element.statspercentage}</p>
                                                    :
                                                    <p key={index} className="mb-0 font-size-14 text-muted ms-4 me-4"> <span className="font-theme-color">{element.statsname}</span> <br />{element.statsvalue} of {element.statspercentage}</p>

                                                    )
                                                })

                                                }
                                                </div>

                                                <p className="mt-2 mb-1 fw-bold font-size-14"> <i className="mdi mdi-view-dashboard"></i> Levels </p>
                                                <div className="d-flex">
                                                {
                                                    (resultData.level?.length == 0)?

                                                     <p className="mb-0 font-size-14 text-muted me-4">No Levels</p>
                                                    :
                                                    resultData.level?.map((element,index) =>{
                                                    return (
                                                        index === 0 ? 
                                                    <p key={index} className="mb-0 font-size-14 text-muted me-4"> <span className="font-theme-color">{element.levelname}</span> <br />{element.levelvalue}/{element.levelpercentage}</p>
                                                    :
                                                    <p key={index} className="mb-0 font-size-14 text-muted ms-4 me-4"> <span className="font-theme-color">{element.levelname}</span> <br />{element.levelvalue}/{element.levelpercentage}</p>

                                                    )
                                                })

                                                }
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>

                            {/* <h4 className="card-title mt-3">Price History</h4>
                            <Row style={{ padding: '0px 40px 0px 35px' }}>
                                <Slider {...settings}>
                                    <Col sm="4" className="p-2">
                                        <Card className="mb-0 border">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0"> John Snow </h5>
                                                        <div className="d-flex justify-content-between">

                                                            <p className="text-muted fw-medium mb-0 mt-1">
                                                                14 May, 2022
                                                            </p>
                                                            <p className="text-primary font-size-14 fw-medium mb-0 mt-1">
                                                                $ 306.00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="4" className="p-2">
                                        <Card className="mb-0 border">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0"> Ken Shuttleworth</h5>
                                                        <div className="d-flex justify-content-between">

                                                            <p className="text-muted fw-medium mb-0 mt-1">
                                                                10 May, 2022
                                                            </p>
                                                            <p className="text-primary font-size-14 fw-medium mb-0 mt-1">
                                                                $ 299.00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="4" className="p-2">
                                        <Card className="mb-0 border">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0"> Geoff Arnold </h5>
                                                        <div className="d-flex justify-content-between">

                                                            <p className="text-muted fw-medium mb-0 mt-1">
                                                                01 May, 2022
                                                            </p>
                                                            <p className="text-primary font-size-14 fw-medium mb-0 mt-1">
                                                                $ 269.00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="4" className="p-2">
                                        <Card className="mb-0 border">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0">Bob Woolmer </h5>
                                                        <div className="d-flex justify-content-between">

                                                            <p className="text-muted fw-medium mb-0 mt-1">
                                                                10 Apr, 2022
                                                            </p>
                                                            <p className="text-primary font-size-14 fw-medium mb-0 mt-1">
                                                                $ 234.00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="4" className="p-2">
                                        <Card className="mb-0 border">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0"> Frank Hayes </h5>
                                                        <div className="d-flex justify-content-between">

                                                            <p className="text-muted fw-medium mb-0 mt-1">
                                                                09 Apr, 2022
                                                            </p>
                                                            <p className="text-primary font-size-14 fw-medium mb-0 mt-1">
                                                                $ 230.00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="4" className="p-2">
                                        <Card className="mb-0 border">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0"> Graham Roope </h5>
                                                        <div className="d-flex justify-content-between">

                                                            <p className="text-muted fw-medium mb-0 mt-1">
                                                                02 Feb, 2022
                                                            </p>
                                                            <p className="text-primary font-size-14 fw-medium mb-0 mt-1">
                                                                $ 201.00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="4" className="p-2">
                                        <Card className="mb-0 border">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0">Derek Underwood </h5>
                                                        <div className="d-flex justify-content-between">

                                                            <p className="text-muted fw-medium mb-0 mt-1">
                                                                29 Jan, 2022
                                                            </p>
                                                            <p className="text-primary font-size-14 fw-medium mb-0 mt-1">
                                                                $ 198.00
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Slider>
                            </Row> */}
                        </Col>


                        <Col xl="12" className="mt-2 border">
                            <Card className="my-4">
                                <h4 className="card-title mb-3">Trade History</h4>

                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th> Action </th>
                                                <th> Price </th>
                                                <th> From </th>
                                                <th> To </th>
                                                <th> Transaction Hash </th>
                                                <th> Created Date </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr >
                                                {/* <td align="center"> */}
                                                {/* </td> */}
                                                <td colSpan="6">
                                                <p className="mb-0 text-muted"> No records found</p>
                                                </td>
                                                
                                                </tr>
                                            {/* <tr>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> Transfer </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> $ 210.00 </h5>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Maurice Read</h5>
                                                        <p> maurice.read@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Billy Bates</h5>
                                                        <p> billy.bates@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> November 29, 2022 </h5>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> Sale </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> $ 208.00 </h5>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Johnny Briggs</h5>
                                                        <p> johnny.briggs@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Javed</h5>
                                                        <p> javed.332@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> November 15, 2022 </h5>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> Transfer </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> $ 206.00 </h5>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Arnold Fothergill</h5>
                                                        <p> arnold.fothergill@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Frank Hearne</h5>
                                                        <p> frank.hearne@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> November 11, 2022 </h5>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> Transfer </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> $ 204.00 </h5>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Billy Newham</h5>
                                                        <p> billy.newham@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">William Gunn</h5>
                                                        <p> william.gunn@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> November 06, 2022 </h5>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> Sale </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> $ 200.00 </h5>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Bobby Peel</h5>
                                                        <p> bobby.peel@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">George Lohmann</h5>
                                                        <p> george.lohmann@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> November 05, 2022 </h5>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> Minted </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> $ 198.00 </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <h5 className="font-size-14 mb-0">Billy Bates</h5>
                                                        <p> billy.bates@gmail.com </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> - </h5>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 text-muted mb-0"> November 01, 2022 </h5>
                                                </td>
                                            </tr> */}

                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};


ShowNFT.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ShowNFT);
