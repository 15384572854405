import React, { useState } from "react"
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import JoditEditor from "jodit-react"
import axios from "axios"
import { alerShow } from "../commonFunction"
import { apiRoute } from "routes/apiRoutes"
// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/style.scss"
import { useEffect } from "react"

const FormElement = () => {
  //meta title
  document.title = "Social Links"
  const [formValues, setFormValues] = useState({
    twitter: "",
    telegram: "",
    discord: "",
    youtube: "",
  })

  const changeValues = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    getSocialLinks()
  }, [])

  const getSocialLinks = async () => {
    await axios
      .get(apiRoute.GetSocialLikes)
      .then(function (response) {
        console.log(response.data,'response.data >>>>>>>>>>>>')
        var Result = response.data.data.response
        setFormValues(response.data.data.response)
      })
      .catch(function (error) {
        // alerShow('Error', error.response.data.message, 'error');
      })
  }

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      twitter: formValues.twitter,
      telegram: formValues.telegram,
      discord: formValues.discord,
      youtube: formValues.youtube,
    },
    validationSchema: Yup.object({
    //   twitter: Yup.string().required("This field is required."),
    //   telegram: Yup.string().required("This field is required."),
    //   discord: Yup.string().required("This field is required."),
    //   youtube: Yup.string().required("This field is required."),
    }),
    onSubmit: values => {
      updateSocialLikes(values)
    },
  })

  const updateSocialLikes = async data => {
    await axios
      .post(apiRoute.SocialLikes, data)
      .then(function (response) {
        alerShow("Success", response.data.message, "success")
      })
      .catch(function (error) {
        console.log(error, "eeeee")
        alerShow("Error", error.response.data.message, "error")
      })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <form
                    className="outer-repeater custom-error-val"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                              <i className="mdi mdi-twitter" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0 text-info">Twitter</h5>
                        </div>
                          <input
                            type="text"
                            name="twitter"
                            className="inner form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.twitter}
                          />
                          {validation.touched.twitter &&
                          validation.errors.twitter ? (
                            <FormFeedback type="invalid">
                              {validation.errors.twitter}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="mb-4 mt-2">
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-secondary bg-soft text-secondary font-size-18">
                              <i className="mdi mdi-discord" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0 text-secondary">Discord</h5>
                        </div>
                          <input
                            type="text"
                            name="discord"
                            className="inner form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.discord}
                          />
                          {validation.touched.discord &&
                          validation.errors.discord ? (
                            <FormFeedback type="invalid">
                              {validation.errors.discord}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="mb-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                              <i className="mdi mdi-telegram" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0 text-primary">Telegram</h5>
                        </div>
                          <input
                            type="text"
                            name="telegram"
                            className="inner form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.telegram}
                          />
                          {validation.touched.telegram &&
                          validation.errors.telegram ? (
                            <FormFeedback type="invalid">
                              {validation.errors.telegram}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        
                        <FormGroup className="mb-4">
                        
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-danger bg-soft text-danger font-size-18">
                              <i className="mdi mdi-youtube" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0 text-danger">Youtube</h5>
                        </div>
                          <input
                            type="text"
                            name="youtube"
                            className="inner form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.youtube}
                          />
                          {validation.touched.youtube &&
                          validation.errors.youtube ? (
                            <FormFeedback type="invalid">
                              {validation.errors.youtube}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </div>
                    </div>
                    <Row className="justify-content-end">
                      <Col lg="12">
                        <button type="submit" className="btn btn-primary w-md mt-2">
                          {" "}
                          Submit{" "}
                        </button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default FormElement
