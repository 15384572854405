import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import {
    Col,
} from "reactstrap"

const containerStyle = {
    width: "100%",
    height: "270px"
};

function Map({ latitude, longitude, changeFunction }) {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyC2-n39eQnutXECIDc-9tlNMNFmxzshDtE"
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {

        let bounds = new window.google.maps.LatLngBounds();
        map.setOptions({ styles: [
            {
                featureType: "poi",
                stylers: [
                    { visibility: "off" }
                ]
            }
        ] })

        let marker = new google.maps.Marker({
            position: {
                lat: latitude,
                lng: longitude,
            },
            map: map,
            draggable: true,
        })
        marker.addListener('dragend', handleDragEnd)
        
    }, []);
    const handleDragEnd = async (e) => {
        console.log(e, 'sdfsdfasdf')
        const { latLng } = e;
        const lat = latLng.lat();
        const lng = latLng.lng();

        await changeFunction({ lat, lng });
        console.log(lat, lng, 'lat long >>>>>>>>>>>')
    }

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);


    const onMarkerDragEnd = React.useCallback(function callback(map) {

        let marker = new google.maps.Marker({
            position: {
                lat: latitude,
                lng: longitude,
            },
            map: map,
            draggable: true,
        })
        marker.addListener('dragend', handleDragEnd)
    });

    return isLoaded ? (
        <>

            <Col md={12} className="mb-2 mt-2">
                <div>
                    <GoogleMap
                        options={{
                            draggable: true,
                            scrollwheel: true,
                            keyboardShortcuts: true,
                            mapTypeControl: true,
                            fullscreenControl: true,
                            zoomControl: true,
                            streetViewControl: false,
                        }}
                        zoom={17}
                        center={{
                            lat: latitude,
                            lng: longitude,
                        }}
                        mapContainerStyle={containerStyle}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onDragEnd={(t, map, coord) => onMarkerDragEnd(map)}
                        mapTypeId='satellite'
                    >

                        {/* Child components, such as markers, info windows, etc. */}
                        <>

                        </>
                    </GoogleMap>
                </div>
            </Col>
            <Col md={6} className="mb-2 mt-2">
                <input type="text" readOnly name='lat' className='form-control' value={latitude} />
            </Col>
            <Col md={6} className="mb-2 mt-2">
                <input type="text" readOnly name='lat' className='form-control' value={longitude} />
            </Col>
        </>
    ) : (
        <></>
    );
}

export default React.memo(Map);