import PropTypes from "prop-types"
import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Button,
  FormFeedback,
} from "reactstrap"
import "../../../assets/scss/style.css"
import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"
import moment from "moment"
import Select from "react-select"
import { deploy } from "utils/deploy"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const NFTImage7 =
  "https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg"

const ShowNFT = props => {
  //meta title
  document.title = "Collection Create"
  const history = useHistory()
  const BASE_PATH = process.env.REACT_APP_BASE_PATH
  const { loader, loading, setloading, account } = useContext(LoaderContext)
  const [selectPeriod, setSelectPeriod] = useState('fix_date');
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    royalties: "",
    nftlimit: "",
    ticket_price: "",
    collection_type: "",
    no_uses: "",
    start_date: "",
    end_date: "",
    select_dateformat:"",
    time_period: "",
    select_period:"",
    property_img: "",
    property_banner_img: "",
    nftTicket: "",
    property_id: "",
    category_id: "",
  })

  const [logoImg, setLogoImg] = useState("")
  const [bannerImg, setBannerImg] = useState("")
  const [ticket_image, setnftTicket] = useState("")

  const changeValues = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }
  console.log(formValues.select_period,"/////");
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: formValues.name,
      description: formValues.description,
      royalties: formValues.royalties,
      nftlimit: formValues.nftlimit,
      ticket_price: formValues.ticket_price,
      collection_type: formValues.collection_type,
      no_uses: formValues.no_uses,
      select_dateformat:selectPeriod,
      time_period: formValues.time_period,
      select_period:formValues.select_period,
      property_img: formValues.property_img,
      property_banner_img: formValues.property_banner_img,
      property_id: formValues.property_id,
      category_id: formValues.category_id,
      logoImg: logoImg,
      bannerImg: bannerImg,
      ticket_image: ticket_image,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required."),
      // royalties: Yup.number().max(100).required("This field is required."),
      description: Yup.string().required("This field is required."),
      nftlimit: Yup.string().required("This field is required."),
      ticket_price: Yup.string().required("This field is required."),
      collection_type: Yup.string().required("This field is required."),
      no_uses: Yup.string().required("This field is required."),
      // select_dateformat: Yup.string().required("This field is required."),
      // time_period: Yup.string().required("This field is required."),
      category_id: Yup.string().required("This field is required."),
      // logoImg: Yup.string().required("This field is required."),
      // bannerImg: Yup.string().required("This field is required."),
      ticket_image: Yup.string().required("This field is required."),
    }),
    onSubmit: values => {
      createCollectionFun(values)
    },
  })

  const createCollectionFun = async data => {
    console.log(data, "form values >>>>>>>>>>>>>>>>>>>>>>")

    var StartEndDateTime = data.time_period.split(" / ")
    var questionElements = document.getElementsByClassName("question-cls")
    var answerElements = document.getElementsByClassName("answer-cls")

    var FAQrecord = []
    for (var i = 0; i < questionElements.length; i++) {
      if (questionElements[i].value != "" && answerElements[i].value != "") {
        var record = {}
        record.question = questionElements[i].value
        record.answer = answerElements[i].value
        FAQrecord[i] = record
      }
    }

    var formData = new FormData()
    formData.append("bannerImg", data.bannerImg)
    formData.append("description", data.description)
    formData.append("logoImg", data.logoImg)
    formData.append("property_image", data.property_img)
    formData.append("mapView_image", data.property_banner_img)
    formData.append("propertyId", data.property_id)
    formData.append("categoryId", data.category_id)
    formData.append("royalties", 0)
    formData.append("name", data.name)
    formData.append("nftlimit", data.nftlimit)
    formData.append("type", data.collection_type)
    formData.append("startDateTime", StartEndDateTime[0])
    formData.append("endDateTime", StartEndDateTime[1])
    formData.append("useTime", data.no_uses)
    formData.append("date_format", data.select_dateformat)
    formData.append("valid_period", data.select_period)
    formData.append("ticket_image", data.ticket_image)
    formData.append("ticket_price", data.ticket_price)
    formData.append("faq", JSON.stringify(FAQrecord))
   console.log(data.select_dateformat);
    if(data.select_dateformat == 'days'){
       var valid_period = data.select_period*24*60*60;
      //  var valid_period = 180;
       var membership = true
    }else{
       const date =new Date(StartEndDateTime[1]);
        valid_period = Math.floor(date.getTime() / 1000);
       console.log(valid_period);
        membership = false
    }


    let base_url = `https://universe-eye-apis.block-brew.com/uploads/${data.name}/json/`;

    if(data.no_uses == 0){
      var useTime = 100000
    }else{
      useTime = data.no_uses;
    }

    setloading(true)
    const res = await deploy(
      data.name,
      data.name,
      base_url,
      data.nftlimit,
      data.ticket_price,
      useTime,
      valid_period,
      membership,
      setloading 
    )
    if (res) {
      await axios
        .post(apiRoute.collectionCreate, formData)
        .then(async (response) => {
          var formData1 = new FormData()
          formData1.append("collectionID", response.data.data.collection._id)
          formData1.append("contractAdd", res.address)
          formData1.append("deployed", true)
          console.log(response.data.data.collection._id, "update");
          await axios
            .put(apiRoute.collectionUpdate, formData1)
            .then(_response => {
              toast.success("Successfully Deployed")
            })
            .catch(error => {
              setloading(false)
              toast.error(error._response.data.error)
            })

          setloading(false)
          toast.success(response.data.message)
          history.push("/admin/nft/collections")
        })
        .catch(error => {
          setloading(false)
          toast.error(error.response.data.error)
        })
    }
  }

  const inpLevel = [{ question: "", answer: "" }]
  const [inputLevel, setinputLevel] = useState(inpLevel)

  function handleAddFieldsFAQ() {
    const item1 = { question: "", answer: "" }
    setinputLevel([...inputLevel, item1])
  }

  function handleRemoveLevel(idx) {
    document.getElementById("nestedLevel" + idx).remove()
  }

  const updateDateTimePeriod = (date, type) => {
    setFormValues({ ...formValues, [type]: date })
  }

  const handleDateEvent = async event => {
    console.log(event.length)
    if (event.length == 2) {
      let filterDate = []
      filterDate[0] = moment(event[0]).format("YYYY-MM-DD")
      filterDate[1] = moment(event[1]).format("YYYY-MM-DD")

      setFormValues({
        ...formValues,
        ["time_period"]: filterDate[0] + " / " + filterDate[1],
      })
    } else {
      setFormValues({ ...formValues, ["time_period"]: "" })
    }
  }


  const [listCollection, setListCollection] = useState([])
  const [optionCollectionGroup, setOptionCollectionGroup] = useState([])
  const fetchCollections = () => {
    try {
      axios
        .get(apiRoute.getProperty)
        .then(res => {
          setListCollection(res.data.data.property)

          var propertyOptionGroup = []
          res.data.data.property.map((value, index) => {
            var optionData = {}
            optionData.label = value.name
            optionData.value = value._id
            propertyOptionGroup.push(optionData)
          })
          setOptionCollectionGroup(propertyOptionGroup)
        })
        .catch(error => {
          console.log(error)
          toast.error("Something went wrong!")
        })
    } catch (err) {
      console.log(err)
    }
  }

  const [listCategories, setListCategories] = useState([])
  const [optionCategoriesGroup, setOptionCategoriesGroup] = useState([])
  const fetchCategories = () => {
    try {
      axios
        .get(apiRoute.categoriesListing)
        .then(res => {
          setListCategories(res.data.data.category.items)

          var propertyOptionGroup = []
          res.data.data.category.items.map((value, index) => {
            var optionData = {}
            optionData.label = value.name
            optionData.value = value._id
            propertyOptionGroup.push(optionData)
          })
          setOptionCategoriesGroup(propertyOptionGroup)
        })
        .catch(error => {
          console.log(error)
          toast.error("Something went wrong!")
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchCollections()
    fetchCategories()
  }, [])

  const [selectedCollectionMulti, setselectedCollectionMulti] = useState(null)

  function handleCollectionMultiRemove(selectedCollectionMulti) {
    setselectedCollectionMulti(null)

    setFormValues({
      name: "",
      description: "",
      royalties: 0,
      nftlimit: formValues.nftlimit,
      collection_type: formValues.collection_type,
      no_uses: formValues.no_uses,
      start_date: formValues.start_date,
      end_date: formValues.end_date,
      time_period: formValues.time_period,
      property_img: "",
      property_banner_img: "",
      property_id: "",
    })
  }
  function handleCollectionMulti(selectedCollectionMulti) {
    setselectedCollectionMulti(selectedCollectionMulti)

    if (listCollection) {
      listCollection.map((value, index) => {
        if (value._id == selectedCollectionMulti.value) {
          setFormValues({
            name: value.name,
            description: value.description,
            royalties: 0,
            nftlimit: formValues.nftlimit,
            collection_type: formValues.collection_type,
            no_uses: formValues.no_uses,
            start_date: formValues.start_date,
            end_date: formValues.end_date,
            time_period: formValues.time_period,
            property_img: value.propertyImg,
            property_banner_img: value.mapView,
            property_id: selectedCollectionMulti.value,
          })
        }
      })
    }
  }

  // Categories
  const [selectedCategories, setSelectedCategories] = useState(null)
  function handleCategoriesRemove(selectedCollectionMulti) {
    setSelectedCategories(null)
    setFormValues({ ...formValues, ["category_id"]: "" })
  }
  function handleCategories(event) {
    setSelectedCategories(event)
    setFormValues({ ...formValues, ["category_id"]: event.value })
  }

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="validation-cls"
                  >
                    <CardTitle className="h5 mb-4 text-color-gredient">Create Collection</CardTitle>

                    <Row className="">
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <label htmlFor="floatingnameInput">
                              Select Property{" "}
                              <span className="font-size-11 text-secondary">
                                {" "}
                                [ Do you want to link this collection to a
                                property? ]{" "}
                              </span>{" "}
                            </label>
                            <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                value={selectedCollectionMulti}
                                isMulti={false}
                                options={optionCollectionGroup}
                                className="select2-selection"
                                onChange={e => {
                                  handleCollectionMulti(e)
                                }}
                                onKeyDown={e => {
                                  handleCollectionMultiRemove(e)
                                }}
                              />
                              {validation.touched.collection_id &&
                                validation.errors.collection_id ? (
                                <FormFeedback type="invalid">
                                  {" "}
                                  {validation.errors.collection_id}{" "}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col md={12}>
                            <label htmlFor="floatingnameInput">
                              Select Category{" "}
                            </label>
                            <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                value={selectedCategories}
                                isMulti={false}
                                options={optionCategoriesGroup}
                                className="select2-selection"
                                onChange={e => {
                                  handleCategories(e)
                                }}
                                onKeyDown={e => {
                                  handleCategoriesRemove(e)
                                }}
                              />
                              {validation.touched.category_id &&
                                validation.errors.category_id ? (
                                <FormFeedback type="invalid">
                                  {" "}
                                  {validation.errors.category_id}{" "}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col md={12}>
                            <div className="mb-3">
                              <label htmlFor="floatingnameInput">Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="floatingnameInput"
                                placeholder="Enter Collection Name..."
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                                value={formValues.name}
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6} className="d-none">
                            <div className="mb-3 mt-2">
                              <label htmlFor="floatingnameInput">
                                Royalties
                              </label>
                              <input
                                type="number"
                                name="royalties"
                                className="form-control"
                                id="floatingnameInput"
                                placeholder="Enter Royalties..."
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                                value={formValues.royalties}
                              />
                              {validation.touched.royalties &&
                                validation.errors.royalties ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.royalties}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="floatingnameInput1">
                            Description
                          </label>
                          <textarea
                            name="description"
                            className="form-control"
                            id="floatingnameInput1"
                            rows={3}
                            placeholder="Enter Description..."
                            style={{ height: "205px" }}
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.description}
                          ></textarea>
                          {validation.touched.description &&
                            validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3 mt-0">
                          <label htmlFor="floatingnameInput">
                            Number of NFT
                          </label>
                          <input
                            type="number"
                            name="nftlimit"
                            className="form-control"
                            id="floatingnameInput"
                            placeholder="Enter Limit..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.nftlimit}
                          />
                          {validation.touched.nftlimit &&
                            validation.errors.nftlimit ? (
                            <FormFeedback type="invalid">
                              {validation.errors.nftlimit}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3 mt-0">
                          <label htmlFor="floatingnameInput">
                            {" "}
                            Pass or Membership{" "}
                          </label>
                          <select
                            defaultValue="0"
                            name="collection_type"
                            className="form-select"
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                          >
                            <option value=""> Select... </option>
                            <option value="event"> Pass </option>
                            <option value="membership"> Membership </option>
                          </select>
                          {validation.touched.collection_type &&
                            validation.errors.collection_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.collection_type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col
                        md={formValues.property_id == "" ? 6 : 5}
                        className="mb-4"
                      >
                        <label htmlFor="floatingnameInput">
                          Collection Logo
                        </label>
                        <input
                          type="file"
                          name="logoImg"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Upload Collection Logo"
                          onChange={event => {
                            setLogoImg(event.currentTarget.files[0])
                          }}
                        />
                        {validation.touched.logoImg &&
                          validation.errors.logoImg ? (
                          <FormFeedback type="invalid">
                            {validation.errors.logoImg}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {formValues.property_id != "" ? (
                        <Col md={1} className="mb-2 overflow-hidden">
                          <img
                            src={BASE_PATH + formValues.property_img}
                            style={{ height: "80px" }}
                          />
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col md={3}>
                        <div className="mb-3 mt-0">
                          <label htmlFor="floatingnameInput">
                            Number of Uses{" "}
                            <span className="font-size-11 text-secondary">
                              {" "}
                              [ Put 0 to enter unlimited times ]{" "}
                            </span>{" "}
                          </label>
                          <input
                            type="number"
                            name="no_uses"
                            className="form-control"
                            id="floatingnameInput"
                            placeholder="Enter Number..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.no_uses}
                          />
                          {validation.touched.no_uses &&
                            validation.errors.no_uses ? (
                            <FormFeedback type="invalid">
                              {validation.errors.no_uses}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={3} className="mb-3 mt-0">
                        <label htmlFor="floatingnameInput">Ticket Price</label>
                        <input
                          type="number"
                          name="ticket_price"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Enter Ticket Price...(ETH)"
                          onBlur={validation.handleBlur}
                          onChange={changeValues}
                          value={formValues.ticket_price}
                        />
                        {validation.touched.ticket_price &&
                          validation.errors.ticket_price ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ticket_price}
                          </FormFeedback>
                        ) : null}
                      </Col>


                      <Col
                        md={formValues.property_id == "" ? 6 : 5}
                        className="mb-4"
                      >
                        <label htmlFor="floatingnameInput">
                          Collection Banner
                        </label>
                        <input
                          type="file"
                          name="bannerImg"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Upload Collection Banner"
                          onChange={event => {
                            setBannerImg(event.currentTarget.files[0])
                          }}
                        />
                        {validation.touched.bannerImg &&
                          validation.errors.bannerImg ? (
                          <FormFeedback type="invalid">
                            {validation.errors.bannerImg}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {formValues.property_id != "" ? (
                        <Col md={1} className="mb-2 overflow-hidden">
                          <img
                            src={BASE_PATH + formValues.property_banner_img}
                            style={{ height: "80px" }}
                          />
                        </Col>
                      ) : (
                        ""
                      )}



                      <Col md={3} className="mb-4">
                        <label htmlFor="floatingnameInput">
                          Select Date Format
                        </label>
                        <select
                          defaultValue={selectPeriod}
                          name="select_dateformat"
                          className="form-select"
                          onBlur={validation.handleBlur}
                          onChange={(event) => setSelectPeriod(event.target.value)}
                        >
                          <option value="days"> Days Period </option>
                          <option value="fix_date"> Fix DateTime </option>
                        </select>

                        {validation.touched.select_dateformat &&
                          validation.errors.select_dateformat ? (
                          <FormFeedback type="invalid">
                            {validation.errors.select_dateformat}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {
                        (selectPeriod == "days") ?

                          <Col md={3} className="mb-4">
                            <label htmlFor="floatingnameInput">
                              Select Period
                            </label>
                            <select
                              defaultValue="30"
                              name="select_period"
                              className="form-select"
                              onBlur={validation.handleBlur}
                              onChange={changeValues}
                            >
                              <option value="30"> Select... </option>
                              <option value="30"> 1 Month </option>
                              <option value="90"> 3 Months </option>
                              <option value="180"> 6 Months </option>
                              <option value="365"> 1 Year </option>
                            </select>

                            {validation.touched.select_period &&
                              validation.errors.select_period ? (
                              <FormFeedback type="invalid">
                                {validation.errors.select_period}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          :

                          <Col md={3}>
                            <div className="mb-4">
                              <label htmlFor="floatingnameInput">
                                Validity Period
                              </label>
                              <Flatpickr
                                className="form-control d-block"
                                style={{ marginRight: "8px" }}
                                onChange={date => handleDateEvent(date)}
                                placeholder="Select Dates"
                                options={{ mode: "range", dateFormat: "Y-m-d" }}
                              />
                              {validation.touched.time_period &&
                                validation.errors.time_period ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.time_period}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                      }


                      <Col md={ticket_image == "" ? 6 : 5} className="mb-4">
                        <label htmlFor="floatingnameInput">
                          Collection Ticket
                        </label>
                        <input
                          type="file"
                          name="ticket_image"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Upload Collection Ticket"
                          onChange={event => {
                            setnftTicket(event.currentTarget.files[0])
                          }}
                        />
                        {validation.touched.ticket_image &&
                          validation.errors.ticket_image ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ticket_image}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {ticket_image != "" ? (
                        <Col md={1} className="mb-2 overflow-hidden">
                          <img
                            src={
                              ticket_image
                                ? URL.createObjectURL(ticket_image)
                                : null
                            }
                            style={{ height: "80px" }}
                          />
                        </Col>
                      ) : (
                        ""
                      )}


                      <Col md={12} className="mb-2 mt-3">
                        <label className="font-size-18">FAQ - </label>
                        <Button
                          color="success"
                          className="inner btn-sm ms-2 mb-1"
                          onClick={() => {
                            handleAddFieldsFAQ()
                          }}
                        >
                          + Add FAQ
                        </Button>
                        {inputLevel.map((field, key) => (
                          <Row key={key} id={"nestedLevel" + key}>
                            <Col md={10}>
                              <div className="mb-3">
                                <label>- Question</label>
                                <input
                                  type="text"
                                  name="question[]"
                                  className="form-control question-cls"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>

                            <Col md={2}>
                              <div className="mt-2 mt-md-0 d-grid">
                                <label className="invisible">delete</label>
                                <Button
                                  color="primary"
                                  className="inner"
                                  onClick={() => {
                                    handleRemoveLevel(key)
                                  }}
                                  block
                                >
                                  Delete
                                </Button>
                              </div>
                            </Col>
                            <Col md={12} className="pe-0">
                              <div className="mb-3">
                                <label>- Answer</label>
                                <textarea
                                  name="answer[]"
                                  className="form-control answer-cls"
                                  id="floatingnameInput1"
                                  rows={2}
                                  placeholder="Enter Description..."
                                  style={{ height: "90px" }}
                                ></textarea>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>

                    <div>
                      <Link
                        to="/admin/nft/collections"
                        className="btn btn-secondary w-md mt-2 me-2"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary w-md mt-2"
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ShowNFT.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ShowNFT)