import contract from "../contracts/myContractABI.json";
import { ethers } from "ethers";
import { isMetaMaskInstalled, ethereum,connectAccount } from "../config";
import {  toast } from 'react-toastify';

export const mint = async (mint_amount,price,setloading,contractAdd) => {
  try {
    if (isMetaMaskInstalled()) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const contractAddress = contractAdd;
      const nftContract = new ethers.Contract(
        contractAddress,
        contract,
        signer
      );
      // let limit = await nftContract.USERLIMIT();
      // let walletMints = await nftContract.maxWalletMints( ethereum.selectedAddress);
      // console.log(walletMints,"mints");
      // let total = parseInt(walletMints) + parseInt(mint_amount);
     
      // if(parseInt(walletMints) == limit){
      //   toast.error('Max NFT per Wallet limit reached', {
      //     position: "top-right",
      //     autoClose: 4000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //     });
      //     return;
      // }

      // if(total  > limit){
      //   let left = limit - parseInt(walletMints);
      //   toast.error(`You have only ${left} Nfts left to mint` , {
      //     position: "top-right",
      //     autoClose: 4000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //     });
      //     return;
      // }

      let txnHash = await nftContract.mint(
        ethereum.selectedAddress,
        mint_amount,
        {
          gasLimit: "600000",
          value: ethers.utils.parseEther((price * mint_amount).toString()),
        });

       console.log(txnHash,"txnHash");

        if(txnHash){
        toast.info('Minting in Process...', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            setloading(false);
          }

     
      console.log(txnHash,"Hash");
      const txReceipt = await provider.waitForTransaction(
        `${txnHash.hash}`,
        1,
        500000
      );



      // console.log(txReceipt,"txReceipt");
      if (txReceipt && txReceipt.blockNumber) {
        let network = ethereum.networkVersion
        const pro1 = new ethers.providers.EtherscanProvider(Number(network));
        let history = await pro1.getTransaction(txReceipt.transactionHash);
        let tokenIds = [];
        for(let i = 0 ; i < txReceipt.logs.length;i++){
          tokenIds.push(parseInt(txReceipt.logs[i].topics[3],16))
        }
        const tx = {
          from: txReceipt.from,
          to: txReceipt.to,
          value: ethers.utils.formatEther(history.value),
          gasPrice: ethers.utils.formatUnits(history.gasPrice, "gwei"),
          fee: ethers.utils.formatEther(history.gasPrice.mul(txReceipt.gasUsed)),
          tokenIds:tokenIds
        };
        console.log(txReceipt,"txReceipt");
        console.log(history,"history");
        console.log(tx,"Transaction Details");
        return txReceipt;
      }

    }
  } catch (err) {
    console.log(err, "not done");
    toast.error('Transaction Rejected', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  }
};



export const totalMintCount = async (contractAdd) => {
  if (isMetaMaskInstalled()) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const accounts = await provider.listAccounts();
    console.log(accounts,".>>>>>>>>>>>>>>>>>");
    const signer = provider.getSigner();
    const contractAddress = contractAdd;
    const nftContract = new ethers.Contract(contractAddress, contract, signer);
    let totalMint = await nftContract.count();
    // let c = await nftContract.getMyStruct();
    // console.log(c,"ghh");
    return totalMint;
  }
};

export const visit = async (contractAdd) => {
  try{
    if (isMetaMaskInstalled()) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const contractAddress = contractAdd;
      const nftContract = new ethers.Contract(contractAddress, contract, signer);
      let checkedIn = await nftContract.getTokenIdsandCheckIn(ethereum.selectedAddress);
      console.log(checkedIn,".........");
      return checkedIn;
    }
  }
  catch(error){
    console.log(error);
  }
  
};