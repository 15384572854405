import PropTypes, { element } from "prop-types"
import React, { useEffect, useState, useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Button,
  FormFeedback,
} from "reactstrap"
import "../../assets/scss/style.css"
import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"
import { dateformat, getUserDetail } from "admin/commonFunction"

import { withTranslation } from "react-i18next"

const NFTImage7 =
  "https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg"
import slash from "../../assets/images/slash_forward_icon_134959.png"

const ShowNFT = props => {
  //meta title
  document.title = "NFT Create"
  const history = useHistory()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [userInfo, setUserInfo] = useState("")
  const { loader, loading, setloading } = useContext(LoaderContext)
  const [categories, setCategories] = useState([])
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    category: "",
    price: "",
    externalLink: "",
    property: "",
  })
  const [itemThumb, setItemThumb] = useState("")
  const [itemMedia, setItemMedia] = useState("")
  const [listingData, setData] = useState([])
  const [property, setProperty] = useState([
    {
      name: "",
      sections: [],
    },
  ])

  // const changeProperty = event => {
  //     setFormValues({...formValues,[]})
  // }

  const changeValues = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: formValues.name,
      description: formValues.description,
      category: formValues.category,
      price: formValues.price,
      externalLink: formValues.externalLink,
      itemThumb: itemThumb,
      itemMedia: itemMedia,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required."),
      description: Yup.string().required("This field is required."),
      category: Yup.string().required("This field is required."),
      price: Yup.number().required("This field is required."),
      externalLink: Yup.string().required("This field is required."),
      itemThumb: Yup.string().required("This field is required."),
      itemMedia: Yup.string().required("This field is required."),
    }),
    onSubmit: values => {
      createNFT(values)
    },
  })
  function showLoader(param) {
    if (param) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }

  const createNFT = async data => {
    // Level
    var levelname = document.getElementsByClassName("levelname")
    var levelvalue = document.getElementsByClassName("levelvalue")
    var levelpercentage = document.getElementsByClassName("levelpercentage")

    var LevelRecord = []
    for (var i = 0; i < levelname.length; i++) {
      if (
        levelname[i].value != "" &&
        levelvalue[i].value != "" &&
        levelpercentage[i].value != ""
      ) {
        var record = {}
        record.levelname = levelname[i].value
        record.levelvalue = levelvalue[i].value
        record.levelpercentage = levelpercentage[i].value
        LevelRecord[i] = record
      }
    }

    // Stats
    var statsname = document.getElementsByClassName("statsname")
    var statsvalue = document.getElementsByClassName("statsvalue")
    var statspercentage = document.getElementsByClassName("statspercentage")

    var StatsRecord = []
    for (var i = 0; i < statsname.length; i++) {
      if (
        statsname[i].value != "" &&
        statsvalue[i].value != "" &&
        statspercentage[i].value != ""
      ) {
        var record = {}
        record.statsname = statsname[i].value
        record.statsvalue = statsvalue[i].value
        record.statspercentage = statspercentage[i].value
        StatsRecord[i] = record
      }
    }

    // features
    var properiestype = document.getElementsByClassName("properiestype")
    var propertiesname = document.getElementsByClassName("propertiesname")

    var PropertiesRecord = []
    for (var i = 0; i < properiestype.length; i++) {
      if (properiestype[i].value != "" && propertiesname[i].value != "") {
        var record = {}
        record.properiestype = properiestype[i].value
        record.propertiesname = propertiesname[i].value
        PropertiesRecord[i] = record
      }
    }

    // porperties
    var sectionCheckbox = document.getElementsByClassName("section-checkbox")

    var PropertiesRecord = []
    for (var i = 0; i < sectionCheckbox.length; i++) {
      var selectedObject = {};
      if(sectionCheckbox[i].checked){
          if(!PropertiesRecord[sectionCheckbox[i].getAttribute('dataid')]){
            PropertiesRecord[sectionCheckbox[i].getAttribute('dataid')] = [];
          }
          PropertiesRecord[sectionCheckbox[i].getAttribute('dataid')].push(sectionCheckbox[i].value)
      }
    }

    var propertiesDataReady = [];
    for (var key in PropertiesRecord){
      
        var propretiesDetails = {};
        propretiesDetails.placeId = key;
        propretiesDetails.sections = PropertiesRecord[key];

        propertiesDataReady.push(propretiesDetails)
    }


    // console.log(propertiesDataReady, 'propertiesDataReady >>>>>>>>>>>>>>>>>>')

    var formData = new FormData()
    formData.append("name", data.name)
    formData.append("description", data.description)
    formData.append("category", data.category)
    formData.append("price", data.price)
    formData.append("externalLink", data.externalLink)
    formData.append("level", JSON.stringify(LevelRecord))
    formData.append("properties", JSON.stringify(PropertiesRecord))
    formData.append("stats", JSON.stringify(StatsRecord))
    formData.append("itemThumb", data.itemThumb)
    formData.append("itemMedia", data.itemMedia)
    formData.append("active", true)
    formData.append("property", JSON.stringify(propertiesDataReady))
    setloading(true)

    await axios
      .post(apiRoute.nftCreate, formData)
      .then(response => {
        toast.success(response.data.message)
        setloading(false)
        history.push("/admin/nft/shows")
      })
      .catch(error => {
        setloading(false)
        toast.error(error.response.data.error)
      })
  }

  const inpRow = [{ name: "", file: "" }]
  const [inputFields, setinputFields] = useState(inpRow)

  const inpLevel = [{ name: "", value: "", div: "" }]
  const [inputLevel, setinputLevel] = useState(inpLevel)

  const inpStats = [{ name: "", value: "", div: "" }]
  const [inputStats, setinputStats] = useState(inpStats)

  // const inpProps = [{ property: "" }]
  // const [inputProps, setinputProps] = useState(inpProps)

  // Function for Create Input Fields
  function handleAddFields() {
    const item1 = { name: "", file: "" }
    setinputFields([...inputFields, item1])
  }
  // Function for Create Input Fields
  function handleAddFieldsLevel() {
    const item1 = { name: "", value: "", div: "" }
    setinputLevel([...inputLevel, item1])
  }
  // Function for Create Input Fields
  function handleAddFieldsStats() {
    const item1 = { name: "", value: "", div: "" }
    setinputStats([...inputStats, item1])
  }

  // Function for Create Input Fields
  // function handleAddFieldsProps() {
  //   const item1 = { property: "" }
  //   setinputProps([...inputProps, item1])
  // }

  // Function for Remove Input Fields
  function handleRemoveFields(idx) {
    document.getElementById("nested" + idx).style.display = "none"
  }
  // Function for Remove Input Fields
  function handleRemoveLevel(idx) {
    document.getElementById("nestedLevel" + idx).style.display = "none"
  }
  // Function for Remove Input Fields
  function handleRemoveStats(idx) {
    document.getElementById("nestedStats" + idx).style.display = "none"
  }

  // Function for Remove Input Props
  function handleRemoveProps(idx) {
    document.getElementById("nestedProps" + idx).style.display = "none"
  }

  const fetchData = () => {
    try {
      axios
        .get(apiRoute.categoriesListing)
        .then(res => {
          setCategories(res.data.data.category.items)
        })
        .catch(error => {
          console.log(error)
          toast.error("Something went wrong!")
        })
    } catch (err) {
      console.log(err)
    }
  }

  const [optionGroup, setOptionGroup] = useState([])
  const fetchProperties = () => {
    try {
      axios
        .get(apiRoute.getProperty)
        .then(res => {
          setData(res.data.data.property)

          var propertyOptionGroup = []
          res.data.data.property.map((value, index) => {
            var optionData = {}
            optionData.label = value.name
            optionData.value = value._id
            propertyOptionGroup.push(optionData)
          })
          setOptionGroup(propertyOptionGroup)
        })
        .catch(error => {
          console.log(error)
          toast.error("Something went wrong!")
        })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData()
    fetchProperties()
    setUserInfo(getUserDetail())
  }, [])

  const [customchkPrimary, setcustomchkPrimary] = useState(true);
  const [selectedProperties, setSelectedProperties] = useState([])
  const [selectedMulti, setselectedMulti] = useState(null)
  function handleMulti(selectedMulti) {

    setSelectedProperties(selectedMulti)
    setselectedMulti(selectedMulti)
  }


  


  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="validation-cls"
                  >
                    <CardTitle className="h5 text-color-gredient">Create NFT</CardTitle>
                    <p className="card-title-desc">
                      NFTs (Non-Fungible Tokens) are digital assets based on
                      blockchain technology.
                    </p>

                    <Row className="mb-4">
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <label htmlFor="floatingnameInput">Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="floatingnameInput"
                                placeholder="Writer here..."
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                                value={formValues.name}
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={12} className="d-none">
                            <div className="mb-3 mt-2">
                              <label htmlFor="floatingSelectGrid">
                                Select Collection
                              </label>
                              <select defaultValue="1" className="form-select">
                                <option value="1">The Litle Things</option>
                                <option value="2">Pixel Sweeper</option>
                                <option value="3">Mobox Avatar</option>
                                <option value="3">Metroverse Genesis</option>
                              </select>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3 mt-2">
                              <label htmlFor="floatingSelectGrid">
                                Category
                              </label>
                              <select
                                defaultValue="1"
                                name="category"
                                className="form-select"
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                              >
                                <option value=""> Select Category </option>
                                {categories
                                  ? categories.map((element, index) => {
                                      return (
                                        <option value={element._id} key={index}>
                                          {" "}
                                          {element.name}{" "}
                                        </option>
                                      )
                                    })
                                  : ""}
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="floatingnameInput1">
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="description"
                            id="floatingnameInput1"
                            rows={3}
                            placeholder="Writer here..."
                            style={{ height: "130px" }}
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.description}
                          ></textarea>
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="floatingnameInput">Price</label>
                          <input
                            type="number"
                            name="price"
                            className="form-control"
                            id="floatingnameInput"
                            placeholder="Writer here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.price}
                          />
                          {validation.touched.price &&
                          validation.errors.price ? (
                            <FormFeedback type="invalid">
                              {validation.errors.price}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="floatingnameInput">
                            External Link
                          </label>
                          <input
                            type="text"
                            name="externalLink"
                            className="form-control"
                            id="floatingnameInput"
                            placeholder="Writer here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            value={formValues.externalLink}
                          />
                          {validation.touched.externalLink &&
                          validation.errors.externalLink ? (
                            <FormFeedback type="invalid">
                              {validation.errors.externalLink}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6} className="mb-4">
                        <label htmlFor="floatingnameInput">Item Thumb</label>
                        <input
                          type="file"
                          name="itemThumb"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Writer here..."
                          onChange={event => {
                            setItemThumb(event.currentTarget.files[0])
                          }}
                        />
                        {validation.touched.itemThumb &&
                        validation.errors.itemThumb ? (
                          <FormFeedback type="invalid">
                            {validation.errors.itemThumb}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={6} className="mb-4">
                        <label htmlFor="floatingnameInput">Item Media</label>
                        <input
                          type="file"
                          name="itemMedia"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Writer here..."
                          onChange={event => {
                            setItemMedia(event.currentTarget.files[0])
                          }}
                        />
                        {validation.touched.itemMedia &&
                        validation.errors.itemMedia ? (
                          <FormFeedback type="invalid">
                            {validation.errors.itemMedia}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col md={6} className="mb-2 mt-3">
                        <label className="font-size-18">Level - </label>
                        <Button
                          color="success"
                          className="inner btn-sm ms-2 mb-1"
                          onClick={() => {
                            handleAddFieldsLevel()
                          }}
                        >
                          + Add Level
                        </Button>
                        {inputLevel.map((field, key) => (
                          <Row key={key} id={"nestedLevel" + key}>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control levelname"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col md={2} className="pe-0">
                              <div className="mb-3">
                                <label>Value</label>
                                <input
                                  type="text"
                                  className="form-control levelvalue"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col
                              md={1}
                              className="align-items-center text-center p-0"
                            >
                              <img
                                src={slash}
                                style={{ height: "33px", marginTop: "30px" }}
                              />
                            </Col>
                            <Col md={2} className="ps-0">
                              <div className="mb-3">
                                <label>Percentage</label>
                                <input
                                  type="text"
                                  className="form-control levelpercentage"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="mt-2 mt-md-0 d-grid">
                                <label className="invisible">delete</label>
                                <Button
                                  color="primary"
                                  className="inner"
                                  onClick={() => {
                                    handleRemoveLevel(key)
                                  }}
                                  block
                                >
                                  Delete
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>

                      <Col md={6} className="mb-2 mt-3">
                        <label className="font-size-18">Stats - </label>
                        <Button
                          color="success"
                          className="inner btn-sm ms-2 mb-1"
                          onClick={() => {
                            handleAddFieldsStats()
                          }}
                        >
                          + Add Stats
                        </Button>
                        {inputStats.map((field, key) => (
                          <Row key={key} id={"nestedStats" + key}>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control statsname"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col md={2} className="pe-0">
                              <div className="mb-3">
                                <label>Value</label>
                                <input
                                  type="text"
                                  className="form-control statsvalue"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col
                              md={1}
                              className="align-items-center text-center p-0"
                            >
                              <img
                                src={slash}
                                style={{ height: "33px", marginTop: "30px" }}
                              />
                            </Col>
                            <Col md={2} className="ps-0">
                              <div className="mb-3">
                                <label>Percentage</label>
                                <input
                                  type="text"
                                  className="form-control statspercentage"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="mt-2 mt-md-0 d-grid">
                                <label className="invisible">delete</label>
                                <Button
                                  color="primary"
                                  className="inner"
                                  onClick={() => {
                                    handleRemoveStats(key)
                                  }}
                                  block
                                >
                                  Delete
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>

                      <Col md={6} className="mt-4">
                        <label className="font-size-18">Features - </label>
                        <Button
                          color="success"
                          className="inner btn-sm ms-2 mb-1"
                          onClick={() => {
                            handleAddFields()
                          }}
                        >
                          + Add Features
                        </Button>

                        {inputFields.map((field, key) => (
                          <Row key={key} id={"nested" + key}>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Type</label>
                                <input
                                  type="text"
                                  className="form-control properiestype"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control propertiesname"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="mt-2 mt-md-0 d-grid">
                                <label className="invisible">delete</label>
                                <Button
                                  color="primary"
                                  className="inner"
                                  onClick={() => {
                                    handleRemoveFields(key)
                                  }}
                                  block
                                >
                                  Delete
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    
                      <Col md={6} className="mt-4">
                      <label className="font-size-18 mt-4">Properties </label>
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={e => {
                            handleMulti(e)
                          }}
                          options={optionGroup}
                          className="select2-selection"
                        />

                      {listingData.map((value, index) => {
                        return selectedProperties.map(
                          (selectValue, selectIndex) => {
                            if (value._id == selectValue.value) {
                              return (
                                <div className="mt-3" key={index}>
                                  <p> {selectValue.label} - Sections </p>
                                  {value.property_sections.map(
                                    (sectionValue, sectionIndex) => {
                                      // return <p key={sectionIndex+'section'}> {sectionValue.name} </p>
                                      return (
                                        <div
                                          className="form-check  form-check-inline mb-1 me-4"
                                          key={sectionIndex + "section"}
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input section-checkbox"
                                            id={selectIndex+''+sectionIndex + "section"}
                                            defaultChecked={0}
                                            value={sectionValue._id}
                                            dataId={selectValue.value}
                                          />

                                          <label
                                            className="form-check-label"
                                            htmlFor={selectIndex+''+sectionIndex + "section"}
                                          >
                                            {sectionValue.name}
                                          </label>
                                        </div>
                                      )
                                    }
                                  )}{" "}
                                </div>
                              )
                            }
                          }
                        )
                      })}
                      </Col>
                    </Row>

                    <div>
                      <Link
                        to="/admin/nft/shows"
                        className="btn btn-secondary w-md mt-2 me-2"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary w-md mt-2"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ShowNFT.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ShowNFT)
