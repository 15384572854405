import PropTypes, { element } from "prop-types"
import React, { useEffect, useState, useContext } from "react"
import { Link, useParams, useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  TabContent,
  NavItem,
  NavLink,
  CardText,
  Nav,
  TabPane,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Badge
} from "reactstrap"
import "../../../assets/scss/style.css"
import classnames from "classnames"

import noDataImage from "../../../assets/images/nodata-found.png"
import { dateformat } from "admin/commonFunction"
import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import { deploy } from "utils/deploy"
import { mint, totalMintCount, visit } from "../../../utils/web3mint"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"

import profile1 from "../../../assets/images/profile-img.png"

const profileImage =
  "https://dubai-attraction.com/wp-content/uploads/2021/12/ain-dubai-observation-wheel-550x550.jpg"

import { withTranslation } from "react-i18next"

const img1 =
  "https://pbs.twimg.com/media/Em8m0isXIAE0T2e?format=jpg&name=360x360"
const img2 =
  "https://www.visitdubai.com/-/media/gathercontent/poi/a/ain-dubai/fallback-image/ain-dubai-dtcm.jpg?&cw=256&ch=256"
const img3 = "https://images.otstatic.com/prod1/47767291/2/huge.jpg"
const img4 =
  "https://www.timeoutdubai.com/cloud/timeoutdubai/2022/02/16/Botanica-10-1024x768.jpg"
const img5 =
  "https://www.visitdubai.com/-/media/gathercontent/poi/t/the-pods-bluewaters-dubai/fallback-image/thepods2eventorganisermay2022jpg.jpg?rev=1b28dca5033c4ee0b4b80d52124aa6f4&cx=0.55&cy=0.61&cw=397&ch=397"
const img6 = "https://images.otstatic.com/prod1/47767289/2/huge.jpg"
const img7 =
  "https://www.poma.net/wp-content/uploads/2020/11/ain-dubai-capsules-galerie-1.jpg"
const img8 =
  "https://curlytales.com/wp-content/uploads/2022/03/Untitled-design-2022-03-25T122701.432-1170x614.jpg"
const img9 = "https://images.otstatic.com/prod1/47767289/2/huge.jpg"
const img10 =
  "https://cosmopoliclan.com/wp-content/uploads/2022/02/CosmopoliClan-Dubai-Dubai-ferris-wheel-Ain-Dubai-wheel-Dubai-Eye-wheel-Eye-Dubai-wheel-25-1024x768.jpg"
const img11 =
  "https://www.timeoutdubai.com/cloud/timeoutdubai/2022/04/29/KyYMsEXl-D29A8361-1200x800.jpg"

const NFTImage1 = "https://images.otstatic.com/prod1/47794124/3/huge.jpg"
const NFTImage2 =
  "https://dubaieyewheel.com/wp-content/uploads/2022/01/WhatsApp-Image-2022-01-13-at-12.02.43-AM-300x300.jpeg"
const NFTImage3 =
  "https://www.traveller.com.au/content/dam/images/h/2/1/0/x/2/image.related.socialLead.620x349.h210wq.png/1642123242787.jpg"
const NFTImage4 =
  "https://images.prismic.io/mystique/54b948bc-e603-47cc-a2b7-48085cb1bf0e_ain+dubai+1.jpg?auto=compress%2Cformat&w=1200&h=450&q=75&crop=faces&fm=webp&rect=0,0,797,498"
const NFTImage5 =
  "https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg"
const NFTImage6 =
  "https://media.npr.org/assets/img/2021/10/26/gettyimages-1194524615_sq-972b7802bd5a897861640c76900c982e71cbc818.jpg"
const NFTImage7 =
  "https://cdn.pixabay.com/photo/2020/12/13/23/18/ferris-wheel-5829622_1280.jpg"

const ShowNFT = props => {
  //meta title
  document.title = "NFT's Category"
  const history = useHistory()
  const BASE_PATH = process.env.REACT_APP_BASE_PATH
  const { account, connectMetawallet, setloading } = useContext(LoaderContext)
  const [userInfo, setUserInfo] = useState({ name: "" })
  const [resultData, setResultData] = useState({
    name: "",
    description: "",
    royalties: "",
    bannerImg: "",
    logoImg: "",
    nftlimit: "",
    base_url: "",
  })
  const [faqData, setFAQData] = useState([])
  const [settings_Menu, setsettings_Menu] = useState(false)
  const [remaining, setRemaining] = useState();
  const [message, setMessage] = useState("");
  const { id } = useParams();
  var nftCount = 0;

  let [num, setNum] = useState(0)
  let incNum = () => {
    if (num < resultData.useTime) {
      setNum(Number(num) + 1)
    }
  }
  let decNum = () => {
    if (num > 0) {
      setNum(num - 1)
    }
  }
  let handleChange = e => {
    if (e.target.value == "") {
      setNum(e.target.value)

    }
    else if (isNaN(e.target.value)) {

    } else if (e.target.value >= 1 && e.target.value <= 3) {

      setNum(e.target.value)

    }

  }

  const handleDateEvent = async event => {
    console.log(event.length)
    if (event.length == 2) {
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("3")
  const [activeTab, setactiveTab] = useState("3")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }
  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu)
  }

  const [transactions, setTransactions] = useState([]);
  const fetchData = () => {
    try {
      axios
        .get(apiRoute.collectionSingleDetail + "?collectionID=" + id)
        .then(res => {
          console.log(res, "resres >>>>>>>>>>>>>>>>>>>")
          setResultData(res.data.data.collection)
          setTransactions(res.data.data.transactions)
          setFAQData(res.data.data.collection.faq)
          setUserInfo({ name: res.data.data.userDetails.name })
        })
        .catch(error => {
          console.log(error)
          toast.error("Collection not found !")
        })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const deleteCollection = collection_id => {
    try {
      axios
        .get(apiRoute.collectionDelete + `?id=${collection_id}`)
        .then(res => {
          console.log(res)
          toast.success("Collection Deleted Successfully")

          history.push("/admin/nft/collections")
        })
    } catch (err) {
      console.log(err)
      toast.error("An error occurred !")
    }
  }
  const cccConfirmDelete = id => {
    // let URL = BASE_URL + 'user/delete/' + id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteCollection(id)
      }
    })
  }

  const contractDeploy = async () => {
    try {
      if (account) {
        const res = await deploy(
          resultData.name,
          resultData.name,
          resultData.base_url,
          resultData.nftlimit,
          resultData.useTime
        )
        if (res) {
          var formData = new FormData()
          formData.append("collectionID", id)
          formData.append("contractAdd", res.address)
          formData.append("deployed", true)

          await axios
            .put(apiRoute.collectionUpdate, formData)
            .then(response => {
              toast.success("successfully Deployed")
              window.location.reload();
            })
            .catch(error => {
              toast.error(error.response.data.error)
            })
        }
      } else {
        toast.warning("Please connect Wallet first!")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const mintNow = async () => {
    try {

      if (account) {

        if (num > 0 && num <= resultData.useTime) {
          setloading(true)
          let txn = await mint(num, resultData.ticket_price, setloading, resultData.contractAdd);
          setloading(false);
          console.log(txn, "txn");
          if (txn) {
            toast.success("Minted Successfully", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            calculateRemainingItems();
          }
        }

      } else {
        toast.warning("Please connect Wallet first!")
      }




    } catch (err) {
      setloading(false);
      console.log(err, "err");
    }
  };

  const calculateRemainingItems = async () => {
    let totaltMintedItems = await totalMintCount(resultData.contractAdd);
    setRemaining(parseInt(totaltMintedItems._hex, 16));
  };

  useEffect(() => {
    calculateRemainingItems();
  });

  const visitMetaverse = async () => {
    let visited = await visit(resultData.contractAdd);
    console.log(visited);
  }


  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copy to clipboard!")
  }

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card className="overflow-hidden border mb-2">
                <div className="bg-primary bg-soft">
                  <Row
                    className="category-background"
                    style={{
                      backgroundImage: `url(${BASE_PATH + resultData.bannerImg
                        })`,
                    }}
                  >
                    <Col xs="12" className="pb-3">
                      <div className="text-end p-4 mt-3">
                        <h4 className="text-white">
                          <strong
                            className="text-white"
                            style={{
                              textShadow: "2px 1px black",
                              background: "#0000009c",
                              padding: "0px 8px",
                            }}
                          >
                            {" "}
                            {resultData.name}{" "}
                          </strong>
                        </h4>
                        <p
                          className="text-white p-0 m-0"
                          style={{ textShadow: "2px 1px black" }}
                        >
                          <strong
                            className="text-warning"
                            style={{
                              background: "#0000009c",
                              padding: "0px 8px",
                            }}
                          >
                            By {userInfo.name.toUpperCase()}{" "}
                          </strong>{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="12">
                      <div
                        className="avatar-md profile-user-wid mb-4"
                        style={{
                          height: "8.5rem",
                          width: "8rem",
                          marginTop: "-93px",
                        }}
                      >
                        <img
                          src={BASE_PATH + resultData.logoImg}
                          className="img-thumbnail rounded-circle"
                          style={{ height: "130px", width: "130px" }}
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate fw-bold d-flex">
                        <div className="me-4">
                          {" "}
                          <span className="text-black">
                            {" "}
                            <i className="mdi mdi-view-dashboard"></i> Max NFT's :{" "}
                          </span>{" "}
                          {resultData.nftlimit}{" "}
                        </div>
                        <div className="ms-3 me-4">
                          {" "}
                          <span className="text-black">
                            {" "}
                            <i className="mdi mdi-view-dashboard"></i> Minted NFT's :{" "}
                          </span>{" "}
                          {remaining ? remaining : 0}{" "}
                        </div>
                        <div className="ms-3 me-4">
                          {" "}
                          <span className="text-black">
                            {" "}
                            <i className="mdi mdi-view-dashboard"></i> Created At:{" "}
                          </span>{" "}
                          {dateformat(resultData.createdAt)}{" "}
                        </div>
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        {resultData.description}
                      </p>

                      <div className="d-flex mt-4">
                        <div className="me-4">
                          <h5 className="font-size-14 mb-1"> Uses Time </h5>
                          <p className="text-black mb-0">{resultData.useTime}</p>
                        </div>
                        <div className="ms-4 me-4">
                          <h5 className="font-size-14 mb-1"> NFT Price </h5>
                          <p className="text-black mb-0">{resultData.ticket_price} ETH</p>
                        </div>
                        <div className="ms-4 me-4">
                          <h5 className="font-size-14 mb-1"> {(resultData?.date_format == "days") ? "Validity Days" : "Validity Period"} </h5>
                          <p className="text-black mb-0">{(resultData?.date_format == "days") ? resultData?.valid_period + " Days" : dateformat(resultData.startDateTime) + " - " + dateformat(resultData.endDateTime)}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl="12">
              <Card className="border">
                <CardBody className="">
                  <div className="row">
                    <Nav tabs className="nav-tabs-custom nav-justified col-4">
                      <Link
                        to="/admin/nft/collections"
                        className="btn btn-soft btn-secondary mx-4"
                      >
                        <i className="bx bx-arrow-back"></i> Back
                      </Link>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "3",
                          })}
                          onClick={() => {
                            toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block"> About </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Minted NFT's
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem style={{ display: "none" }}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Top NFT's</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <div
                      className="col-8 text-end conversation-list"
                      style={{
                        display: "flex",
                        textAlign: "end",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >

                      {/* <div className="dropdown d-inline-block">
                        <button
                          type="button"
                          className="btn btn-secondary me-2"
                          onClick={contractDeploy}
                          disabled={resultData.deployed ? true : false}
                        >
                          {resultData.deployed
                            ? "Contract Deployed"
                            : "Deploy Contract"}
                        </button>
                      </div> */}
                      <div className="btn-group">
                        {/* <Link to="/admin/nft/create" className="btn btn-soft btn-secondary">
                                                    <i className='bx bx-plus'></i> Create NFT
                                                </Link> */}
                        <Dropdown
                          isOpen={settings_Menu}
                          toggle={toggleSettings}
                          className="me-1"
                        >
                          <DropdownToggle
                            className="btn nav-btn btn-light rounded-0 rounded-end"
                            tag="a"
                          >
                            <i className="mdi mdi-cog mdi-17px pb-0" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link
                              className="dropdown-item"
                              to={`/admin/nft/collection/edit/${id}`}
                            >
                              {" "}
                              Edit{" "}
                            </Link>
                            <DropdownItem
                              href="#"
                              onClick={() => {
                                ConfirmDelete(id)
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="3">
                      <Row className="mt-4">
                        <Col xl={12}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={BASE_PATH + resultData.logoImg}
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "12px",
                                marginRight: "15px",
                              }}
                            />
                            <span className="text-black font-size-15 text-truncate fw-bold">
                              {" "}
                              {resultData.name}{" "}
                            </span>
                          </div>
                          <p className="mt-2 font-size-15">
                            {resultData.description}
                          </p>
                        </Col>
                        <Col xl={6} className="mt-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginBottom: "3px",
                            }}
                          >
                            <span> {remaining ? ((remaining / resultData.nftlimit) * 100).toFixed() : 0}% minted </span>
                            <span> {remaining ? remaining : 0} / {resultData.nftlimit} </span>
                          </div>
                          <div className="animated-progess progress-bar  mb-4">
                            <Progress
                              value={(remaining / resultData.nftlimit) * 100}
                              color="primary"
                              animated
                            ></Progress>
                          </div>
                          {
                            resultData.deployed ?
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "end"
                                }}
                                className="dropdown"
                              >
                                <p style={{ height: 'fit-content', paddingRight: 10, marginBottom: 0 }}>Limit {resultData.useTime} per wallet</p>
                                <div className="flex" style={{ marginRight: "10px" }} >
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <button className="btn btn-outline-primary" type="button" onClick={decNum}>-</button>
                                    </div>
                                    <input type="text" style={{ width: '36px' }} className="form-control" value={num} onChange={handleChange} />
                                    <div className="input-group-prepend">
                                      <button className="btn btn-outline-primary" type="button" onClick={incNum}>+</button>
                                    </div>
                                  </div>

                                </div>
                                <button type="button" className="btn btn-warning ml-5 " onClick={mintNow} disabled={resultData.deployed ? false : true}>
                                  Mint item
                                </button>
                                <button type="button" className="btn btn-warning ml-5" style={{ 'marginLeft': 5 }} onClick={visitMetaverse} disabled={resultData.deployed ? false : true}>
                                  Visit Metaverse
                                </button>
                              </div> :
                              ""
                          }


                        </Col>
                        <Col xl={12}>
                          <CardTitle className="mb-3 mt-5">
                            FAQ [ Frequently Asked Questions ]
                          </CardTitle>
                          {faqData.length == 0 ? (
                            <Col sm="12" className="text-center">
                              <img
                                src={noDataImage}
                                style={{ width: "505px" }}
                              />
                            </Col>
                          ) : (
                            faqData.map((element, index) => {
                              return (
                                <div
                                  className="faq-box d-flex mb-4"
                                  key={index}
                                >
                                  <div className="flex-shrink-0 me-3 faq-icon">
                                    <i className="bx bx-help-circle font-size-20 text-success" />
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-15">
                                      {element.question}
                                    </h5>
                                    <p className="text-muted">
                                      {element.answer}
                                    </p>
                                  </div>
                                </div>
                              )
                            })
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="1">
                      <Row className="">
                        {
                          (transactions.length == 0) ?
                            <Col sm="12" className="text-center">
                              <img src={noDataImage} style={{ width: "505px" }} />
                            </Col>
                            :
                            transactions && transactions.map((value, key) => {

                              return <Col xl="3" xs="6" className="mt-3 px-2" key={key}>
                                <Card>
                                  <div className="nft-category-image">
                                    <img
                                      className="card-img-top img-fluid rounded"
                                      src={BASE_PATH + resultData.ticket_image}
                                    />

                                    <div style={{
                                      position: "absolute",
                                      left: "10px",
                                      top: "10px",
                                    }} >
                                      <Badge className="bg-info border" style={{padding: '6px 8px', fontSize: '12px'}}> {value.nfts} </Badge>
                                    </div>
                                  </div>
                                  <div className="p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <b>{resultData.name} </b>
                                      <span>{value.value} ETH</span>
                                    </div>
                                    <span>

                                      {value.txhash && value.txhash.slice(0, 13)}.....{value.txhash && value.txhash.slice(
                                        value.txhash.length - 4,
                                        value.txhash.length
                                      )} &nbsp; <i className="mdi mdi-content-copy" onClick={() => handleCopy(value.txhash)} style={{ cursor: 'pointer' }}></i>
                                      &nbsp; <a href={`https://goerli.etherscan.io/tx/${value.txhash}`} target="_blank" rel="noreferrer"> <i className="mdi mdi-open-in-new ms-1"></i> </a>

                                    </span>
                                  </div>
                                </Card>
                              </Col>


                            })
                        }

                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={img1}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Origami Club </b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Vernon
                                  Royle{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={img10}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Neo Tokyo Citizens </b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> A. J.
                                  Webbe{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={img11}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Supercute World</b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Ted Peate{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={img2}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Krapopolis Krap </b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Maurice
                                  Read{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={img3}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Pixel Sweeper </b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Basil
                                  Grieve{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={img8}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Mobox Avatar Binance</b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Charles
                                  Coventry{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={img9}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>SPACE ID Badges</b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Frank Sugg{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={NFTImage7}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Geometric Gems</b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Bobby Abel{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col xl="2" xs="6" className="mt-3 px-2">
                          <Link to="/admin/nft/detail/1">
                            <Card>
                              <div className="nft-category-image">
                                <img
                                  className="card-img-top img-fluid rounded"
                                  src={NFTImage2}
                                />
                              </div>
                              <div className="p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <b>Keepers of the Inn</b>
                                  <b>$ 23.00</b>
                                </div>
                                <span>
                                  {" "}
                                  <i className="mdi mdi-account"></i> Johnny
                                  Briggs{" "}
                                </span>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ShowNFT.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ShowNFT)
