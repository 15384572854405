import PropTypes from "prop-types"
import React, { useEffect, useState, useContext } from "react"
import { Link, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Button,
  FormFeedback,
} from "reactstrap"
import "../../assets/scss/style.css"
import Select from "react-select";
import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import { LoaderContext } from "context/ContextProvider"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"
import moment from 'moment'

import { withTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { exists } from "i18next"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import GoogleMaps from './map';
import { deploy } from "utils/deploy"

const places = props => {
  //meta title
  document.title = "Create Property"
  const history = useHistory()
  const BASE_PATH = process.env.REACT_APP_BASE_PATH;
  const { loader, loading, setloading } = useContext(LoaderContext)
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    propertyId: "",
    propertyType: "",
    collection_id: "",
    collection_image: "",
    collection_banner_image: "", nftlimit: '', collection_type: '', no_uses: '', start_date: '', end_date: '', time_period: '', category_id: '', ticket_price: '',select_period:'30'
  })
 
  const [ticketImage, setticket_image] = useState("")
  const [propertyImg, setPropertyImg] = useState("")
  const [mapview, setMapView] = useState("")
  const [galleryImg, setGalleryImg] = useState([])
  const [selectPeriod, setSelectPeriod] = useState('fix_date');
  
  const [LatLong, setLatLong] = useState({ latitude: 25.079944630125194, longitude: 55.12245170750407 })

  const changeValues = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: formValues.name,
      description: formValues.description,
      propertyId: formValues.propertyId,
      collection_id: formValues.collection_id,
      propertyType: formValues.propertyType,

      nftlimit: formValues.nftlimit,
      collection_type: formValues.collection_type,
      no_uses: formValues.no_uses,
      time_period: formValues.time_period,
      category_id: formValues.category_id,
      ticket_price: formValues.ticket_price,
      ticket_image: ticketImage,
      select_dateformat:selectPeriod,
      select_period:formValues.select_period,

      collection_image: formValues.collection_image,
      collection_banner_image: formValues.collection_banner_image,
      propertyImg: propertyImg,
      galleryImg: galleryImg,
      mapView: mapview,
      
      latitude: LatLong.latitude,
      longitude: LatLong.longitude,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required."),
      description: Yup.string().required("This field is required."),
      propertyId: Yup.number().required("This field is required."),
      propertyType: Yup.string().required("This field is required."),
      latitude: Yup.string().required("This field is required."),
      longitude: Yup.string().required("This field is required."),
    }),
    onSubmit: values => {
      createCollectionFun(values)
      console.log(values, "hgh")
    },
  })

  const createCollectionFun = async data => {

    console.log(data, 'data >>>>>>>>>>>>>>>>>>>>>>')

    if (collectionStatus) {
      // Property Detail with Collection Detail

      if (data.nftlimit == '' && data.collection_type == '' && data.no_uses == '' && data.time_period && data.category_id && data.ticket_price && data.ticket_image) {
        toast.error('Collection fields are required!');
        return;
      }

      console.log(data.time_period, 'time_period >>>>>>>>>>>>>')

      var StartEndDateTime = data.time_period.split(" / ");
      var questionElements = document.getElementsByClassName("question-cls");
      var answerElements = document.getElementsByClassName("answer-cls");

      console.log(StartEndDateTime, 'StartEndDateTime >>>>>>>>>>>>>')

      var FAQrecord = [];
      for (var i = 0; i < questionElements.length; i++) {
        if (questionElements[i].value != '' && answerElements[i].value != '') {
          var record = {};
          record.question = questionElements[i].value;
          record.answer = answerElements[i].value;
          FAQrecord[i] = record;
        }
      }

      console.log(Object.keys(data.galleryImg).length, 'data >>>>>>>>>>>>>>>>>>')

      var nameElements = document.getElementsByClassName("name-cls")
      var sectionIdElements = document.getElementsByClassName("section-cls")

      var SECrecord = []
      var sectionUniqueID = []
      var uniqueErr = "";
      for (var i = 0; i < nameElements.length; i++) {
        if (nameElements[i].value != "" && sectionIdElements[i].value != "") {
          for (let j = 0; j < sectionUniqueID.length; j++) {
            if (sectionIdElements[i].value == sectionUniqueID[j]) {
              uniqueErr = 'The section id must be unique.';
              break;
            }
          }
          if (uniqueErr != '') {
            toast.error(uniqueErr)
            break;
          }
          sectionUniqueID[i] = sectionIdElements[i].value

          var record = {}
          record.name = nameElements[i].value
          record.sectionId = sectionIdElements[i].value
          SECrecord[i] = record
        }
      }
      if (uniqueErr != '') {
        return;
      }

      // Form Collection
      var formData = new FormData()

      formData.append("description", data.description)
      formData.append("propertyId", data.propertyId)
      // formData.append("collectionId", data.collection_id)

      formData.append("categoryId", data.category_id)
      formData.append("name", data.name)
      formData.append("propertyType", data.propertyType)
      formData.append("propertyImg", data.propertyImg)
      formData.append("mapView", data.mapView)
      formData.append("ticket_image", data.ticket_image)
      formData.append('ticket_price', data.ticket_price)
      formData.append("date_format", data.select_dateformat)
      formData.append("valid_period", data.select_period)

      formData.append('latitude', data.latitude)
      formData.append('longitude', data.longitude)

      // formData.append("collection_banner_image", data.collection_banner_image)
      // formData.append("collection_image", data.collection_image)
      formData.append("section", JSON.stringify(SECrecord))

      formData.append('royalties', 0)
      formData.append('nftlimit', data.nftlimit)
      formData.append('collectionType', data.collection_type)
      formData.append('startDateTime', StartEndDateTime[0])
      formData.append('endDateTime', StartEndDateTime[1])
      formData.append('useTime', data.no_uses)
      formData.append('faq', JSON.stringify(FAQrecord))

      for (let index = 0; index < Object.keys(data.galleryImg).length; index++) {
        formData.append("multiMedia", data.galleryImg[index])
      }
      let base_url = `https://universe-eye-apis.block-brew.com/uploads/${data.name}/json/`;
      if(data.no_uses == 0){
        var useTime = 100000
      }else{
        useTime = data.no_uses;
      }

      if(data.select_dateformat == 'days'){
        var valid_period = data.select_period*24*60*60;
       //  var valid_period = 180;
        var membership = true
     }else{
        const date =new Date(StartEndDateTime[1]);
         valid_period = Math.floor(date.getTime() / 1000);
        console.log(valid_period);
         membership = false
     }
  
     console.log(valid_period,membership);
      setloading(true)
      const res = await deploy(
        data.name,
        data.name,
        base_url,
        data.nftlimit,
        data.ticket_price,
        useTime,
        valid_period,
        membership,
        setloading 
      )


      // // Api Call
      if (res) {
      await axios
        .post(apiRoute.propertyWithCollection, formData)
        .then(async (response) => {
          console.log(response);
          var formData1 = new FormData()
          formData1.append("collectionID", response.data.data.collection._id)
          formData1.append("contractAdd",res.address)
          formData1.append("deployed", true)
          await axios
            .put(apiRoute.collectionUpdate, formData1)
            .then(_response => {
              toast.success("Successfully Deployed")
            })
            .catch(error => {
              setloading(false)
              toast.error(error._response.data.error)
            })

          setloading(false)
          toast.success(response.data.message)
          history.push("/admin/properties")
        })
        .catch(error => {
          setloading(false)
          toast.error(error.response.data.error)
        })
      }

    } else {
      // Property Detail and Select Collection From Listing

      console.log(Object.keys(data.galleryImg).length, 'data >>>>>>>>>>>>>>>>>>')

      var nameElements = document.getElementsByClassName("name-cls")
      var sectionIdElements = document.getElementsByClassName("section-cls")

      var SECrecord = []
      var sectionUniqueID = []
      var uniqueErr = "";
      for (var i = 0; i < nameElements.length; i++) {
        if (nameElements[i].value != "" && sectionIdElements[i].value != "") {
          for (let j = 0; j < sectionUniqueID.length; j++) {
            if (sectionIdElements[i].value == sectionUniqueID[j]) {
              uniqueErr = 'The section id must be unique.';
              break;
            }
          }
          if (uniqueErr != '') {
            toast.error(uniqueErr)
            break;
          }
          sectionUniqueID[i] = sectionIdElements[i].value

          var record = {}
          record.name = nameElements[i].value
          record.sectionId = sectionIdElements[i].value
          SECrecord[i] = record
        }
      }
      if (uniqueErr != '') {
        return;
      }

      // Form Collection
      var formData = new FormData()

      formData.append("description", data.description)
      formData.append("propertyId", data.propertyId)
      formData.append("collectionId", data.collection_id)
      formData.append("name", data.name)
      formData.append("buildingType", data.propertyType)
      formData.append("propertyImg", data.propertyImg)
      formData.append("mapView", data.mapView)
      
      formData.append('latitude', data.latitude)
      formData.append('longitude', data.longitude)

      // formData.append("multiMedia", data.galleryImg)
      formData.append("collection_banner_image", data.collection_banner_image)
      formData.append("collection_image", data.collection_image)
      formData.append("section", JSON.stringify(SECrecord))

      for (let index = 0; index < Object.keys(data.galleryImg).length; index++) {
        formData.append("multiMedia", data.galleryImg[index])
      }


      // Api Call
      setloading(true)
      await axios
        .post(apiRoute.createProperty, formData)
        .then(response => {
          setloading(false)
          toast.success(response.data.message)
          history.push("/admin/properties")
        })
        .catch(error => {
          setloading(false)
          toast.error(error.response.data.error)
        })


    }


  }

  const inpLevel = [{ name: "", sectionId: "" }]
  const [inputLevel, setinputLevel] = useState(inpLevel)

  function handleAddFieldsSection() {
    const item1 = { name: "", sectionId: "" }
    setinputLevel([...inputLevel, item1])
  }

  function handleRemoveLevel(idx) {
    document.getElementById("nestedLevel" + idx).remove()
  }


  const [listCollection, setListCollection] = useState([])
  const [optionCollectionGroup, setOptionCollectionGroup] = useState([])
  const fetchCollections = () => {
    try {
      axios
        .get(apiRoute.collectionListing)
        .then(res => {

          setListCollection(res.data.data.collection)

          var propertyOptionGroup = []
          res.data.data.collection.map((value, index) => {
            var optionData = {}
            optionData.label = value.name
            optionData.value = value._id
            propertyOptionGroup.push(optionData)
          })
          setOptionCollectionGroup(propertyOptionGroup)
        })
        .catch(error => {
          console.log(error)
          toast.error("Something went wrong!")
        })
    } catch (err) {
      console.log(err)
    }
  }

  const [listCategories, setListCategories] = useState([])
  const [optionCategoriesGroup, setOptionCategoriesGroup] = useState([])
  const fetchCategories = () => {
    try {
      axios
        .get(apiRoute.categoriesListing)
        .then(res => {

          setListCategories(res.data.data.category.items)

          var propertyOptionGroup = []
          res.data.data.category.items.map((value, index) => {
            var optionData = {}
            optionData.label = value.name
            optionData.value = value._id
            propertyOptionGroup.push(optionData)
          })
          setOptionCategoriesGroup(propertyOptionGroup)
        })
        .catch(error => {
          console.log(error)
          toast.error("Something went wrong!")
        })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchCollections()
    fetchCategories()
  }, [])

  const [selectedCollectionMulti, setselectedCollectionMulti] = useState(null)
  function handleCollectionMultiRemove() {
    setselectedCollectionMulti(null)

    setFormValues({
      name: "",
      description: "",
      collection_image: "",
      collection_banner_image: "",
      propertyId: formValues.propertyId,
      propertyType: formValues.propertyType,
      collection_id: "",
    })
  }
  function handleCollectionMulti(selectedCollectionMulti) {

    console.log(selectedCollectionMulti, 'selectedCollectionMulti selectedCollectionMulti >>>>>>>>>')

    setselectedCollectionMulti(selectedCollectionMulti)

    if (listCollection) {
      listCollection.map((value, index) => {
        if (value._id == selectedCollectionMulti.value) {
          setFormValues({
            name: value.name,
            description: value.description,
            collection_image: value.logoImg,
            collection_banner_image: value.bannerImg,
            propertyId: formValues.propertyId,
            propertyType: formValues.propertyType,
            collection_id: selectedCollectionMulti.value,
          })
        }
      })
    }
  }

  // Collection Module 

  const [collectionStatus, setCollectionStatus] = useState(false);

  const inpLevelFAQ2 = [{ question: "", answer: "" }]
  const [inputLevelFAQ, setinputLevelFAQ] = useState(inpLevelFAQ2)

  function handleAddFieldsFAQ() {
    const inpLevelFAQ1 = { question: "", answer: "" }
    setinputLevelFAQ([...inputLevelFAQ, inpLevelFAQ1])
  }

  function handleRemoveLevelFAQ(idx) {
    document.getElementById("nestedLevel" + idx).remove()
  }


  const handleDateEvent = async (event) => {
    console.log(event.length)
    if (event.length == 2) {
      let filterDate = [];
      filterDate[0] = moment(event[0]).format('YYYY-MM-DD');
      filterDate[1] = moment(event[1]).format('YYYY-MM-DD');

      setFormValues({ ...formValues, ['time_period']: filterDate[0] + ' / ' + filterDate[1] })

    } else {
      setFormValues({ ...formValues, ['time_period']: "" })
    }
  }


  // Categories
  const [selectedCategories, setSelectedCategories] = useState(null)
  function handleCategoriesRemove(selectedCollectionMulti) {
    setSelectedCategories(null)
    setFormValues({ ...formValues, ['category_id']: '' })
  }
  // console.log(f,"mko");
  function handleCategories(event) {
    setSelectedCategories(event)
    setFormValues({ ...formValues, ['category_id']: event.value })
  }

  const handleChangeLocation = (event) => {
    console.log(event, 'map event >>>>>>>>>>>>>>>>>>>>>')
    setLatLong({ latitude: event.lat, longitude: event.lng })
  };

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="validation-cls"
                  >


                    <Row className="">
                      <Col md={6}>
                        <CardTitle className="h5 mb-4 text-color-gredient">Create Property</CardTitle>
                      </Col>
                      <Col md={6} className="text-end">
                        {
                          (!collectionStatus) ? <button type="button" className="btn btn-primary" onClick={(event) => setCollectionStatus(true)}> + Add Collection </button>
                            : <button type="button" className="btn btn-primary" onClick={(event) => setCollectionStatus(false)}> Remove Collection </button>
                        }
                      </Col>


                      {
                        (collectionStatus) ?
                          <>

                            <Col md={4}>
                              <label htmlFor="floatingnameInput">Select Category  </label>
                              <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select value={selectedCategories} isMulti={false} options={optionCategoriesGroup} className="select2-selection"
                                  onChange={e => {
                                    handleCategories(e)
                                  }}
                                  onKeyDown={e => {
                                    handleCategoriesRemove(e)
                                  }}
                                />
                                {validation.touched.category_id && validation.errors.category_id ? (
                                  <FormFeedback type="invalid"> {validation.errors.category_id}  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="floatingnameInput">Number of NFT</label>
                                <input type="number" name="nftlimit" className="form-control" id="floatingnameInput" placeholder="Enter Limit..." onBlur={validation.handleBlur} onChange={changeValues} value={formValues.nftlimit} />
                                {validation.touched.nftlimit && validation.errors.nftlimit ? (
                                  <FormFeedback type="invalid">{validation.errors.nftlimit}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="">
                                <label htmlFor="floatingnameInput"> Pass or Membership </label>
                                <select
                                  defaultValue="0"
                                  name="collection_type"
                                  className="form-select"
                                  onBlur={validation.handleBlur}
                                  onChange={changeValues}
                                >
                                  <option value=""> Select... </option>
                                  <option value="event"> Pass </option>
                                  <option value="membership"> Membership </option>
                                </select>
                                {validation.touched.collection_type && validation.errors.collection_type ? (
                                  <FormFeedback type="invalid">{validation.errors.collection_type}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={3} className="mb-3 mt-2">
                        <label htmlFor="floatingnameInput">
                          Select Date Format
                        </label>
                        <select
                          defaultValue={selectPeriod}
                          name="select_dateformat"
                          className="form-select"
                          onBlur={validation.handleBlur}
                          onChange={(event) => setSelectPeriod(event.target.value)}
                        >
                          <option value="days"> Days Period </option>
                          <option value="fix_date"> Fix DateTime </option>
                        </select>

                        {validation.touched.select_dateformat &&
                          validation.errors.select_dateformat ? (
                          <FormFeedback type="invalid">
                            {validation.errors.select_dateformat}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      {
                        (selectPeriod == "days") ?

                          <Col md={3} className="mb-3 mt-2">
                            <label htmlFor="floatingnameInput">
                              Select Period
                            </label>
                            <select
                              defaultValue="30"
                              name="select_period"
                              className="form-select"
                              onBlur={validation.handleBlur}
                              onChange={changeValues}
                            >
                              <option value=""> Select... </option>
                              <option value="30"> 1 Month </option>
                              <option value="90"> 3 Months </option>
                              <option value="180"> 6 Months </option>
                              <option value="365"> 1 Year </option>
                            </select>

                            {validation.touched.select_period &&
                              validation.errors.select_period ? (
                              <FormFeedback type="invalid">
                                {validation.errors.select_period}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          :

                            <Col md={3}>
                              <div className="mb-3 mt-2">
                                <label htmlFor="floatingnameInput">Validity Period</label>
                                <Flatpickr className="form-control d-block" style={{ marginRight: '8px' }} onChange={(date) => handleDateEvent(date)} placeholder="Select Dates" options={{ mode: "range", dateFormat: "Y-m-d" }} />
                                {validation.touched.time_period && validation.errors.time_period ? (
                                  <FormFeedback type="invalid">{validation.errors.time_period}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                      }
                            <Col md={3}>
                              <div className="mb-3 mt-2">
                                <label htmlFor="floatingnameInput">Number of Uses <span className="font-size-11 text-secondary"> [ Put 0 to enter unlimited times ] </span> </label>
                                <input type="number" name="no_uses" className="form-control" id="floatingnameInput" placeholder="Enter Number..." onBlur={validation.handleBlur} onChange={changeValues} value={formValues.no_uses} />
                                {validation.touched.no_uses && validation.errors.no_uses ? (
                                  <FormFeedback type="invalid">{validation.errors.no_uses}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3 mt-2">
                                <label htmlFor="floatingnameInput">Ticket Image</label>
                                <input
                                  type="file"
                                  name="ticket_image"
                                  className="form-control"
                                  id="floatingnameInput"
                                  placeholder="Upload map Image"
                                  onChange={event => {
                                    setticket_image(event.currentTarget.files[0])
                                  }}
                                />
                                {validation.touched.ticket_image &&
                                  validation.errors.ticket_image ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.ticket_image}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3 mt-2">
                                <label htmlFor="floatingnameInput"> Ticket Price </label>
                                <input type="number" name="ticket_price" className="form-control" id="floatingnameInput" placeholder="Enter Number..." onBlur={validation.handleBlur} onChange={changeValues} value={formValues.ticket_price} />
                                {validation.touched.ticket_price && validation.errors.ticket_price ? (
                                  <FormFeedback type="invalid">{validation.errors.ticket_price}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>


                            <Col md={12} className="mb-2 mt-3">
                              <label className="font-size-18">FAQ -  </label>
                              <Button
                                color="success"
                                className="inner btn-sm ms-2 mb-1"
                                onClick={() => {
                                  handleAddFieldsFAQ()
                                }}
                              >
                                + Add FAQ
                              </Button>
                              {inputLevelFAQ.map((field, key) => (
                                <Row
                                  key={key}
                                  id={"nestedLevel" + key}
                                >

                                  <Col md={10}>
                                    <div className="mb-3">
                                      <label>- Question</label>
                                      <input type="text" name="question[]" className="form-control question-cls" placeholder="Writer here..." />
                                    </div>
                                  </Col>

                                  <Col md={2}>
                                    <div className="mt-2 mt-md-0 d-grid">
                                      <label className="invisible">delete</label>
                                      <Button color="primary" className="inner"
                                        onClick={() => {
                                          handleRemoveLevelFAQ(key)
                                        }}
                                        block
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </Col>
                                  <Col md={12} className="pe-0">
                                    <div className="mb-3">
                                      <label>- Answer</label>
                                      <textarea name="answer[]" className="form-control answer-cls" id="floatingnameInput1" rows={2} placeholder="Enter Description..." style={{ height: '90px' }}></textarea>
                                    </div>
                                  </Col>
                                </Row>
                              ))}

                            </Col>
                            <Col md={12} className="mb-4">
                              <i className="mdi mdi-asterisk" style={{ position: 'absolute', top: '7px', left: '20%' }}></i>
                              <i className="mdi mdi-asterisk" style={{ position: 'absolute', top: '7px', left: '40%' }}></i>
                              <i className="mdi mdi-asterisk" style={{ position: 'absolute', top: '7px', left: '60%' }}></i>
                              <i className="mdi mdi-asterisk" style={{ position: 'absolute', top: '7px', left: '80%' }}></i>
                              <hr style={{ height: '1px', backgroundColor: 'gray' }} />
                            </Col>

                          </>
                          : ''
                      }

                    </Row>
                    <Row className="">
                      <Col md={6}>
                        <Row>
                          {
                            (!collectionStatus) ?
                              <Col md={12}>
                                <label htmlFor="floatingnameInput">Select Collection <span className="font-size-11 text-secondary"> [ Do you want to link this property to a collection? ] </span> </label>
                                <div className="form-floating mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <Select value={selectedCollectionMulti} isMulti={false} options={optionCollectionGroup} className="select2-selection"
                                    onKeyDown={e => {
                                      handleCollectionMultiRemove()
                                    }}
                                    onChange={e => {
                                      handleCollectionMulti(e)
                                    }}
                                  />
                                  {validation.touched.collection_id && validation.errors.collection_id ? (
                                    <FormFeedback type="invalid"> {validation.errors.collection_id}  </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              : ''
                          }

                          <Col md={12}>
                            <div className="mb-3">
                              <label htmlFor="floatingnameInput">Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="floatingnameInput"
                                placeholder="Enter Property Name"
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                                value={formValues.name}
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <label htmlFor="floatingnameInput1">
                                Property ID
                              </label>
                              <input
                                type="text"
                                name="propertyId"
                                className="form-control"
                                id="floatingnameInput"
                                placeholder="Enter PropertyId"
                                onBlur={validation.handleBlur}
                                onChange={changeValues}
                                value={formValues.propertyId}
                              />
                              {validation.touched.propertyId &&
                                validation.errors.propertyId ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.propertyId}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>


                          <Col md={(formValues.collection_id == '') ? 12 : 10} className="mb-3">
                            <label htmlFor="floatingnameInput">
                              Property Icon
                            </label>
                            <input
                              type="file"
                              name="propertyImg"
                              className="form-control"
                              id="floatingnameInput"
                              placeholder="Upload Property Image"
                              onChange={event => {
                                setPropertyImg(event.currentTarget.files[0])
                              }}
                            />
                            {validation.touched.propertyImg &&
                              validation.errors.propertyImg ? (
                              <FormFeedback type="invalid">
                                {validation.errors.propertyImg}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          {
                            (formValues.collection_id != '') ?

                              <Col md={2} className="mb-3 overflow-hidden">
                                <img
                                  src={BASE_PATH + formValues.collection_image}
                                  style={{ height: "80px" }}
                                />
                              </Col>

                              : ''
                          }

                          <Col md={(formValues.collection_id == '') ? 12 : 10} className="mb-3">
                            <label htmlFor="floatingnameInput">Banner Image</label>
                            <input
                              type="file"
                              name="mapView"
                              className="form-control"
                              id="floatingnameInput"
                              placeholder="Upload map Image"
                              onChange={event => {
                                setMapView(event.currentTarget.files[0])
                              }}
                            />
                            {validation.touched.propertyImg &&
                              validation.errors.propertyImg ? (
                              <FormFeedback type="invalid">
                                {validation.errors.propertyImg}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          {
                            (formValues.collection_id != '') ?

                              <Col md={2} className="mb-3 overflow-hidden">
                                <img
                                  src={BASE_PATH + formValues.collection_banner_image}
                                  style={{ height: "80px" }}
                                />
                              </Col>

                              : ''
                          }

                          <Col md={12} className="mb-3">
                            <label htmlFor="floatingnameInput">Gallery Image</label>
                            <input
                              type="file"
                              name="mapView"
                              className="form-control"
                              id="floatingnameInput"
                              placeholder="Upload map Image"
                              multiple="muliple"
                              onChange={event => {
                                setGalleryImg(event.currentTarget.files)
                              }}
                            />
                            {validation.touched.propertyImg &&
                              validation.errors.propertyImg ? (
                              <FormFeedback type="invalid">
                                {validation.errors.propertyImg}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col md={12} className="mb-3">
                            <label htmlFor="floatingnameInput">Property Type</label>
                            <select
                              name="propertyType"
                              className="form-select"
                              onBlur={validation.handleBlur}
                              onChange={changeValues}
                            >
                              <option value="">Select Property Type</option>
                              <option value="Residential">Residential </option>
                              <option value="Business">Business</option>
                              <option value="Commercial">Commercial</option>
                              <option value="Party Hall">Party Hall</option>
                            </select>
                            {validation.touched.propertyType &&
                              validation.errors.propertyType ? (
                              <FormFeedback type="invalid">
                                {validation.errors.propertyType}
                              </FormFeedback>
                            ) : null}
                          </Col>

                        </Row>
                      </Col>

                      <Col md={6}>
                        <Row>

                          <Col md={12}>
                            <div className="mb-2">
                              <label htmlFor="floatingnameInput1">Description</label>
                              {
                                (!collectionStatus) ? <textarea name="description" className="form-control" id="floatingnameInput1" rows={3} placeholder="Enter Description..." style={{ height: '177px' }} onBlur={validation.handleBlur} onChange={changeValues} value={formValues.description}></textarea>
                                  :
                                  <textarea name="description" className="form-control" id="floatingnameInput1" rows={3} placeholder="Enter Description..." style={{ height: '120px' }} onBlur={validation.handleBlur} onChange={changeValues} value={formValues.description}></textarea>
                              }


                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>


                          <GoogleMaps latitude={LatLong.latitude} longitude={LatLong.longitude} changeFunction={handleChangeLocation} />

                        </Row>
                      </Col>


                      <Col md={12} className="mb-2 mt-3">
                        <label className="font-size-18">Sections in Property - </label>
                        <Button
                          color="success"
                          className="inner btn-sm ms-2 mb-1"
                          onClick={() => {
                            handleAddFieldsSection()
                          }}
                        >
                          + Add Section
                        </Button>
                        {inputLevel.map((field, key) => (
                          <Row key={key} id={"nestedLevel" + key}>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Name</label>
                                <input
                                  type="text"
                                  name="name[]"
                                  className="form-control name-cls"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="mb-3">
                                <label>Section ID</label>
                                <input
                                  type="text"
                                  name="sectionId[]"
                                  className="form-control section-cls"
                                  placeholder="Writer here..."
                                />
                              </div>
                            </Col>

                            <Col md={2}>
                              <div className="mt-2 mt-md-0 d-grid">
                                <label className="invisible">delete</label>
                                <Button
                                  color="primary"
                                  className="inner"
                                  onClick={() => {
                                    handleRemoveLevel(key)
                                  }}
                                  block
                                >
                                  Delete
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>

                    </Row>

                    <div>
                      <Link
                        to="/admin/nft/collections"
                        className="btn btn-secondary w-md mt-2 me-2"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary w-md mt-2"
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

places.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(places)
