import React from "react"
import { Redirect } from "react-router-dom"


// // Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "pages/Authentication/ResetPassword"


// Admin Dashboard
import AdminUserDashboard from "../admin/user_dashboard/index"
import AdminDashboard from "../admin/dashboard/index"
import AdminChangePassword from "../admin/account/index"
import AdminTransaction from "../admin/transactions/index"
import AdminUsers from "../admin/users/index"
import AdminVendors from "../admin/users/vendor"
import AdminRevenue from "../admin/revenue/index"
import Faq from "../admin/faq/Faq"

// CSM Pages
import AdminCSMpages from "../admin/csm/index"
import AdminSocialLinks from "../admin/social/index"

// Public CSM Pages
import PublicCSMpages from "../admin/csm/public"

// NFT Management
import AdminNFTDashbaord from "../admin/nft/dashboard/index"
import AdminNFTCreate from "../admin/nft/create"
import AdminNFTShows from "../admin/nft/shows/index"
import AdminNFTEdit from "../admin/nft/shows/edit"
import AdminNFTCollectionDetail from "../admin/nft/collections/detail"
import AdminNFTCollections from "../admin/nft/collections/index"
import AdminNFTDetail from "../admin/nft/shows/detail"
import AdminNFTCategoryCreate from "../admin/nft/collections/create"
import AdminNFTCollectionEdit from "../admin/nft/collections/edit"
import AdminNFTCategories from "../admin/nft/categories/index"
import AdminUserEdit from "../admin/users/edit"

// Event Management
import AdminEventCreate from "../admin/events/create"
import AdminEventShow from "../admin/events/show"
import AdminEventDetail from "../admin/events/detail"
import AdminEventEdit from "../admin/events/edit"

import AdminEventRequest from "../admin/events/request"

// Places
import AdminPlaces from "../admin/places/index"
import AdminPlaceCreate from "../admin/places/create"
import AdminPlaceEdit from "../admin/places/edit"

// Support
import AdminSupportRequests from "../admin/support/index"

import DummyScreen from "../admin/testing/index"

const authProtectedRoutes = [
  
  { path: "/dummy", component: DummyScreen },

  // Admin Routes
  { path: "/dashboard", component: AdminDashboard },
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/change-password", component: AdminChangePassword },
  { path: "/admin/csm-pages", component: AdminCSMpages },
  { path: "/admin/transactions", component: AdminTransaction },
  { path: "/admin/users", component: AdminUsers },
  { path: "/admin/vendors", component: AdminVendors },
  { path: "/admin/revenue", component: AdminRevenue },

  // NFT Module
  { path: "/admin/nft/create", component: AdminNFTCreate },
  { path: "/admin/nft/shows", component: AdminNFTShows },
  { path: "/admin/nft/edit/:id", component: AdminNFTEdit },
  { path: "/admin/nft/collection/:id", component: AdminNFTCollectionDetail },
  { path: "/admin/nft/collection/edit/:collectionID", component: AdminNFTCollectionEdit },
  { path: "/admin/nft/collections", component: AdminNFTCollections },
  { path: "/admin/nft/detail/:id", component: AdminNFTDetail },
  { path: "/admin/nft/collections/create", component: AdminNFTCategoryCreate },
  { path: "/admin/nft/categories", component: AdminNFTCategories },

  // Public Pages
  { path: "/admin/faqs", component: Faq},
  { path: "/admin/user/edit/:userID",component:AdminUserEdit},
  { path: "/admin/social-links",component:AdminSocialLinks},

  // Events
  { path: "/admin/event/create", component: AdminEventCreate },
  { path: "/admin/event/shows", component: AdminEventShow },
  { path: "/admin/event/detail/:id", component: AdminEventDetail },
  { path: "/admin/event/edit/:id", component: AdminEventEdit },
  
  // Event Request
  { path: "/admin/event/requests", component: AdminEventRequest },

  // NFT Dashboard
  { path: "/admin/nft/dashboard", component: AdminNFTDashbaord },
  { path: "/admin/user/dashboard", component: AdminUserDashboard },
  
  // Places
  { path: "/admin/properties", component: AdminPlaces },
  { path: "/admin/properties/create",component:AdminPlaceCreate},
  { path: "/admin/properties/edit/:propertyID",component:AdminPlaceEdit},

  // Support Request
  { path: "/admin/support",component:AdminSupportRequests},

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  
  // Public CSM Page
  { path: "/csm/privacy-policy", component: PublicCSMpages },
  { path: "/csm/term-condition", component: PublicCSMpages },
  { path: "/csm/:id", component: PublicCSMpages },
  
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/reset-password", component: ResetPassword },

]

export { authProtectedRoutes, publicRoutes }
