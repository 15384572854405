import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router'
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, Table } from "reactstrap";
//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import CardUser from "./CardUser";
import ChartUser from "./Chart";
import NFTUser from "./NFT";
import Transactions from "./Transactions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'

import { apiRoute } from "routes/apiRoutes";
import axios from "axios";


const Dashboard = props => {

  //meta title
  document.title = "Dashboard";
  const history = useHistory();
  const BASE_PATH = process.env.REACT_APP_BASE_PATH;

  const [resultStats, setResultStats] = useState('');
  const [resultGraph, setResultGraph] = useState('');
  const [resultGraphStats, setResultGraphStats] = useState('');
  const [resultCollection, setResultCollection] = useState([]);
  const [resultEvent, setResultEvent] = useState([]);
  const [latestTransaction, setLatestTransactions] = useState([]);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    try {
      axios.get(apiRoute.dashboardStats).then(res => {

        console.log(res.data.data)

        setResultStats({ totalUser: res.data.data.totalUsers, totalVendor: res.data.data.acl, collectionCount: res.data.data.collectionCount, upcomingEvents: res.data.data.upcomingEvents, todayEvents: res.data.data.todayEvents, eventReqCount: res.data.data.eventReqCount })
        setResultCollection(res.data.data.recentlyMinted)
        setLatestTransactions(res.data.data.transactions)
        setResultGraphStats({ todaysUser: res.data.data.todaysUser, lastSevenDayUser: res.data.data.lastSevenDayUser, lastThirtyDayUser: res.data.data.lastThirtyDayUser })
        setResultGraph(res.data.data.userGraph)
        setResultEvent(res.data.data.recentEvents)

      }).catch((error) => {
        console.log(error.response.data.message, '>>>>>>>>>>>>>>>')
        
        if(error.response.data.message == 'Unauthorized'){
          history.push('/logout');
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

          <Row>
            <Col xl="12">
              <CardUser resultStats={resultStats} dataColors='["--bs-primary", "--bs-warning"]' />
            </Col>
            <Col xl="12">
              <NFTUser resultCollection={resultCollection} />
            </Col>
            <Col xl="12">
              <ChartUser resultGraph={resultGraph} resultGraphStats={resultGraphStats} dataColors='["--bs-primary", "--bs-warning"]' />
            </Col>
            <Col xl="12">
              <Transactions latestTransaction={latestTransaction} resultEvent={resultEvent} />
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
