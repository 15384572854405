import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Card, CardBody, Col, Row, Container, Badge
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import '../../assets/scss/style.css'

import { toast } from "react-toastify"
import { apiRoute } from "routes/apiRoutes"
import axios from "axios"
import { dateformat, dateTimeformat, getUserDetail } from "admin/commonFunction"
import { LoaderContext } from "context/ContextProvider"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";
import noDataImage from "../../assets/images/nodata-found.png"

import event1 from "../../assets/images/events/event-1.jpg";
import event2 from "../../assets/images/events/event-2.jpg";
import event3 from "../../assets/images/events/event-3.jpg";
import event4 from "../../assets/images/events/event-4.jpg";
import event5 from "../../assets/images/events/event-5.jpg";
import event6 from "../../assets/images/events/event-6.jpg";

const EventsShow = props => {

    //meta title
    document.title = "Events";
    const BASE_PATH = process.env.REACT_APP_BASE_PATH
    const { loader, loading, setloading } = useContext(LoaderContext)
    const [listingData, setEventData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [view, setview] = useState(true)
    const [loaderStatus, setLoaderStatus] = useState(true)
    const [approval, setApproval] = useState("")
    const [userInfo, setUserInfo] = useState("")
    const perPage = 10

    const handlePageClick = page => {
        setCurrentPage(page)
        getEvents(page, perPage, searchName, approval)
    }

    const getEvents = (pagenumber, pagelimit, search = "", approval) => {
        try {

            setLoaderStatus(true)
            axios.get(apiRoute.eventRequests + "?page=" + pagenumber + "&limit=" + pagelimit + "&search=" + search + "&isApproved=" + approval)
                .then(res => {
                    setLoaderStatus(false)

                    setEventData(res.data.data.request)
                    let pageCount = Math.ceil(res.data.data.totalItems / perPage)
                    setTotalCount(pageCount)
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getEvents(currentPage, perPage, searchName, approval)
        setUserInfo(getUserDetail())
    }, [])


    const handleSearchClick = event => {
        setSearchName(event.target.value)
        setCurrentPage(1)
        getEvents(1, perPage, event.target.value, approval)
    }


    const handleChangeStatus = (requestid, status) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, do it!",
        }).then(async (result) => {
            if (result.isConfirmed) {

                await axios.put(apiRoute.eventRequestStatusUpdate, { 'requestId': requestid, 'event_status': status })
                    .then(res => {

                        console.log(res, 'resresresresresresresres')
                        getEvents(currentPage, perPage, searchName, approval)
                    })
            }
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1 text-color-gredient"> Event Requests </h5>

                                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                            <div className="position-relative" style={{ marginTop: '10px' }}>
                                                <label htmlFor="search-bar-0" className="search-label">
                                                    <span id="search-bar-0-label" className="sr-only">
                                                        Search this table
                                                    </span>
                                                    <input
                                                        id="search-bar-0"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Search...`}
                                                        onChange={handleSearchClick}
                                                    />
                                                </label>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="btn btn-light me-1" >
                                                <i className="mdi mdi-refresh" style={{ cursor: "pointer" }} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Row>

                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th> User </th>
                                                        <th> Title </th>
                                                        <th> Description </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loaderStatus ? (
                                                        <tr>
                                                            <td>No Record Found</td>
                                                        </tr>
                                                    ) : (
                                                        listingData.map((element, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ width: '350px' }}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="">
                                                                                <h5 className="font-size-14 mb-1 text-color-gredient">
                                                                                    {" "}
                                                                                    {element?.userdetails?.name}{" "}
                                                                                </h5>
                                                                                <p className="text-muted mb-0">
                                                                                    Email :{" "}
                                                                                    {element?.userdetails?.email}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {element?.name}
                                                                    </td>
                                                                    <td>
                                                                        {element?.description}
                                                                    </td>
                                                                    <td style={{ width: '200px' }}>
                                                                        {
                                                                            (element.status == 1) ?
                                                                                <Badge className="bg-success ms-1" style={{ padding: '7px 7px', width: '75px' }}> Accepted </Badge>
                                                                                : (element.status == 2) ?
                                                                                    <Badge className="bg-danger ms-1" style={{ padding: '7px 7px', width: '75px' }}> Rejected </Badge>
                                                                                    : (element.status == 0) ?

                                                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                                                            <li>
                                                                                                <Link
                                                                                                    to="#"
                                                                                                    className="btn btn-sm btn-success p-0 px-1 ms-2"
                                                                                                    onClick={() => {
                                                                                                        handleChangeStatus(element._id, 1)
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="mdi mdi-check-circle font-size-18" />
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link
                                                                                                    to="#"
                                                                                                    className="btn btn-sm btn-danger p-0 px-1 ms-2"
                                                                                                    onClick={() => {
                                                                                                        handleChangeStatus(element._id, 2)
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="mdi mdi-close-circle font-size-18" />
                                                                                                </Link>
                                                                                            </li>
                                                                                        </ul>
                                                                                        : ''
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Row>


                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};


EventsShow.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(EventsShow);
